import Base from "../../../base";
import OrderInstallService from "../../../../services/order/install";
import Form from "../../../layout/form";
import CompanyListLayout from "../../../company/layout";
import UserListLayout from "../../../user/layout/list";
import Table from "../../../layout/table";
import CompanyUserListLayout from "../../../company/user/layout";
import UserService from "../../../../services/user";
import CompanyService from "../../../../services/company";
import PosListLayout from "../../../device/pos/layout";
import KeybordListLayout from "../../../device/keybord/layout";
import SimListLayout from "../../../device/sim/layout";
import BankListLayout from "../../../running/bank/layout";
import BankService from "../../../../services/running/bank";
import { Fragment } from 'react';
import UserDeviceListLayout from "../../../user/device/layout";
import CompanyUserService from "../../../../services/company/user";

export default class OrderInstallAdd extends Base {
    boot() {
        this.state = {
            model: {
                uid: 0,
                username:'',
                phone:'',
                address:'',
                remark:'',
                installUid:0,
                checkUid:0,
                type:1,
                status:0
            },
            company: [],
            client: [],
            installUser:[],
            checkUser:[],
            receiveBank:[],
            bank:[],
            pos:[],
            keyboard:[],
            sim:[],
            terminal:[]
        }
    }

    componentDidMount() {
        /* global layer */
        let uid = this.app.getUrlParams('uid');
        if (uid) {
            this.server.getService(UserService).getDetail(uid, user => {
                this.setState({
                    client: [user]
                })
                if (user.information.companyId) {
                    this.server.getService(CompanyService).getDetail(user.information.companyId, company => {
                        user.information.company = {
                            name:company.name
                        }
                        this.setState({
                            company:[company],
                        })
                    })
                }
            })
        }

    }

    service() {
        return OrderInstallService;
    }

    render() {
        return (
            <Form
                state={this.state}
                fields={{
                    base: {
                        type: 'group',
                        name: '基础信息',
                        fields: {
                            company: {
                                name: '所属公司',
                                type: 'choose',
                                component: this.company.bind(this),
                                click: this.chooseCompany.bind(this)
                            },
                            client: {
                                name: '商户',
                                type: 'choose',
                                component: this.client.bind(this),
                                click: this.chooseClient.bind(this)
                            },
                            type: {name: '单据类型', type: 'select', data: this.server.type},
                            username:'联系人',
                            phone:'联系电话',
                            address:{name:'安装地址', type:'textarea'},
                            remark:{name:'备注（问题描述）', type:'textarea'},
                            visitedAt:{name:'上门时间', type:'date', format:'datetime'},
                            installUser:{
                                name:'安装人',
                                type:'choose',
                                component: this.installUser.bind(this),
                                click: this.chooseInstallUser.bind(this)
                            },
                            status:{
                                name:'状态',
                                type:'select',
                                data:this.server.status
                            }
                        }
                    },
                    pos: {
                        type: 'group',
                        name: '终端信息',
                        fields: {
                            terminal: {
                                name: '终端',
                                type: 'choose',
                                component: this.terminal.bind(this),
                                click: this.chooseTerminal.bind(this)
                            },
                        //     pos:{
                        //         name:'POS机',
                        //         type:'choose',
                        //         click: this.choosePos.bind(this),
                        //         component:(
                        //             <Fragment>
                        //                 <PosListLayout
                        //                     autoload={false}
                        //                     list={this.state.pos}
                        //                     show={this.state.pos.length}
                        //                     paging={false}
                        //                     btnShow={false}
                        //                     oprationShow={false}
                        //                     layuiFluid={false}
                        //                     layuiCardBody={false}
                        //                     searchShow={false}
                        //                     hidden={['proddedAt']}
                        //                     inputName={"posId"}
                        //                 />
                        //                 <div style={{display:'none'}}>
                        //                     {
                        //                         this.state.pos.map((val, index) => {
                        //                             return (
                        //                                 <Fragment key={index}>
                        //                                     <input type="text" name={"posId"} value={val.id}/>
                        //                                     <input type="text" name={"posModel"} value={val.model}/>
                        //                                     <input type="text" name={"posDeviceNum"} value={val.deviceNum}/>
                        //                                     <input type="text" name={"posMade"} value={val.made}/>
                        //                                 </Fragment>
                        //                             )
                        //                         })
                        //                     }
                        //                 </div>
                        //             </Fragment>
                        //         )
                        //     },
                        //     keyboard:{
                        //         type:'choose',
                        //         name:'键盘',
                        //         click: this.chooseKeyboard.bind(this),
                        //         component:(
                        //             <Fragment>
                        //                 <KeybordListLayout
                        //                     autoload={false}
                        //                     list={this.state.keyboard}
                        //                     show={this.state.keyboard.length}
                        //                     paging={false}
                        //                     btnShow={false}
                        //                     oprationShow={false}
                        //                     layuiFluid={false}
                        //                     layuiCardBody={false}
                        //                     searchShow={false}
                        //                     hidden={['proddedAt']}
                        //                     inputName={"keyboardId"}
                        //                 />
                        //                 <div style={{display:'none'}}>
                        //                     {
                        //                         this.state.keyboard.map((val, index) => {
                        //                             return (
                        //                                 <Fragment key={index}>
                        //                                     <input type="text" name={"keyboardId"} value={val.id}/>
                        //                                     <input type="text" name={"keyboardModel"} value={val.model}/>
                        //                                     <input type="text" name={"keyboardDeviceNum"} value={val.deviceNum}/>
                        //                                     <input type="text" name={"keyboardMade"} value={val.made}/>
                        //                                 </Fragment>
                        //                             )
                        //                         })
                        //                     }
                        //                 </div>
                        //             </Fragment>
                        //         )
                        //     },
                        //     sim:{
                        //         type:'choose',
                        //         name:'SIM卡',
                        //         click: this.chooseSim.bind(this),
                        //         component:(
                        //             <Fragment>
                        //                 <SimListLayout
                        //                     autoload={false}
                        //                     list={this.state.sim}
                        //                     show={this.state.sim.length}
                        //                     paging={false}
                        //                     btnShow={false}
                        //                     oprationShow={false}
                        //                     layuiFluid={false}
                        //                     layuiCardBody={false}
                        //                     searchShow={false}
                        //                     hidden={['proddedAt']}
                        //                     inputName={"simId"}
                        //                 />
                        //                 <div style={{display:'none'}}>
                        //                     {
                        //                         this.state.sim.map((val, index) => {
                        //                             return (
                        //                                 <Fragment key={index}>
                        //                                     <input type="text" name={"simId"} value={val.id}/>
                        //                                     <input type="text" name={"simModel"} value={val.model}/>
                        //                                     <input type="text" name={"simDeviceNum"} value={val.deviceNum}/>
                        //                                     <input type="text" name={"simMade"} value={val.made}/>
                        //                                 </Fragment>
                        //                             )
                        //                         })
                        //                     }
                        //                 </div>
                        //             </Fragment>
                        //         )
                        //     },
                        //     terminalCode:'终端号',
                        //     username:'联系人',
                        //     phone:'联系电话',
                        //     address:{name:'安装地址', type:'textarea'},
                        //     receiveBank:{name:'收单银行', type:'choose', component: this.receiveBank.bind(this), click: this.chooseReceiveBank.bind(this)},
                        //     bank:{name:'开户银行', type:'choose', component: this.bank.bind(this), click: this.chooseBank.bind(this)},
                        //     box:{
                        //         type:'box',
                        //         fields:{
                        //             lendRate:'费率(借)',
                        //             loanRate:'费率(贷)',
                        //             outRate:'费率(外)',
                        //             originRate:'费率(本)',
                        //             specialCoast:'特殊计费',
                        //             level:'商户等级',
                        //             expansion:'拓展人',
                        //             expansionCoast:'拓展费用',
                        //             platform:'所属平台',
                        //             bankAgent:'银行经办人',
                        //             expansionType:'拓展方式',
                        //             channel:'渠道',
                        //             transactionControl:'交易控制',
                        //             receiveDate:{name:'收单日期', type:'date'},
                        //             postcode:'邮政编码',
                        //             serialNumber:'申请序号',
                        //             risk:'风险等级',
                        //             corporate:'法人代表',
                        //             corporateIdcode:{name:'法人证件号', title:'法人代表证件号', labelStyle:{overflow:'hidden', whiteSpace:'nowrap', textOverflow: 'ellipsis'}},
                        //             corporateValidityStartDate:{name:'有效期开始', type:'date', title:'法人代表证件号有效期开始'},
                        //             corporateValidityEndDate:{name:'有效期结束', type:'date', title:'法人代表证件号有效期结束'},
                        //             corporatePhone:'法人手机',
                        //             corporateTel:'法人固话',
                        //             corporateFax:'法人传真',
                        //             authorized:'被授权人',
                        //             authorizedIdcode:{name:'身份证', labelStyle:{overflow:'hidden', whiteSpace:'nowrap', textOverflow: 'ellipsis'}, title:'被授权人身份证'},
                        //             authorizedValidityStartDate:{name:'有效期开始', type:'date', title:'被授权人身份证有效期开始'},
                        //             authorizedValidityEndDate:{name:'有效期结束', type:'date', title:'被授权人身份证有效期结束'},
                        //             registerDate:{name:'登记日期', type:'date'},
                        //             startDate:{name:'启用日期', type:'date'},
                        //             aliCoastRate:{name:'支付宝费率'},
                        //             wechatCoastRate:{name:'微信费率'},
                        //             salesman:'业务员',
                        //             salesmanNum:'业务员工号',
                        //             externalChannels:'外部渠道',
                        //             clientManagerPhone:{name:'客户经理电话', labelStyle:{overflow:'hidden', whiteSpace:'nowrap', textOverflow: 'ellipsis'}, title:'客户经理电话'},
                        //             district:'区域',
                        //             contractor:'承包人',
                        //         }
                        //     }
                        }
                    },
                }}
            />
        );
    }

    terminal() {
        return (
            <UserDeviceListLayout
                autoload={false}
                show={this.state.terminal.length}
                list={this.state.terminal}
                paging={false}
                btnShow={false}
                searchShow={false}
                oprationFields={['detail']}
                layuiFluid={false}
                layuiCardBody={false}
                inputName={'terminalId'}
                visibles={['id', 'clientImage', 'client.code', 'client.name', 'terminalCode', 'client.bankAddress', 'deviceRemark']}
            />
        )
    }

    chooseTerminal() {
        let client = this.state.client[0];
        if (!client) {
            return layer.msg('请先选择商户!');
        }
        this.layOpen({
            title: '选择终端',
            content: '/admin/user/device/choose/list?uid='+client.id+'&status=0',
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (!data || !data.length) {
                    return layer.msg('请选择终端');
                }
                let terminal = JSON.parse(data[0]);
                if (terminal.contractor) {
                    this.server.getService(CompanyUserService).searchList(terminal.contractor, list => {
                        if (list.data.length) {
                            this.setState({
                                installUser:[list.list[0]]
                            })
                        }
                    })
                }
                this.setState({
                    terminal: [terminal]
                })
                layer.close(index);
            }
        })
    }

    company() {
        return (
            <CompanyListLayout
                autoload={false}
                list={this.state.company}
                paging={false}
                btnShow={false}
                show={this.state.company.length}
                searchShow={false}
                oprationShow={false}
                layuiFluid={false}
                layuiCardBody={false}
            />
        );
    }

    chooseCompany() {
        this.layOpen({
            title: '选择分公司',
            content: '/admin/company/choose/list',
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (!data || !data.length) {
                    return layer.msg('请选择分公司');
                }
                let company = JSON.parse(data[0]);
                this.setState({
                    company: [company]
                })
                layer.close(index);
            }
        })
    }

    client() {
        return (
            <UserListLayout
                autoload={false}
                list={this.state.client}
                paging={false}
                btnShow={false}
                show={this.state.client.length}
                searchShow={false}
                oprationFields={['detail']}
                layuiFluid={false}
                layuiCardBody={false}
                hidden={['statusDup', 'auditStatusDup', 'location', 'qrcode']}
                inputName={'uid'}
            />
        );
    }

    chooseClient() {
        let company = this.state.company[0];
        if (!company) {
            return layer.msg('请先选择所属公司!');
        }

        this.layOpen({
            title: '选择商户',
            content: '/admin/user/choose/list',
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (!data || !data.length) {
                    return layer.msg('请选择商户');
                }

                let client = JSON.parse(data[0]);
                this.setState( {
                    client: [client]
                })
				
				console.log(data[0]);

                this.server.getService(UserService).getDetail(client.id, data => {
                    let model = this.state.model;
                    let pei = {
                        username:'contact',
                        phone:'tel'
                    };
                    let fields = ['bankAddress', 'address', 'corporate', 'corporateIdcode', 'corporateValidityStartDate', 'corporateValidityEndDate', 'corporatePhone', 'corporateTel', 'corporateFax', 'lendRate', 'loanRate','outRate','originRate','specialCoast', 'level','expansion','expansionCoast','platform','bankAgent','expansionType','channel',
                        'transactionControl', 'receiveDate','postcode','serialNumber','risk','registerDate','startDate','aliCoastRate','wechatCoastRate','authorized', 'authorizedIdcode', 'authorizedValidityStartDate', 'authorizedValidityEndDate'
                    ];
                    for(let k in fields) {
                        let field = fields[k];
                        // if (data.information.hasOwnProperty(field) && !model[field]) {
                        model[field] = data.information[field];
                        // }
                    }
                    for(let k in pei) {
                        let field = pei[k];
                        // if (! model[k] && data.information[field]) {
                        model[k] = data.information[field];
                        // }
                    }
                    if (data.information.receiveBank) {
                        this.server.getService(BankService).getDetail(data.information.receiveBank, bank => {
                            this.setState({
                                receiveBank:[bank]
                            })
                        })
                    }
                    if (data.information.bank) {
                        this.server.getService(BankService).getDetail(data.information.bank, bank => {
                            this.setState({
                                bank:[bank]
                            })
                        })
                    }
					console.log(model);
                    this.setModel(model);
                })
                layer.close(index);
            }
        })
    }

    installUser() {
        return (
            <CompanyUserListLayout
                autoload={false}
                list={this.state.installUser}
                show={this.state.installUser.length}
                paging={false}
                btnShow={false}
                searchShow={false}
                oprationShow={false}
                layuiFluid={false}
                layuiCardBody={false}
                inputName={'installUid'}
            />
        )
    }

    chooseInstallUser() {
        this.layOpen({
            title: '选择安装人',
            content: '/admin/company/user/choose/list',
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (!data || !data.length) {
                    return layer.msg('请选择数据');
                }
                let user = JSON.parse(data[0]);
                this.setState( state => {
                    let model = state.model;
                    model.installUid = user.id;
                    return {
                        installUser: [user],
                        model: model
                    }
                })
                layer.close(index);
            }
        })
    }

    checkUser() {
        return (
            <CompanyUserListLayout
                autoload={false}
                list={this.state.checkUser}
                show={this.state.checkUser.length}
                paging={false}
                btnShow={false}
                searchShow={false}
                oprationShow={false}
                layuiFluid={false}
                layuiCardBody={false}
                inputName={'checkUid'}
            />
        )
    }

    chooseCheckUser() {
        this.layOpen({
            title: '选择巡检人',
            content: '/admin/company/user/choose/list',
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (!data || !data.length) {
                    return layer.msg('请选择数据');
                }
                let user = JSON.parse(data[0]);
                this.setState( state => {
                    let model = state.model;
                    model.checkUid = user.id;
                    return {
                        checkUser: [user],
                        model: model
                    }
                })
                layer.close(index);
            }
        })
    }

    choosePos() {
        let company = this.state.company[0];
        if (! company || ! company.id) {
            return layer.msg('请选择所属公司');
        }
        this.layOpen({
            title:'选择POS机',
            content:'/admin/device/pos/choose/list?companyId=' + company.id,
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (! data || ! data.length) {
                    return layer.msg('请选择数据!');
                }

                this.setState({
                    pos:[JSON.parse(data[0])]
                })
                layer.close(index);
            }
        })
    }

    chooseKeyboard() {
        let company = this.state.company[0];
        if (! company || ! company.id) {
            return layer.msg('请选择所属公司');
        }
        this.layOpen({
            title:'选择键盘',
            content:'/admin/device/keyboard/choose/list?companyId='+ company.id,
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (! data || ! data.length) {
                    return layer.msg('请选择数据!');
                }

                this.setState({
                    keyboard: [JSON.parse(data[0])]
                })
                layer.close(index);
            }
        })
    }

    chooseSim() {
        let company = this.state.company[0];
        if (! company || ! company.id) {
            return layer.msg('请选择所属公司');
        }
        this.layOpen({
            title:'SIM卡',
            content:'/admin/device/sim/choose/list?companyId='+ company.id,
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (! data || ! data.length) {
                    return layer.msg('请选择数据!');
                }
                this.setState({
                    sim:[JSON.parse(data[0])]
                })
                layer.close(index);
            }
        })
    }

    receiveBank(state) {
        return (
            <BankListLayout
                autoload={false}
                show={state.receiveBank.length}
                list={state.receiveBank}
                paging={false}
                searchShow={false}
                btnShow={false}
                oprationShow={false}
                layuiFluid={false}
                layuiCardBody={false}
                visibles={['id', 'name', 'typeDup']}
                inputName={'receiveBankId'}
            />
        )
    }

    chooseReceiveBank(form) {
        this.layOpen({
            title:'选择收单银行',
            content:'/admin/running/bank/choose/list',
            form: true,
            yes: (fields, index) => {
                fields = this.app.htmlArrayToJson(fields);
                let data = fields.data;
                if (! data || ! data.length) {
                    return layer.msg('请选择银行');
                }
                form.setState({
                    receiveBank:[JSON.parse(data[0])]
                })
                layer.close(index);
            }
        })
    }

    bank(state) {
        return (
            <BankListLayout
                autoload={false}
                show={state.bank.length}
                list={state.bank}
                paging={false}
                searchShow={false}
                btnShow={false}
                oprationShow={false}
                layuiFluid={false}
                layuiCardBody={false}
                visibles={['id', 'name', 'typeDup']}
                inputName={'bankId'}
            />
        )
    }

    chooseBank(form) {
        this.layOpen({
            title:'选择开户银行',
            content:'/admin/running/bank/choose/list',
            form: true,
            yes: (fields, index) => {
                fields = this.app.htmlArrayToJson(fields);
                let data = fields.data;
                if (! data || ! data.length) {
                    return layer.msg('请选择银行');
                }
                form.setState({
                    bank:[JSON.parse(data[0])]
                })
                layer.close(index);
            }
        })
    }

    // deviceList() {
    //     return (
    //         <Table
    //             autoload={false}
    //             list={this.state.model.device}
    //             listTitleHeaderComponent={
    //                 <tr>
    //                     <th style={{textAlign: 'center'}}>终端号</th>
    //                     <th colSpan={3} style={{textAlign: 'center'}}>POS机</th>
    //                     <th colSpan={3} style={{textAlign: 'center'}}>键盘</th>
    //                     <th colSpan={3} style={{textAlign: 'center'}}>SIM卡</th>
    //                     {/*<th style={{textAlign: 'center'}}>费用</th>*/}
    //                 </tr>
    //             }
    //             fieldsInit={{
    //                 terminalCode:'终端号',
    //                 posModel: {name:'POS机型号', value(model) {
    //                         return (
    //                             <div>
    //                                 <input type="hidden" name={'device[]'} value={JSON.stringify(model)}/>
    //                                 {model.posModel}
    //                             </div>
    //                         );
    //                     }},
    //                 posDeviceNum: 'POS机编号',
    //                 posMade: 'POS厂商',
    //                 // deposit: '押金',
    //                 keyboardModel: '键盘型号',
    //                 keyboardDeviceNum: '键盘编号',
    //                 keyboardMade: '键盘厂商',
    //                 simModel: 'SIM类型',
    //                 simDeviceNum: 'SIM卡号',
    //                 simMade: '运营商',
    //                 //money: ''
    //             }}
    //             oprationShow={false}
    //             layuiFluid={false}
    //             layuiCardBody={false}
    //             paging={false}
    //             btnShow={false}
    //             searchShow={false}
    //         />
    //     )
    // }

    // addDevice() {
    //     let company = this.state.company[0];
    //     if (! company || ! company.id) {
    //         return layer.msg('请选择分公司');
    //     }
    //     this.layOpen({
    //         title: '添加终端',
    //         content: '/admin/device/choose?companyId='+company.id,
    //         form: true,
    //         area: ['900px', '95%'],
    //         yes: (form, index) => {
    //             let pos = form.pos ? JSON.parse(form.pos) : {},
    //                 keyboard = form.keyboard ? JSON.parse(form.keyboard) : {},
    //                 sim = form.sim ? JSON.parse(form.sim) : {};
    //             if (! form.terminalCode) {
    //                 return layer.msg('请填写终端号');
    //             }
    //
    //             let devices = this.state.model.device;
    //             devices.push(
    //                 {
    //                     terminalCode:form.terminalCode,
    //                     posId: pos.id,
    //                     posModel: pos.model,
    //                     posDeviceNum: pos.deviceNum,
    //                     posMade: pos.made,
    //                     deposit: 0,
    //                     keyboardId:keyboard.id,
    //                     keyboardModel: keyboard.model,
    //                     keyboardDeviceNum: keyboard.deviceNum,
    //                     keyboardMade: keyboard.made,
    //                     simId: sim.id,
    //                     simModel: sim.model,
    //                     simDeviceNum: sim.deviceNum,
    //                     simMade: sim.made,
    //                     money: 0
    //                 }
    //             )
    //             this.setModel({
    //                 devices: devices
    //             })
    //             layer.close(index);
    //         }
    //     })
    // }
}
