import Base from "../../../base";
import CompanyUserListLayout from "../layout";

export default class CompanyUserChooseList extends Base
{
    render() {
        return (
            <CompanyUserListLayout
                search={{status:1}}
                canCheck={true}
                btnShow={false}
                oprationShow={false}
                searchFields={['keywords', 'company']}
            />
        );
    }
}
