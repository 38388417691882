import Base from "../../../base";
import OrderInstallListLayout from "../layout";

export default class OrderUserInstallList extends Base
{
    boot() {
        this.state = {
            uid:this.app.getUrlParams('uid')
        }
    }
    render() {
        return (
            <OrderInstallListLayout
                search={{
                    uid: this.state.uid
                }}
                btnShow={false}
                searchFields={['keywords','status', 'dateMin', 'dateMax']}
                visibles={['id', 'orderSn', 'typeDup', 'posDeviceNum', 'principalUser', 'check', 'visitedAt', 'servedAt', 'statusDup', 'createdAt']}
            />
        )
    }
}