import Base from "../../../base";
import KeybordListLayout from "../layout";

export default class KeybordList extends Base
{
    render() {
        return (
            <KeybordListLayout
                oprationShow={true}
            />
        );
    }
}
