import Base from "../../../base";
import AnnouncementService from "../../../../services/running/announcement";
import Table from "../../../layout/table";

export default class AnnouncementList extends Base
{
    service() {
        return AnnouncementService;
    }

    componentDidMount() {
        /* global layer*/
    }

    render() {
        return (
            <Table
                service={AnnouncementService}
                stateInit={{
                    orderBy:['id', 'desc']
                }}
                searchInit={{
                    keywords:{name:'关键字', enter: true},
                    type:{name:'所属端口', type:'select', data: this.server.type, default:''},
                }}
                fieldsInit={{
                    id:'ID',
                    title:'标题',
                    typeDup:'所属端口',
                    contentDup:'内容',
                    startAt:'开始时间',
                    endAt:'结束时间',
                    statusDup:'状态',
                    createdAt:'添加时间'
                }}
                oprationInit={{
                    edit:{name:'编辑', icon: ['edit'], click: this.edit.bind(this)},
                    delete:{name: '删除', icon:['delete', 'danger'], click:this.delete.bind(this)}
                }}
                btnInit={{
                    add:{name:'添加', click: this.add.bind(this)}
                }}
            />
        );
    }

    add(table) {
        this.layOpen({
            title:'添加',
            content:'/admin/running/announcement/add',
            form:true,
            area:['1000px', '90%'],
            yes: (field, index) => {
                this.server.save(field, () => {
                    layer.msg('添加成功!');
                    layer.close(index);
                    table.loadData();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    edit(model, table) {
        this.layOpen({
            title:'修改 '+model.title,
            content:'/admin/running/announcement/edit/'+model.id,
            form:true,
            area:['1000px', '90%'],
            yes: (field, index) => {
                field.id = model.id;
                this.server.save(field, () => {
                    layer.msg('修改成功!');
                    layer.close(index);
                    table.pageReload();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    delete(model, table) {
        layer.confirm(`您真的要删除 ${model.title} 吗?`, () => {
            this.server.delete(model, () => {
                layer.msg('删除成功!');
                table.pageReload();
            }, (msg) => {
                layer.msg(msg);
            })
        })
    }
}
