import AdminService from "../../../../services/admin";
import Table from "../../../layout/table";
import View from "../../../../../../vendor/framework/view";

class AdminListLayout extends View
{
    service() {
        return AdminService;
    }

    render() {
        return (
            <Table
                service={AdminService}
                searchInit={{
                    keywords: {name: '关键字', placeholder:'请输入关键字', enter: true},
                    status:{name: '状态', type:'select', data: this.server.states, default:''},
                }}
                fieldsInit={{
                    id:{name:'ID', sort:{asc:'asc', desc:'desc'}},
                    username:'账号',
                    realname:'名称',
                    roleName:'角色',
                    stateDup:'状态',
                    lastLoginAt:'最后登录时间',
                    createdAt:'添加时间'
                }}
                btnInit={{
                    add:{name:'添加', click:this.add.bind(this)},
                }}
                oprationInit={{
                    edit:{name:'修改', icon:['edit', 'normal'], click:this.edit.bind(this)},
                    delete:{name:'删除', icon:['delete', 'danger'], click:this.delete.bind(this)}
                }}
            />
        )
    }

    componentDidMount() {
        /* global layui,layer */
        layui.use(['form'], () => {

        })
    }

    add(table) {
        this.layOpen({
            title:'添加账号',
            content:'/admin/admin/add',
            form:true,
            area:['900px', '90%'],
            yes: (field, index) => {
                table.server.save(field, () => {
                    layer.msg('添加成功!');
                    layer.close(index);
                    table.loadData();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    edit(val, table) {
        this.layOpen({
            title:'修改账号',
            content:'/admin/admin/edit/'+val.id,
            form:true,
            area:['900px', '90%'],
            yes: (field, index) => {
                field.id = val.id;
                this.server.save(field, () => {
                    layer.msg('修改成功!');
                    layer.close(index);
                    table.loadData();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    delete(model, table) {
        layer.confirm('您真的要删除 ' + model.username + ' 吗?', () => {
            this.server.delete(model.id, () => {
                layer.msg('删除成功!');
                table.pageReload();
            }, (msg) => {
                layer.msg(msg);
            })
        })
    }
}
export default AdminListLayout;
