import Base from "../../../../base";
import OrderInstallApplyListLayout from "../layout";

export default class OrderInstallApplyList extends Base {
    render() {
        return (
            <OrderInstallApplyListLayout/>
        );
    }
}
