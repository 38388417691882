import Base from "../../../../base";
import ReceiptCodeTypeListLayout from "../layout";

export default class ReceiptCodeTypeList extends Base
{
    render() {
        return (
            <ReceiptCodeTypeListLayout />
        );
    }
}
