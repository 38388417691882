import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";

export default class OrderRepealService extends BaseService
{
    requestUrls = {
        url:'/order/repeal/list',
        add:'/order/repeal/add',
        detail:'/order/repeal/detail',
        save:'/order/repeal/save',
        delete:'/order/repeal/delete',
        setAppointUser:'/order/repeal/appoint',
        manyAppoint:'/order/repeal/many-appoint',
        audit:'/order/repeal/audit',
        import:'/order/repeal/import',
        export:'/order/repeal/export',
		sureAdd:'/order/repeal/sureAdd',
		
		
    }

    status = ['未完成', '待审核', '审核拒绝', '已撤机'];

    result = ['异常', '正常'];

    appends = ['statusDup', 'resultDup'];

    excelFields = {
        id:'序号',
        orderSn:'工单编号',
        company:'所属公司',
        clientName:'商户名称',
        clientCode:'商户编号',
        username:'联系人',
        phone:'联系电话',
        address:'地址',
        terminalCode:'终端号',
        repealUser:'撤机员工',
        result:'状态',
        servedAt:'完成时间'
    };

    appointFields = {
        id:'序号',
        orderSn: '撤机工单号',
        repealUser:'撤机员工'
    }

    getExcelTitles() {
        let titles = [];
        Object.keys(this.excelFields).forEach(item => {
            titles.push(this.excelFields[item]);
        })
        return titles;
    }

    repeal(model, suc, err) {
        let rules = [
                ['ids', 'required', '请选择要撤机的工单'],
                ['repealUid', 'required', '请选择撤机员工']
            ],
            validator = new Validator();

        if (! validator.validate(rules, model)) {
            return err(validator.getError());
        }
        this.post(
            this.requestUrls.add,
            model,
            suc,
            err
        )
    }

    save(model, suc, err) {
        let rules = [
                ['uid', 'required', '请选择商户'],
                ['deviceId', 'required', '请选择终端'],
                ['username', 'required', '请填写联系人'],
                ['phone', 'required', '请填写联系电话'],
                ['address', 'required', '请填写详细地址'],
            ],
            validator = new Validator();

        if (! validator.validate(rules, model)) {
            return err(validator.getError());
        }
        this.post(
            this.requestUrls.save,
            model,
            suc,
            err
        )
    }

    getDetail(id, suc) {
        this.post(
            this.requestUrls.detail,
            {id:id},
            suc
        )
    }

    delete(id, suc, err) {
        this.request({
            url:this.requestUrls.delete,
            data:{id:id},
            success:suc,
            error:err
        })
    }

    audit(model, suc, err) {
        this.post(
            this.requestUrls.audit,
            model,
            suc,
            err
        )
    }
	//确认入库
	sureAdd(id, suc, err) {
	    this.request({
	        url:this.requestUrls.sureAdd,
	        data:{id:id},
	        success:suc,
	        error:err
	    })
	}

    import(data, suc, err) {
        let rules = [
                ['data', 'required', '请选择EXCEL文件'],
                ['data.*.company', 'required', '请填写所属公司'],
                ['data.*.clientCode', 'required', '请填写商户编号'],
                ['data.*.clientName', 'required', '请填写商户名称'],
                ['data.*.username', 'required', '请填写联系人'],
                ['data.*.phone', 'required', '请填写联系电话'],
                ['data.*.address', 'required', '请填写安装地址'],
                ['data.*.terminalCode', 'required', '请填写终端号']
            ],
            validator = new Validator();

        if (! validator.validate(rules, data)) {
            return err(validator.getError());
        }
        this.post(
            this.requestUrls.import,
            data,
            suc,
            err
        )
    }

    setAppointUser(data, suc, err) {
        this.post(
            this.requestUrls.setAppointUser,
            data,
            suc,
            err
        )
    }

    manyAppoint(data, suc, err) {
        let rules = [
                ['data', 'required', '请选择EXCEL文件'],
                ['data.*.orderSn', 'required', '请填写撤机工单号'],
                ['data.*.repealUser', 'required', '请填写撤机员工'],
            ],
            validator = new Validator();

        if (! validator.validate(rules, data)) {
            return err(validator.getError());
        }
        this.post(
            this.requestUrls.manyAppoint,
            data,
            suc,
            err
        )
    }

    // export(ids, suc) {
    //     this.post(
    //         this.requestUrls.export,
    //         {ids:ids},
    //         suc
    //     )
    // }
	
	export(data, suc) {
	    this.post(
	        this.requestUrls.export,
	        data,
	        suc
	    )
	}

    getStatusDupAttribute() {
		
        return this.status[this.attributes['status']];
    }

    getResultDupAttribute() {
        return this.attributes['status'] !== 0 ? this.result[this.attributes['result']] : '';
    }
	 
	
}
