import Base from "../../../base";
import UserDeviceListLayout from "../layout";

export default class UserDeviceList extends Base
{
    render() {
        return (
            <UserDeviceListLayout
                search={{
                    uid:this.app.getUrlParams('uid')
                }}
				canCheck={true}
				checkMany={true}
            />
        );
    }
}
