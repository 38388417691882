import Base from "../../../base";
import OrderPaymentQrcodeService from "../../../../services/order/paymentQrcode";
import Detail from "../../../layout/detail/detail";

export default class OrderPaymentQrcodeDetail extends Base
{
    boot() {
        this.state = {
            model:{

            }
        }
    }

    service() {
        return OrderPaymentQrcodeService;
    }

    componentDidMount() {
        this.server.getDetail(this.getPropsParams('id'), data => {
            this.setModel(data);
        })
    }

    render() {
        return (
            <Detail
                model={this.state.model}
                fields={{
                    orderSn:'工单号',
                    'qrcode.name':'类型',
                    'client.name':'商户名称',
                    'receipt':{name:'凭证', type:'image'},
                    statusDup:'状态',
                    createdAt:'添加时间'
                }}
            />
        )
    }
}
