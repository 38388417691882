import Base from "../../../base";
import KeybordListLayout from "../layout";

export default class KeybordChooseList extends Base
{
    render() {
        return (
            <KeybordListLayout
                search={{
                    status:0,
                    company_id:this.app.getUrlParams('companyId')
                }}
                searchShow={false}
                hidden={['proddedAt']}
                canCheck={true}
                btnShow={false}
                oprationShow={false}
            />
        );
    }
}
