import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";

export default class OrderMaintainingService extends BaseService
{
    requestUrls = {
        url:'/order/maintaining/list',
        serveUser:'/order/maintaining/serve/user',
        save:'/order/maintaining/save',
        detail:'/order/maintaining/detail',
        delete:'/order/maintaining/delete',
        audit:'/order/maintaining/audit',
        manyAppoint:'/order/maintaining/many-appoint',
        import:'/order/maintaining/import',
        export:'/order/maintaining/export',
		sureAdd:'/order/maintaining/sureAdd',
		 
    }

    orderTypes = ['申请耗材', '故障', '更换'];

    status = ['待处理', '审核中', '审核拒绝', '服务完成'];

    deviceType = ['POS机', '键盘', 'SIM卡'];

    result = ['异常', '正常', '更换'];

    appends = ['orderTypeDup', 'statusDup', 'deviceTypeDup', 'resultDup'];

    appointFields = {
        id:'序号',
        orderSn:'维护工单编号',
        checkUser:'维护员工'
    }

    excelFields = {
        id:'序号',
        orderSn:'工单编号',
        type:'工单类型',
        device:'设备类型',
        company:'所属公司',
        clientName:'商户名称',
        clientCode:'商户编号',
        username:'联系人',
        phone:'联系电话',
        address:'地址',
        terminalCode:'终端号',
        checkUser:'维护人',
        result:'维护结果',
        servedAt:'完成时间'
    };

    appointFields = {
        id:'序号',
        orderSn:'维护工单编号',
        checkUser:'维护员工'
    }

    getExcelTitles() {
        let titles = [];
        Object.keys(this.excelFields).forEach(item => {
            titles.push(this.excelFields[item]);
        })
        return titles;
    }

    audit(model, suc, err) {
        this.post(
            this.requestUrls.audit,
            model,
            suc,
            err
        )
    }

    save(model, suc, err) {
        let rules = [
                ['uid', 'required', '请选择商户'],
                ['orderType', 'required', '请选择类型'],
                ['deviceId', 'required', '请选择终端'],
                ['cuid', 'required', '请选择负责人'],
            ],
            validator = new Validator();

        if (! validator.validate(rules, model)) {
            return err(validator.getError());
        }
        this.post(
            this.requestUrls.save,
            model,
            suc,
            err
        )
    }

    serve(model, suc, err) {
        let rules = [
                ['ids', 'required', '请选择要维护的工单'],
                ['cuid', 'required', '请选择负责人']
            ],
            validator = new Validator();

        if (! validator.validate(rules, model)) {
            return err(validator.getError());
        }
        this.post(
            this.requestUrls.serveUser,
            model,
            suc,
            err
        )
    }

    getDetail(id, suc) {
        this.post(
            this.requestUrls.detail,
            {id:id},
            suc
        )
    }

    delete(id, suc, err) {
        this.request({
            url:this.requestUrls.delete,
            data:{id:id},
            success:suc,
            error:err
        })
    }

    manyAppoint(data, suc, err) {
        let rules = [
                ['data', 'required', '请选择EXCEL文件'],
                ['data.*.orderSn', 'required', '请填写维护工单编号'],
                ['data.*.checkUser', 'required', '请填写维护员工'],
            ],
            validator = new Validator();

        if (! validator.validate(rules, data)) {
            return err(validator.getError());
        }
        this.post(
            this.requestUrls.manyAppoint,
            data,
            suc,
            err
        )
    }


	//确认入库
	sureAdd(id, suc, err) {
	    this.request({
	        url:this.requestUrls.sureAdd,
	        data:{id:id},
	        success:suc,
	        error:err
	    })
	}
    import(data, suc, err) {
        let rules = [
                ['data', 'required', '请选择EXCEL文件'],
                ['data.*.type', 'required|in:'+this.orderTypes.join(','), '请填写工单类型|工单类型不正确'+this.orderTypes.join('/')],
                ['data.*.company', 'required', '请填写所属公司'],
                ['data.*.clientCode', 'required', '请填写商户编号'],
                ['data.*.clientName', 'required', '请填写商户名称'],
                ['data.*.username', 'required', '请填写联系人'],
                ['data.*.phone', 'required', '请填写联系电话'],
                ['data.*.address', 'required', '请填写安装地址'],
                ['data.*.terminalCode', 'required', '请填写终端号']
            ],
            validator = new Validator();

        if (! validator.validate(rules, data)) {
            return err(validator.getError());
        }
        this.post(
            this.requestUrls.import,
            data,
            suc,
            err
        )
    }

    // export(ids, suc) {
    //     this.post(
    //         this.requestUrls.export,
    //         {ids:ids},
    //         suc
    //     )
    // }
	
	export(data, suc) {
	    this.post(
	        this.requestUrls.export,
	        data,
	        suc
	    )
	}

    getOrderTypeDupAttribute() {
        return this.orderTypes[this.attributes['orderType']];
    }

    getStatusDupAttribute() {
        return this.status[this.attributes['status']];
    }

    getDeviceTypeDupAttribute() {
        return this.deviceType[this.attributes['deviceType']];
    }

    getResultDupAttribute() {
        return this.attributes['status'] !== 0 ? this.result[this.attributes['result']] : '';
    }
}
