import BaseService from '../base';
import Validator from "../../../../vendor/framework/validator";

export default class UserDeviceService extends BaseService {
    requestUrls = {
        url: '/user/device/list',
        save: '/user/device/save',
        detail: '/user/device/detail',
        delete: '/user/device/delete',
        import: '/user/device/import',
		export: '/user/device/export',
    }

    status = ['未安装', '审核中', '已安装', '审核拒绝', '已撤机'];

    appends = ['statusDup'];

    excelFields = {
        'id':'ID',
        'clientName':'商户名称',
        'clientCode':'商户编号',
        'company':'所属公司',
        'corporate':'商户法人',
        'corporatePhone':'法人手机',
        'corporateTel':'法人固话',
        'corporateFax':'法人传真',
        'corporateIdcode':'法人证件号',
        'corporateValidityStartDate':'法人证件有效期开始',
        'corporateValidityEndDate':'法人证件有效期结束',
        'username':'联系人',
        'phone':'联系电话',
        'address':'商户地址',
        'bankAddress':'银行商户地址',
        'contractor':'承包人',
        'terminalCode':'终端号',
        'receiveBank':'收单银行',
        'bank':'开户银行',
        'lendRate':'费率(借)',
        'loanRate':'费率(贷)',
        'outRate':'费率(外)',
        'originRate':'费率(本)',
        'specialCoast':'特殊计费',
        'level':'商户等级',
        'expansion':'拓展人',
        'expansionCoast':'拓展费用',
        'platform':'所属平台',
        'bankAgent':'银行经办人',
        'expansionType':'拓展方式',
        'channel':'渠道',
        'transactionControl':'交易控制',
        'receiveDate':'收单日期',
        'postcode':'邮政编码',
        'serialNumber':'申请序号',
        'risk':'风险等级',
        'authorized':'被授权人',
        'authorizedIdcode':'被授权人身份证',
        'authorizedValidityStartDate':'被授权人身份证有效期开始',
        'authorizedValidityEndDate':'被授权人身份证有效期结束',
        'registerDate':'登记日期',
        'startDate':'启用日期',
        'aliCoastRate':'支付宝费率',
        'wechatCoastRate':'微信费率',
        'posModel':'POS机型号',
        'posDeviceNum':'POS机设备号',
        'posMade':'POS机厂商',
        'keyboardModel':'键盘型号',
        'keyboardDeviceNum':'键盘设备号',
        'keyboardMade':'键盘厂商',
        'simModel':'SIM卡型号',
        'simDeviceNum':'SIM卡号',
        'simMade':'SIM卡运营商',
        'statusDup':'状态',
        'servedAt':'安装时间',
		'deviceRemark':'安装备注',
        // 'salesman':'业务员',
        // 'salesmanNum':'业务员工号',
        // 'externalChannels':'外部渠道',
        // 'clientManagerPhone':'客户经理电话',
    } 
	
	excelOutFields = {
	    'id':'ID',
	    'clientCode':'商户编号',
		'clientName':'商户名称',
		'clientPhone':'商户手机',
		'company':'所属公司',
	    'terminalCode':'终端号',
	    'bankAddress':'商户银行地址',
	    'servedAt':'安装时间',
	    'deviceRemark':'安装备注',
	    'statusDup':'状态', 
		'corporate':'商户法人',
		'corporatePhone':'法人手机',
		'corporateTel':'法人固话',
		'corporateFax':'法人传真',
		'corporateIdcode':'法人证件号',
		'corporateValidityStartDate':'法人证件有效期开始',
		'corporateValidityEndDate':'法人证件有效期结束',
		'username':'联系人',
		'phone':'联系电话',
		'address':'商户地址',
		'bankAddress':'银行商户地址',
		'contractor':'承包人',
		'receiveBank':'收单银行',
		'bank':'开户银行',
		'lendRate':'费率(借)',
		'loanRate':'费率(贷)',
		'outRate':'费率(外)',
		'originRate':'费率(本)',
		'specialCoast':'特殊计费',
		'level':'商户等级',
		'expansion':'拓展人',
		'expansionCoast':'拓展费用',
		'platform':'所属平台',
		'bankAgent':'银行经办人',
		'expansionType':'拓展方式',
		'channel':'渠道',
		'transactionControl':'交易控制',
		'receiveDate':'收单日期',
		'postcode':'邮政编码',
		'serialNumber':'申请序号',
		'risk':'风险等级',
		'authorized':'被授权人',
		'authorizedIdcode':'被授权人身份证',
		'authorizedValidityStartDate':'被授权人身份证有效期开始',
		'authorizedValidityEndDate':'被授权人身份证有效期结束',
		'registerDate':'登记日期',
		'startDate':'启用日期',
		'aliCoastRate':'支付宝费率',
		'wechatCoastRate':'微信费率',
		'posModel':'POS机型号',
		'posDeviceNum':'POS机设备号',
		'posMade':'POS机厂商',
		'keyboardModel':'键盘型号',
		'keyboardDeviceNum':'键盘设备号',
		'keyboardMade':'键盘厂商',
		'simModel':'SIM卡型号',
		'simDeviceNum':'SIM卡号',
		'simMade':'SIM卡运营商',

	}
	
    getExcelTitles() {
        let titles = [];
        Object.keys(this.excelFields).forEach(item => {
            titles.push(this.excelFields[item]);
        })
        return titles;
    }

    getDeviceList(uid, suc) {
        this.post(
            this.requestUrls.url,
            {uid:uid},
            suc
        )
    }

    save(model, suc, err) {
        let validator = new Validator(),
            rules = [
                ['uid', 'required', '请选择商户'],
                ['terminalCode', 'required', '请填写终端号']
            ];

        if (!validator.validate(rules, model)) {
            return err(validator.getError());
        }
        this.post(
            this.requestUrls.save,
            model,
            suc,
            err
        )
    }

    getDetail(model, suc) {
        this.post(
            this.requestUrls.detail,
            model,
            suc
        )
    }

    delete(id, suc, err) {
        this.post(
            this.requestUrls.delete,
            {id: id},
            suc,
            err
        )
    }

    import(data, suc, err) {
        let rules = [
                ['data', 'required', '请选择EXCEL文件'],
                ['data.*.clientCode', 'required', '请填写商户编号'],
                ['data.*.clientName', 'required', '请填写商户名称'],
                ['data.*.company', 'required', '请填写所属公司'],
                ['data.*.corporate', 'required', '请填写商户法人'],
                ['data.*.corporatePhone', 'required', '请填写法人手机'],
                ['data.*.terminalCode', 'required', '请填写终端号'],
                // ['data.*.posModel', 'required', '请填写POS机编号'],
                // ['data.*.posDeviceNum', 'required', '请填写POS机设备号'],
                // ['data.*.posMade', 'required', '请填写POS机厂商'],
                // ['data.*.keyboardMade', 'required', '请填写键盘型号'],
                // ['data.*.keyboardDeviceNum', 'required', '请填写键盘设备号'],
                // ['data.*.keyboardMade', 'required', '请填写键盘厂商'],
                // ['data.*.simModel', 'required', '请填写SIM型号'],
                // ['data.*.simDeviceNum', 'required', '请填写SIM卡号'],
                // ['data.*.simMade', 'required', '请填写SIM卡厂商'],
                ['data.*.statusDup', 'in:'+this.status.join(','), '状态:'+this.status.join(',')],
            ],
            validator = new Validator();

        if (! validator.validate(rules, data)) {
            return err(validator.getError());
        }
        this.post(
            this.requestUrls.import,
            data,
            suc,
            err
        )
    }

    getStatusDupAttribute() {
        return this.status[this.attributes['status']];
    }
	
	
	getExcelOutTitles() {
	    let titles = [];
	    Object.keys(this.excelOutFields).forEach(item => {
	        titles.push(this.excelOutFields[item]);
	    })
	    return titles;
	}
	
	
	
	export(data, suc) {
	    this.post(
	        this.requestUrls.export,
	        data,
	        suc
	    )
	}
	
	
	
	
}
