import Base from "../../../base";
import RoleListLayout from "../layout";

class RoleChooseList extends Base
{
    render() {
        return (
            <RoleListLayout
                checkMany={Number(this.app.getUrlParams('type') === 1)}
                canCheck={true}
                layForm={true}
                btnShow={false}
                oprationShow={false}
            />
        );
    }
}
export default RoleChooseList;
