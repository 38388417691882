import Base from "../../../base";
import CompanyUserService from "../../../../services/company/user";
import Detail from "../../../layout/detail/detail";
import File from "../../../../../../vendor/framework/file";

export default class CompanyUserDetail extends Base
{
    file = new File();

    boot() {
        this.state = {
            model:{

            }
        }
    }

    service() {
        return CompanyUserService;
    }

    componentDidMount() {
        this.server.getDetail(this.getPropsParams('id'), data => {
            if (data.portrait) {
                data.portrait = this.file.make(data.portrait);
            }
            this.setModel(data);
        })
    }

    render() {
        return (
            <Detail
                model={this.state.model}
                fields={{
                    portrait:{name:'头像', type:'image', block: true},
                    username:'员工账号',
                    realname:'姓名',
                    phone:'手机号',
                    locateModeDup:'定位方式',
                    'company.name':'所属公司',
                    statusDup:'状态',
                    'createdAt':'添加时间'
                }}
            />
        )
    }
}
