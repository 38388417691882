import Base from "../../../base";
import Form from "../../../layout/form";
import UserTypeService from "../../../../services/running/userType";

export default class UserTypeAdd extends Base
{
    boot() {
        this.state = {
            model:{
                name:'',
                sort:0,
                status:1
            }
        }
    }

    service() {
        return UserTypeService;
    }

    render() {
        return (
            <Form
                model={this.state.model}
                fields={{
                    name: '名称',
                    sort:{name:'排序', type:'number'},
                    status:{name:'状态', type: 'radio', data: this.server.status}
                }}
            />
        );
    }
}
