import Table from "../../../layout/table";
import View from "../../../../../../vendor/framework/view";
import UserDeviceService from "../../../../services/user/device";
import Excel from "../../../../components/excel";

export default class UserDeviceListLayout extends View
{
    boot() {
        this.state = {
            list:[]
        }
    }

    service() {
        return UserDeviceService;
    }

    componentDidMount() {
        /* global layui,layer */

    }

    render() {
        return (
            <Table
                props={this.props}
                service={UserDeviceService}
                searchInit={{
                    keywords: {name: '关键字', placeholder:'商户编号/商户名称/终端号/商户手机', enter: true},
                    company:{name:'所属公司', type:'component', component: this.buttonComponent.bind(this)},
                    status:{name:'状态', type:'select', data:this.server.status, default:''},
                    dateMin: {name: '安装时间', field:'served_at', type: 'date', position: 0, format: 'datetime', placeholder: '开始时间'},
                    dateMax: {type: 'date', field:'served_at', position: 1, format: 'datetime', placeholder: '结束时间'},
                }}
                fieldsInit={{
                    id:{name:'ID', checkbox: true, style:{whiteSpace:'nowrap'}}, 
                    clientImage:{
                        name:'商户照片',
                        type:'image',
                        value: model => {
                            if (model.client && model.client.facadeImg) {
                                let imgs = model.client.facadeImg.split(',');
                                return imgs[0];
                            }
                        }
                     },
                    'client.code':'商户编号',
                    'client.name':{name:'商户名称', style:{width:'200px'}},
					'client.user.phone':{name:'商户手机', style:{width:'200px'}},
                    'terminalCode':'终端号',
                    'client.bankAddress':{name:'商户银行地址', style:{ width:'300px' }},
                    servedAt:'安装时间',
					deviceRemark:'安装备注',
                    statusDup:'状态'
                }}
                btnInit={{
                    add: {name: '添加', click: this.add.bind(this)},
                    excelLayout: {name: 'EXCEL模板下载', className: 'layui-btn-warm', click: this.excelLayout.bind(this)},
                    import:{name:'导入', className:'layui-btn-normal', click: this.import.bind(this)},
					export:{name:'导出', click: this.export.bind(this)},
                }}
                oprationInit={{
                    detail:{name:'详情', icon:['list'], click: this.detail.bind(this)},
                    edit:{name:'编辑', icon: ['edit'], click: this.edit.bind(this)},
                    delete: {name: '删除', icon: ['delete', 'danger'], click: this.delete.bind(this)}
                }}
                headerStyle={{
                    whiteSpace:'nowrap'
                }}
                oprationStyle={{
                    whiteSpace:'nowrap'
                }}

            />
        );
    }

    buttonComponent(table) {
        return (
            <button className={"layui-btn"} onClick={e => this.chooseCompany(table)}>{ table.state.companyName || '所有公司'}</button>
        )
    }

    chooseCompany(table) {
        this.layOpen({
            title:'选择集团',
            content:'/admin/company/choose/list',
            form: true,
            area:['900px', '90%'],
            yes: (fields, index) => {
                fields = this.app.htmlArrayToJson(fields);
                let data = fields.data,
                    model = data && data.length ? JSON.parse(data[0]) : {id:'', name:''};
                table.setState({
                    companyName:model.name,
                })
                table.setSearch({
                    'client.company_id':model.id
                })
                table.loadData();
                layer.close(index);
            }
        })
    }

    add(table) {
        this.layOpen({
            title: '添加',
            content: '/admin/user/device/add',
            form: true,
            area: ['90%', '98%'],
            yes: (field, index) => {
                this.server.save(field, () => {
                    layer.msg('添加成功!');
                    layer.close(index);
                    table.loadData();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    detail(model) {
        this.layOpen({
            title:'详情',
            content:'/admin/user/device/detail/'+model.id,
            area: ['90%', '98%'],
            btn:['确定'],
            yes: (index) => {
                layer.close(index);
            }
        })
    }

    edit(model, table) {
        this.layOpen({
            title:'修改',
            content:'/admin/user/device/edit/'+model.id,
            form:true,
            area: ['90%', '98%'],
            yes: (field, index) => {
                field.id = model.id;
                this.server.save(field, () => {
                    layer.msg('修改成功!');
                    layer.close(index);
                    table.pageReload();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    delete(model, table) {
        layer.confirm('您真的要删除吗?', () => {
            this.server.delete(model.id, () => {
                layer.msg('删除成功!');
                table.pageReload();
            }, (msg) => {
                layer.msg(msg);
            })
        })
    }

    excelLayout() {
        let keys = Object.keys(this.server.excelFields),
            arr = [],
            data = [
                {
                    'id':'1',
                    'clientName':'深圳宝安商户',
                    'clientCode':'Y2022010914252121',
                    'company':'广州裕富联分公司',
                    'corporate':'陈某',
                    'corporatePhone':'13022222222',
                    'corporateTel':'0757-123456',
                    'corporateFax':'0757-123456',
                    'corporateIdcode':'123456789',
                    'corporateValidityStartDate':'2021-10-10',
                    'corporateValidityEndDate':'2041-10-10',
                    'username':'小陈',
                    'phone':'13000000000',
                    'address':'广州天河',
                    'bankAddress':'广州天河东圃',
                    'district':'',
                    'contractor':'',
                    'terminalCode':'Y20222222',
                    'receiveBank':'中国工商银行',
                    'bank':'中国农业银行',
                    'lendRate':'1',
                    'loanRate':'2',
                    'outRate':'3',
                    'originRate':'4',
                    'specialCoast':'5',
                    'level':'6',
                    'expansion':'黄某',
                    'expansionCoast':'200',
                    'platform':'抖音',
                    'bankAgent':'小兰',
                    'expansionType':'线上',
                    'channel':'抖音',
                    'transactionControl':'100',
                    'receiveDate':'2021-10-10',
                    'postcode':'1000000',
                    'serialNumber':'xxxxx',
                    'risk':'1',
                    'authorized':'小周',
                    'authorizedIdcode':'45555555555',
                    'authorizedValidityStartDate':'2022-02-02',
                    'authorizedValidityEndDate':'2042-02-02',
                    'registerDate':'2022-01-01',
                    'startDate':'2022-01-02',
                    'aliCoastRate':'1',
                    'wechatCoastRate':'2',
                    'posModel':'x123456',
                    'posDeviceNum':'m688555',
                    'posMade':'中国银行',
                    'keyboardModel':'k4655',
                    'keyboardDeviceNum':'5856656',
                    'keyboardMade':'联想',
                    'simModel':'小',
                    'simDeviceNum':'S265555',
                    'simMade':'中国移动',
                    'statusDup':'已安装',
                    'servedAt':'2022-01-03 09:00:00',
					'deviceRemark':'安装备注',
                }
            ];
        arr[0] = this.server.getExcelTitles();
        data.forEach((item, index) => {
            let k = index + 1;
            arr[k] = [];
            for(let j in keys) {
                let field = keys[j];
                if (item.hasOwnProperty(field)) {
                    arr[k].push(item[field]);
                }
            }
        })

        let excel = new Excel();
        excel.export('终端导入模板', arr, (workbook, table) => {
            workbook.sheet(0).row(1).height(50);
            workbook.sheets().forEach((sheet) => {
                // 表头加粗及背景色
                sheet.range('A1:BH1').style({
                    wrapText: true,
                    fill: "00bdf0",
                    bold: true,
                    horizontalAlignment: "center",
                    verticalAlignment: "center",
                    fontSize: 12,
                    border: {
                        style: 'thin',
                        color: "000000",
                        direction: "both"
                    }
                });

                for (let k in table[0]) {
                    sheet.column(k).width(25);
                }
            })
        });
    }

    import(table) {
        this.layOpen({
            title: '导入',
            content: '/admin/user/device/import',
            area: ['90%', '98%'],
            form: true,
            yes: (data, index) => {
                data = this.app.htmlArrayToJson(data);
                table.server.import(data, () => {
                    layer.close(index);
                    table.loadData();
                    layer.msg('导入成功!');
                }, (msg) => {
                    layer.alert(msg);
                })
            }
        })
    }
	
	
	export(table, type = 0) {
	    let ids = this.getCheckIds();
	    if (! ids.length) {
	        return layer.msg('请选择数据!');
	    }
	    this.server.export({ids:ids, type: type}, res => {
	        if (type) {
	            let url = (new File()).make(res.path);
	            window.open(url);
	            return;
	        }
	        let list = [];
	        res.forEach(item => {
	            if (ids.indexOf(item.id) > -1) {
	                list.push(item);
	            }
	        })
	        let titles = this.server.getExcelOutTitles();
	        let keys = Object.keys(this.server.excelOutFields),
	            data = [titles]; 
	        list.forEach(item => {
	            let arr = [],
				client = item.client || {},   
				company = item.client.company || {},  
				user = item.client.user || {},  
				
				
 				 
					row = {
						id:item.id || 0,
						clientCode:client.code || '',
						clientName:client.name || '',
						clientPhone:user.phone || '',
						company:company.name || '',
						terminalCode:item.terminalCode || '',
						bankAddress:item.bankAddress || '',
						servedAt:item.servedAt || '',
						deviceRemark:item.deviceRemark || '',
						statusDup:item.statusDup || '',
						corporate:item.corporate || '',
						corporatePhone:item.corporatePhone || '',
						corporateTel:item.corporateTel || '',
						corporateFax:item.corporateFax || '',
						corporateIdcode:item.corporateIdcode || '',
						corporateValidityStartDate:item.corporateValidityStartDate || '',
						corporateValidityEndDate:item.corporateValidityEndDate || '',
						username:item.username || '',
						phone:item.phone || '',
						address:item.address || '',
						contractor:item.contractor || '',
						receiveBank:item.receiveBank || '',
						bank:item.bank || '',
						lendRate:item.lendRate || '',
						loanRate:item.loanRate || '',
						outRate:item.outRate || '',
						originRate:item.originRate || '',
						specialCoast:item.specialCoast || '',
						level:item.level || '',
						expansion:item.expansion || '',
						expansionCoast:item.expansionCoast || '',
						platform:item.platform || '',
						bankAgent:item.bankAgent || '',
						expansionType:item.expansionType || '',
						channel:item.channel || '',
						transactionControl:item.transactionControl || '',
						receiveDate:item.receiveDate || '',
						postcode:item.postcode || '',
						serialNumber:item.serialNumber || '',
						risk:item.risk || '',
						authorized:item.authorized || '',
						authorizedIdcode:item.authorizedIdcode || '',
						authorizedValidityStartDate:item.authorizedValidityStartDate || '',
						authorizedValidityEndDate:item.authorizedValidityEndDate || '',
						registerDate:item.registerDate || '',
						startDate:item.startDate || '',
						aliCoastRate:item.aliCoastRate || '',
						wechatCoastRate:item.wechatCoastRate || '',
						posModel:item.posModel || '',
						posDeviceNum:item.posDeviceNum || '',
						posMade:item.posMade || '',
						keyboardDeviceNum:item.keyboardDeviceNum || '',
						keyboardModel:item.keyboardModel || '',
						keyboardMade:item.keyboardMade || '',
						simModel:item.simModel || '',
						simDeviceNum:item.simDeviceNum || '',
						simMade:item.simMade || '',
						 
						 
					} 
					  
				     
					 
				keys.forEach(field => {
				        arr.push(row[field] || '');
				    })
				data.push(arr);
				
				
	        }) 
	
	        let excel = new Excel();
	        excel.export('终端列表导出', data, (workbook, table) => {
	            workbook.sheet(0).row(1).height(50);
	            workbook.sheets().forEach((sheet) => {
	                // 表头加粗及背景色
	                sheet.range('A1:BE1').style({
	                    wrapText: true,
	                    fill: "00bdf0",
	                    bold: true,
	                    horizontalAlignment: "center",
	                    verticalAlignment: "center",
	                    fontSize: 12,
	                    border: {
	                        style: 'thin',
	                        color: "000000",
	                        direction: "both"
	                    }
	                });
	
	                for (let k in table[0]) {
	                    sheet.column(k).width(25);
	                }
	            })
	        })
	    })
	}

}
