import View from "../../../../vendor/framework/view";
import FileHandle from "../../../../vendor/framework/file";
import {Fragment} from "react";

export default class File extends View
{
    boot(props) {
        this.state = {
            src:props.src || '',
            inputName:props.inputName,
            btnClass:props.btnClass || 'chooseBtnUpload',
        }
    }

    componentWillReceiveProps(props) {
        this.setState({
            src:props.src || ''
        })
    }

    componentDidMount() {
        /* global layui,layer */
        let self = this,
            file = new FileHandle();
        layui.use(['form'], () => {
            let config = {
                btn:'.'+this.state.btnClass,
                exts:this.props.exts || [],
                fileErr(msg) {
                    layer.msg(msg);
                },
                choosed:(files, fielNames, fileExts, fileSizes) => {
                    this.props.choosed && this.props.choosed([files, fielNames, fileExts, fileSizes]);
                },
                success(res) {
                    if (res.code === 200) {
                        self.handle(file.make(res.data.src));
                    } else {
                        layer.msg(res.message);
                    }
                }
            }
            if (self.props.max) {
                config.maxSize = self.props.max;
                config.fileErr = (msg) => {
                    layer.msg(msg);
                }
            }

            //上传图片
            file.upload(config);
        })
    }

    render() {
        var input;

        if (this.state.src) {
            input = (
                <input type="hidden" name={this.state.inputName} value={this.state.src} />
            )
        }

        return (
            <Fragment>
                <button style={{float: 'left'}} type="button" className={"layui-btn "+this.state.btnClass}>选择文件</button>
                {
                    this.props.tipExtentions && this.props.tipExtentions()
                }
                <div>
                    {input}
                </div>
            </Fragment>
        );
    }

    handle(src = '') {
        this.props.handle && this.props.handle(src, this.state.inputName);
        this.setState({
            src:src
        })
    }
}
