import Base from "../../../base";
import ActivityListLayout from "../layout";

export default class ActivityList extends Base
{
    render() {
        return (
            <ActivityListLayout />
        );
    }
}
