import Base from "../../../base";
import AgreementService from "../../../../services/running/agreement";
import Table from "../../../layout/table";

class AgreementList extends Base
{
    boot() {
        this.state = {
            list:[]
        }
    }

    componentDidMount() {
        /* global layer */
        this.getList();
    }

    service() {
        return AgreementService;
    }

    getList() {
        this.server.getList(data => {
            this.setState({
                list:data
            })
        })
    }

    render() {
        return (
            <Table
                paging={false}
                autoload={false}
                list={this.state.list}
                fieldsInit={{
                    clientDup:'所属端口',
                    typeDup:'类型',
                    contentDup:'内容'
                }}
                oprationInit={{
                    edit:{name:'编辑', icon: ['edit'], click: this.edit.bind(this)},
                    delete:{name: '删除', icon:['delete', 'danger'], click:this.delete.bind(this)}
                }}
                btnInit={{
                    add:{name:'添加', click: this.add.bind(this)}
                }}
           />
        )
    }

    add() {
        this.layOpen({
            title:'添加',
            content:'/admin/running/agreement/add',
            form:true,
            area:['1000px', '90%'],
            yes: (field, index) => {
                this.server.save(field, () => {
                    layer.msg('添加成功!');
                    layer.close(index);
                    this.getList();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    edit(model) {
        this.layOpen({
            title:'修改 '+model.typeDup,
            content:'/admin/running/agreement/edit/'+model.id,
            form:true,
            area:['1000px', '90%'],
            yes: (field, index) => {
                field.id = model.id;
                this.server.save(field, () => {
                    layer.msg('修改成功!');
                    layer.close(index);
                    this.getList();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    delete(model) {
        layer.confirm(`您真的要删除 ${model.typeDup} 吗?`, () => {
            this.server.delete(model, () => {
                layer.msg('删除成功!');
                this.getList();
            }, (msg) => {
                layer.msg(msg);
            })
        })
    }
}

export default AgreementList;
