import Base from "../../../../base";
import UserAttrListLayout from "../layout";

export default class UserAttrChooseList extends Base
{
    render() {
        return (
            <UserAttrListLayout
                canCheck={true}
                search={{
                    status:1
                }}
                oprationShow={false}
                visibles={['id', 'name', 'createdAt']}
                btnShow={false}
                searchFields={['name']}
            />
        );
    }
}
