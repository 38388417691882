import Base from "../../../../base";
import UserAttrListLayout from "../layout";

export default class UserAttrList extends Base
{
    render() {
        return (
            <UserAttrListLayout

            />
        );
    }
}
