import Base from "../../../base";
import Form from "../../../layout/form";
import BankService from "../../../../services/running/bank";
import BankListLayout from "../layout";

export default class BankAdd extends Base
{
    boot() {
        this.state = {
            model:{
                pid:0,
                name:'',
                type:0,
                account:'',
                password:'',
                status:1
            },
            father:{}
        }
        /* global layer */
    }

    service() {
        return BankService;
    }

    render() {
        return (
            <Form
                model={this.state.model}
                fields={{
                    name:'银行名称',
                    type:{name:'类型', type: 'select', data: this.server.type},
                    father:{name:'上级', show: state =>  state.model.type , type:'choose', component: this.father.bind(this), click: this.chooseBank.bind(this)},
                    account:'账号',
                    password:{name:'密码', type:'password'},
                    status:{name:"状态", type:'select', data: this.server.status}
                }}
            />
        );
    }

    father(state) {
        return (
            <BankListLayout
                autoload={false}
                show={state.model.pid}
                list={[state.father]}
                oprationShow={false}
                searchShow={false}
                btnShow={false}
                layuiFluid={false}
                layuiCardBody={false}
                paging={false}
                inputName={"pid"}
            />
        )
    }

    chooseBank(form) {
        this.layOpen({
            title:'选择银行',
            content:'/admin/running/bank/choose/list?type='+(form.state.model.type - 1),
            form: true,
            yes: (fields, index) => {
                fields = this.app.htmlArrayToJson(fields);
                let data = fields.data;
                if (! data || ! data.length) {
                    return layer.msg('请选择银行');
                }
                form.setState( state => {
                    let bank = JSON.parse(data[0]);
                    state.model.pid = bank.id;
                    return {
                        father:bank,
                        model:state.model
                    }
                })
                layer.close(index);
            }
        })
    }
}
