import Base from "../../../base";
import LogService from "../../../../services/device/Log";
import Table from "../../../layout/table";
class LogList extends Base
{
    boot() {
        this.state = {
            list:[]
        }
     }

    componentDidMount() {
        /* global layer */
          this.server.getList(this.getPropsParams('id'),data => {
            this.setState({
                list:data
            })
        })		
    }

    service() {
        return LogService;
    }

 

    render() {
        return (
            <Table
			    oprationShow={false}
                paging={false}
                autoload={false}
				service={LogService}
                list={this.state.list}
                fieldsInit={{
                    id:{name:'ID'},
					remark:'操作内容',
                    createdAt:'操作时间',
                }}
                
                
           />
        )
    }
 
    
}

export default LogList;

