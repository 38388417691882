import Base from "../../../base";
import ReceiptCodeService from "../../../../services/running/paymentQrcode";
import Form from "../../../layout/form";

export default class ReceiptCodeEdit extends Base
{
    boot() {
        this.state = {
            model: {
                name:'',
                qrcode:'',
                status:1
            },
        }
    }

    service() {
        return ReceiptCodeService;
    }

    componentDidMount() {
        this.server.getDetail(this.getPropsParams('id'), (data) => {
            this.setModel(data);
        })
    }

    render() {
        return (
            <Form
                model={this.state.model}
                fields={{
                    name:'名称',
                    qrcode:{name:'收款二维码', type:'image'},
                    status:{name:'状态', type:'radio', data: this.server.status}
                }}
            />
        );
    }
}

