import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";

export default class HelpService extends BaseService
{
    requestUrls = {
        url:'/running/help/list',
        save:'/running/help/save',
        detail:'/running/help/detail',
        delete:'/running/help/delete',
    }

    appends = ['statusDup', 'contentDup', 'clientDup'];

    clients = ['员工端'];

    status = ['禁用', '启用'];

    getList(suc) {
        this.post(
            this.requestUrls.url,
            {},
            suc
        )
    }

    save(field, suc, err) {
        var validator = new Validator(),
            rules = [
                ['title', 'required', '请填写标题'],
                ['content', 'required', '请填写内容']
            ];

        if (! validator.validate(rules, field)) {
            return err(validator.getError());
        }

        this.post(
            this.requestUrls.save,
            field,
            suc,
            err
        )
    }

    getDetail(id, suc) {
        this.post(
            this.requestUrls.detail,
            {id:id},
            suc
        )
    }

    delete(model, suc, err) {
        this.post(
            this.requestUrls.delete,
            {id:model.id},
            suc,
            err
        )
    }

    getContentDupAttribute() {
        var content = this.app.delHtmlTag(this.attributes['content']);
        var length = content.length;
        if (! content && this.attributes['content']) {
            if (this.attributes['content'].match(/<img/)) {
                return '--图片内容--';
            }
        }
        return length > 20 ? (content.substr(0, 17) + '...') : content;
    }

    getClientDupAttribute() {
        return this.clients[this.attributes['client']];
    }

    getStatusDupAttribute() {
        return this.status[this.attributes['status']];
    }
}

