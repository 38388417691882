import Base from "../../base";
import Form from "../../layout/form";

export default class CompanyAdd extends Base
{
    boot() {
        this.state = {
            model:{
                name:'',
                password:'',
                status:1,
            },
        }
        /* global layer */
    }

    render() {
        return (
            <Form
                model={this.state.model}
                fields={{
                    name:'分部名称',
                    phone:'手机号码',
                    password:{name:'密码', type: 'password'},
                }}
            />
        );
    }
}

