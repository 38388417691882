import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";
import File from "../../../../vendor/framework/file";

class BannerService extends BaseService
{
    file = new File();

    requestUrls = {
        url:'/running/banner/list',
        save:'/running/banner/save',
        detail:'/running/banner/detail',
        sort:'/running/banner/sort',
        change:'/running/banner/change',
        delete:'/running/banner/delete'
    }

    status = ['禁用', '启用'];

    type = ['员工端', '客户端'];

    appends = ['stateDup', 'typeDup'];

    save(model, suc, err) {
        let validator = new Validator(),
            rules = [
                ['image', 'required', '请上传图片'],
            ];
        if (! validator.validate(rules, model)) {
            return err(validator.getError());
        }

        this.post(
            this.requestUrls.save,
            model,
            suc,
            err
        )
    }

    getDetail(id, suc) {
        this.post(
            this.requestUrls.detail,
            {id:id},
            suc
        )
    }

    delete(model, suc, err) {
        this.post(
            this.requestUrls.delete,
            {id:model.id},
            suc,
            err
        )
    }

    changeState(data, suc, err) {
        let model = {
            id:data.id,
            status:Number(data.status) === 0 ? 1 : 0
        }
        this.post(
            this.requestUrls.change,
            model,
            suc,
            err
        )
    }

    changeSort(data, suc, err) {
        let model = {
            id:data.id,
            sort:data.sort
        }
        this.post(
            this.requestUrls.sort,
            model,
            suc,
            err
        )
    }

    getStateDupAttribute() {
        return this.status[this.attributes['status']];
    }

    getTypeDupAttribute() {
        return this.type[this.attributes['type']];
    }

    getImageAttribute() {
        return this.file.make(this.attributes['image']);
    }
}
export default BannerService;
