import Base from "../../../base";
import Detail from "../../../layout/detail/detail";
import UserDeviceService from "../../../../services/user/device";

export default class UserDeviceDetail extends Base {
    boot() {
        this.state = {
            model: {},
        }
    }

    service() {
        return UserDeviceService;
    }

    componentDidMount() {
        /* global layer */
        this.server.getDetail({id:this.getPropsParams('id')}, data => {
            this.setModel(data);
        });
    }

    render() {
        return (
            <Detail
                model={this.state.model}
                fields={[
                    {
                        title: '商户信息',
                        fields: {
                            'client.code': {name: '商户编码'},
                            'client.name': {name: '商户名称'},
                            'client.company.name': {name: '所属公司'},
                            detail:{
                                name:'详情',
                                type:'itemComponent',
                                component:() => {
                                    return (
                                        <button className={"layui-btn layui-btn-sm layui-btn-normal"} onClick={e => this.showClient()}>
                                            <i className="layui-icon layui-icon-list"></i>
                                            查看
                                        </button>
                                    )
                                }}
                        }
                    },
                    {
                       title:'联系人信息',
                       fields:{
                           corporate:'法人代表',
                           corporateIdcode:{name:'法人证件号'},
                           corporateValidityStartDate:{name:'有效期开始'},
                           corporateValidityEndDate:{name:'有效期结束'},
                           corporatePhone:'法人手机',
                           corporateTel:'法人固话',
                           corporateFax:'法人传真',
                           username:'联系人',
                           phone:'电话',
                           address:{name:'安装地址', block: true},
                           bankAddress:'银行商户地址'
                       }
                    },
                    {
                        title:'终端信息',
                        fields:{
                            terminalCode:"终端号",
                            posDeviceNum:'POS机设备号',
                            posModel:"POS机型号",
                            posMade:'POS机厂商',
                            keyboardDeviceNum:"键盘设备号",
                            keyboardModel:'键盘型号',
                            keyboardMade:'键盘厂商',
                            simDeviceNum:'SIM卡号',
                            simModel:'SIM型号',
                            simMade:'SIM运营商',
                            'receiveBankModel.name':'收单银行',
                            'bankModel.name':'开户银行',
                            lendRate:'费率(借)',
                            loanRate:'费率(贷)',
                            outRate:'费率(外)',
                            originRate:'费率(本)',
                            specialCoast:'特殊计费',
                            level:'商户等级',
                            expansion:'拓展人',
                            expansionCoast:'拓展费用',
                            platform:'所属平台',
                            bankAgent:'银行经办人',
                            expansionType:'拓展方式',
                            channel:'渠道',
                            transactionControl:'交易控制',
                            receiveDate:{name:'收单日期'},
                            postcode:'邮政编码',
                            serialNumber:'申请序号',
                            risk:'风险等级',
                            authorized:'被授权人',
                            authorizedIdcode:{name:'身份证'},
                            authorizedValidityStartDate:{name:'有效期开始'},
                            authorizedValidityEndDate:{name:'有效期结束'},
                            registerDate:{name:'登记日期'},
                            startDate:{name:'启用日期'},
                            aliCoastRate:{name:'支付宝费率'},
                            wechatCoastRate:{name:'微信费率'},
                            // salesman:'业务员',
                            // salesmanNum:'业务员工号',
                            // externalChannels:'外部渠道',
                            // clientManagerPhone:'客户经理电话',
                            // district:'区域',
                            contractor:'承包人',
                            statusDup:'状态',
                            servedAt:'安装时间',
							deviceRemark:'安装备注',
							'order.facadeImg':{name:'门面照', type:'slide', slide: 'slide'},
							'order.innerImg':{name:'室内照', type:'slide', slide: 'slide'},
							'order.deskImg':{name:'前台照', type:'slide', slide: 'slide'},
							'order.physicalImg':{name:'机身照', type:'slide', slide: 'slide'},
							'order.salesImg':{name:'签购单', type:'slide', slide: 'slide'},
							'order.signImg':{name:'签名照', type:'slide', slide: 'slide'},
  							  
                        }
                    }
                ]}
            />
        );
    }

    showClient() {
        this.layOpen({
            title:'查看商户 ' + this.state.model.client.name,
            content:'/admin/user/detail/'+this.state.model.uid,
            area:['90%', '96%'],
            btn:['确定'],
            yes: (index) => {
                layer.close(index);
            }
        })
    }
}

