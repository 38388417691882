import Base from "../../base";
import CompanyListLayout from "../layout";

export default class CompanyChooseList extends Base
{
    render() {
        return (
            <CompanyListLayout
                canCheck={true}
                oprationShow={false}
                btnShow={false}
            />
        );
    }
}
