import Base from "../../base";
import Form from "../../layout/form";
import PosListLayout from "../pos/layout";
import KeybordListLayout from "../keybord/layout";
import SimListLayout from "../sim/layout";

export default class DeviceChoose extends Base
{
    boot() {
        this.state = {
            pos:[],
            sim:[],
            keyboard:[]
        }
    }

    componentDidMount() {
        /* global layer */
    }

    render() {
        return (
            <Form
               fields={{
                   terminalCode:'终端号',
                   pos:{
                       type:'choose',
                       name:'POS机',
                       click: this.choosePos.bind(this),
                       component:(
                           <PosListLayout
                                autoload={false}
                                list={this.state.pos}
                                show={this.state.pos.length}
                                paging={false}
                                btnShow={false}
                                oprationShow={false}
                                layuiFluid={false}
                                layuiCardBody={false}
                                searchShow={false}
                                hidden={['proddedAt']}
                           />
                       )
                   },
                   keyboard:{
                       type:'choose',
                       name:'键盘',
                       click: this.chooseKeyboard.bind(this),
                       component:(
                           <KeybordListLayout
                               autoload={false}
                               list={this.state.keyboard}
                               show={this.state.keyboard.length}
                               paging={false}
                               btnShow={false}
                               oprationShow={false}
                               layuiFluid={false}
                               layuiCardBody={false}
                               searchShow={false}
                               hidden={['proddedAt']}
                           />
                       )
                   },
                   sim:{
                       type:'choose',
                       name:'SIM卡',
                       click: this.chooseSim.bind(this),
                       component:(
                           <SimListLayout
                               autoload={false}
                               list={this.state.sim}
                               show={this.state.sim.length}
                               paging={false}
                               btnShow={false}
                               oprationShow={false}
                               layuiFluid={false}
                               layuiCardBody={false}
                               searchShow={false}
                               hidden={['proddedAt']}
                           />
                       )
                   },
                   input:{
                       type:'component',
                       component:(
                           <div style={{display:'none'}}>
                               <input type="text" name={"pos"} value={JSON.stringify(this.state.pos[0])}/>
                               <input type="text" name={"keyboard"} value={JSON.stringify(this.state.keyboard[0])}/>
                               <input type="text" name={"sim"} value={JSON.stringify(this.state.sim[0])}/>
                           </div>
                       )
                   }
               }}
            />
        )
    }

    choosePos() {
        this.layOpen({
            title:'选择POS机',
            content:'/admin/device/pos/choose/list?companyId=' + this.app.getUrlParams('companyId'),
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (! data || ! data.length) {
                    return layer.msg('请选择数据!');
                }
                this.setState({
                    pos:[JSON.parse(data[0])]
                })
                layer.close(index);
            }
        })
    }

    chooseKeyboard() {
        this.layOpen({
            title:'选择键盘',
            content:'/admin/device/keyboard/choose/list?companyId='+ this.app.getUrlParams('companyId'),
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (! data || ! data.length) {
                    return layer.msg('请选择数据!');
                }
                this.setState({
                    keyboard:[JSON.parse(data[0])]
                })
                layer.close(index);
            }
        })
    }

    chooseSim() {
        this.layOpen({
            title:'SIM卡',
            content:'/admin/device/sim/choose/list?companyId='+ this.app.getUrlParams('companyId'),
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (! data || ! data.length) {
                    return layer.msg('请选择数据!');
                }
                this.setState({
                    sim:[JSON.parse(data[0])]
                })
                layer.close(index);
            }
        })
    }
}
