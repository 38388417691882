import BaseService from '../base';
import Validator from "../../../../vendor/framework/validator";

export default class InstallApplyService extends BaseService {
    requestUrls = {
        url: '/order/apply/list',
        detail: '/order/apply/detail',
        delete: '/order/apply/delete',
        audit:'/order/apply/audit'
    }

    status = ['待审核', '审核通过', '审核拒绝'];

    appends = ['statusDup'];

    save(model, suc, err) {
        let validator = new Validator(),
            rules = [];

        if (!validator.validate(rules, model)) {
            return err(validator.getError());
        }
        this.post(
            this.requestUrls.save,
            model,
            suc,
            err
        )
    }

    getDetail(id, suc) {
        this.post(
            this.requestUrls.detail,
            {id: id},
            suc
        )
    }

    delete(id, suc, err) {
        this.post(
            this.requestUrls.delete,
            {id: id},
            suc,
            err
        )
    }

    audit(model, suc, err) {
        this.post(
            this.requestUrls.audit,
            model,
            suc,
            err
        )
    }

    getStatusDupAttribute() {
        return this.status[this.attributes['status']];
    }
}
