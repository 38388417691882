import App from "../framework/app";
import $ from 'jquery';

class Page
{
    app = new App();

    pageLimits = [15, 50, 100, 500,1000, 2000, 5000];

    layout = ['count', 'prev', 'page', 'next', 'limit', 'skip'];

    view = null;

    perPage = 15;

    page = 1;

    constructor(view) {
        this.view = view;
    }

    render(conf = {}) {

        if (this.view.server) {
            let view = this.view,
                page = conf.page || view.getPaging('page'),
                perPage = conf.perPage || view.getPaging('perPage');

            if (! conf.url && this.view.server && this.view.server.requestUrls) {
                conf.url = this.view.server.requestUrls.url;
            }

            if (conf.url && conf.url.indexOf('http') < 0) {
                conf.url = this.app.getServerUrl(conf.url);
            }

            conf.data = conf.data || {};

            conf.data.page = page || this.page;
            conf.data.perPage = perPage || this.perPage;
            let search = $.extend({}, view.state.search),
                requestParams = view.state.requestParams;

            if (search) {
                conf.data.search = {};
                let unsets = ['keywords', 'dateMin', 'dateMax'];
                unsets.forEach(item => {
                    if (search.hasOwnProperty(item)) {
                        conf.data[item] = search[item];
                    }
                })

                for(var k in search) {
                    if (unsets.indexOf(k) === -1) {
                        conf.data.search[k] = search[k];
                    }
                }
            }

            if (requestParams) {
                for(let k in requestParams) {
                    conf.data[k] = requestParams[k];
                }
            }

            if (view.state.orderBy.length) {
                conf.data.orderBy = view.state.orderBy;
            }

            var reConf = Object.create(conf);

            if (view.server) {
                let appends = view.server.listAppends;
                if (appends) {
                    view.server.makeAppends(typeof appends === 'function' ? appends() : appends);
                }
            }

            reConf.success = (response) => {
                if (response) {
                    if (response.total && response.currentPage > response.lastPage) {
                        conf.page = response.lastPage;
                        return this.render(conf);
                    }
                    conf.success && conf.success(response);
                }

                this.loadPage(view.paging.id, response, (obj) => {
                    conf.page = obj.curr;
                    conf.perPage = obj.limit;
                    conf.jump && conf.jump(obj);
                    this.render(conf);
                })
            }
            return this.view.server.request(reConf);
        }
    }

    loadPage(id, data, jump) {
        /* global layui */
        var self =  this;
        layui.use(['laypage'], () => {
            layui.laypage.render({
                elem: id
                ,count: data.total, //数据总数，从服务端得到
                limit: data.perPage,
                limits: self.pageLimits,
                layout: this.layout,
                curr: data.currentPage,
                jump(obj, first) {
                    if (! first) {
                        jump(obj);
                       self.view.setPaging({
                           page:obj.curr,
                           perPage:obj.limit
                       })
                    }
                    layui.form && layui.form.render();
                },
            });
        })
    }
}
export default Page;
