import Base from "../../../base";
import HelpService from "../../../../services/running/help";
import Form from "../../../layout/form";

export default class HelpEdit extends Base
{
    boot() {
        this.state = {
            model: {
                title:'',
                client:0,
                content:'',
                status:1
            }
        }
    }

    service() {
        return HelpService;
    }

    componentDidMount() {
        this.server.getDetail(this.getPropsParams('id'), (data) => {
            this.setModel(data);
        })
    }

    render() {
        return (
            <Form
                model={this.state.model}
                fields={{
                    title:'标题',
                    client:{name:'所属端口', type:'select', data:this.server.clients},
                    content:{name:'内容', type:'editor',width:'100%',height:'500px'},
                    status:{name:'状态', type:'select', data:this.server.status}
                }}
            />
        );
    }
}

