import Service from '../../../../vendor/framework/service';
import Auth from "../auth";

class BaseService extends Service
{
    auth = new Auth();

    time = 0;

    limit = 1000;

    notLogin(response) {
        /* global layer */
        if (! this.time) {
            this.setTime();
            layer.msg(response.message);
            this.app.removeItem(this.auth.filed);
            this.app.setItem(this.auth.lastUrl, window.location.pathname);
            this.app.redirect(this.auth.path, 1000);
        }
    }

    setTime() {
        this.time = 1;
        setTimeout(() => {
            this.time = 0;
        }, this.limit)
    }

    notRule(response) {
        layer.msg(response.message);
    }
}
export default BaseService;
