import Base from "../../../base";
import OrderMaintainingService from "../../../../services/order/maintaining";
import Form from "../../../layout/form";
import UserListLayout from "../../../user/layout/list";
import UserService from "../../../../services/user";
import CompanyUserListLayout from "../../../company/user/layout";
import UserDeviceListLayout from "../../../user/device/layout";

export default class OrderMaintainingAdd extends Base
{
    boot() {
        this.state = {
            model:{
                username:'',
                phone:'',
                address:'',
                status:0,
                deviceId:0,
                orderType:0
            },
            client:[],
            companyUser:[],
            device:[]
        }
    }

    service() {
        return OrderMaintainingService;
    }

    componentDidMount() {
        /* global layer */
    }

    render() {
        return (
            <Form
                model={this.state.model}
                fields={{
                    orderType:{name:'类型', type:'select', data: this.server.orderTypes},
                    client:{
                        name:'商户',
                        type:'choose',
                        component: this.client.bind(this),
                        click: this.chooseClient.bind(this)
                    },
                    deviceType:{
                        name:'设备类型',
                        type:'select',
                        data:['POS机', '键盘', 'SIM卡'],
                        show:state => state.model.orderType === 0
                    },
                    device:{
                       name:'终端',
                       type:'choose',
                        component: this.device.bind(this),
                        click: this.chooseDevice.bind(this)
                    },
                    username:'联系人',
                    phone:'联系电话',
                    address:{name:'地址', type:'textarea'},
                    remark:{name:'备注（问题描述）', type:'textarea'},
                    visitedAt:{name:'上门时间', type:'date', format:'datetime'},
                    companyUser:{
                        name:'负责人',
                        type:'choose',
                        component: this.companyUser.bind(this),
                        click: this.chooseCompanyUser.bind(this)
                    },
                    status:{name:'状态', type:'select', data: this.server.status}
                }}
            />
        )
    }

    client() {
        return (
            <UserListLayout
                autoload={false}
                list={this.state.client}
                paging={false}
                btnShow={false}
                show={this.state.client.length}
                searchShow={false}
                oprationShow={false}
                layuiFluid={false}
                layuiCardBody={false}
                hidden={['statusDup']}
                inputName={'uid'}
            />
        );
    }

    chooseClient() {
        this.layOpen({
            title: '选择商户',
            content: '/admin/user/choose/list',
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (!data || !data.length) {
                    return layer.msg('请选择商户');
                }

                let client = JSON.parse(data[0]);
                this.setState({
                    client: [client],
                })
                this.server.getService(UserService).getDetail(client.id, data => {
                    let model = this.state.model;
                    model.username = data.information.contact;
                    model.phone = data.information.tel;
                    model.address = data.information.address;
                    this.setModel(model);
                })
                layer.close(index);
            }
        })
    }

    device() {
        return (
            <UserDeviceListLayout
                list={this.state.device}
                layuiFluid={false}
                layuiCardBody={false}
                paging={false}
                show={this.state.device.length}
                inputName={"deviceId"}
                btnShow={false}
                searchShow={false}
                oprationShow={false}
                autoload={false}
            />
        )
    }

    chooseDevice() {
        if (! this.state.client.length) {
            return layer.msg('请选择商户!');
        }
        let uid = this.state.client[0].id;
        this.layOpen({
            title: '选择终端',
            content: '/admin/user/device/choose/list?uid='+uid+'&status=3',
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (!data || !data.length) {
                    return layer.msg('请选择数据');
                }
                let device = JSON.parse(data[0]);
                this.setState( state => {
                    let model = state.model;
                    model.deviceId = device.deviceId;
                    return {
                        device: [device],
                        model: model
                    }
                })
                layer.close(index);
            }
        })
    }

    companyUser() {
        return (
            <CompanyUserListLayout
                autoload={false}
                list={this.state.companyUser}
                show={this.state.companyUser.length}
                paging={false}
                btnShow={false}
                searchShow={false}
                oprationShow={false}
                layuiFluid={false}
                layuiCardBody={false}
                inputName={'cuid'}
            />
        )
    }

    chooseCompanyUser() {
        this.layOpen({
            title: '选择负责人',
            content: '/admin/company/user/choose/list',
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (!data || !data.length) {
                    return layer.msg('请选择数据');
                }
                let user = JSON.parse(data[0]);
                this.setState( state => {
                    let model = state.model;
                    model.cuid = user.id;
                    return {
                        companyUser: [user],
                        model: model
                    }
                })
                layer.close(index);
            }
        })
    }
}
