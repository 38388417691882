import BaseService from "../base";
import File from "../../../../vendor/framework/file";

export default class OrderPaymentQrcodeService extends BaseService
{
    file = new File();

    requestUrls = {
        url:'/order/payment-qrcode/list',
        detail:'/order/payment-qrcode/detail',
        delete:'/order/payment-qrcode/delete',
        change:'/order/payment-qrcode/change',
    }

    status = ['未处理', '已处理'];

    appends = ['statusDup'];

    getDetail(id, suc) {
        this.post(
            this.requestUrls.detail,
            {id:id},
            suc
        )
    }

    delete(id, suc, err) {
        this.request({
            url:this.requestUrls.delete,
            data:{id:id},
            success:suc,
            error:err
        })
    }

    change(model, suc, err) {
        this.request({
            url:this.requestUrls.change,
            data:model,
            success:suc,
            error:err
        })
    }

    getStatusDupAttribute() {
        return this.status[this.attributes['status']];
    }

    getReceiptAttribute() {
        return this.file.make(this.attributes['receipt']);
    }
}
