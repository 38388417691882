import Table from "../../../layout/table";
import BankService from "../../../../services/running/bank";
import View from "../../../../../../vendor/framework/view";

export default class BankListLayout extends View
{
    componentDidMount() {
        /* global layer */
    }

    render() {
        return (
            <Table
                service={BankService}
                props={this.props}
                searchInit={{
                    keywords:{name:'关键字', enter: true},
                    type:{name:'类型', type:'select', data:[{value:'', name:'--全部--'},{value:0, name:'总行'}, {value:1,name:'分行'},{value:2,name:'支行'}], option:['value', 'name']},
                    status:{name:'状态', type:'select', data:[{value:'', name:'--全部--'},{value:0, name:'禁用'}, {value:1,name:'启用'}], option:['value', 'name']},
                }}
                fieldsInit={{
                    id:{name:'ID', checkbox: true},
                    name:'银行名称',
                    typeDup:'类型',
                    account:'账号',
                    statusDup:'状态',
                    createdAt:'添加时间'
                }}
                oprationInit={{
                    edit:{name:'编辑', icon: ['edit'], click: this.edit.bind(this)},
                    delete:{name: '删除', icon:['delete', 'danger'], click:this.delete.bind(this)}
                }}
                btnInit={{
                    add:{name:'添加', click: this.add.bind(this)}
                }}
            />
        );
    }

    add(table) {
        this.layOpen({
            title:'添加',
            content:'/admin/running/bank/add',
            form:true,
            area:['800px', '80%'],
            yes: (field, index) => {
                table.server.save(field, () => {
                    layer.msg('添加成功!');
                    layer.close(index);
                    table.loadData();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    edit(model, table) {
        this.layOpen({
            title:'修改 '+model.name,
            content:'/admin/running/bank/edit/'+model.id,
            form:true,
            area:['800px', '80%'],
            yes: (field, index) => {
                field.id = model.id;
                table.server.save(field, () => {
                    layer.msg('修改成功!');
                    layer.close(index);
                    table.loadData();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    delete(model, table) {
        layer.confirm(`您真的要删除 ${model.name} 吗?`, () => {
            table.server.delete(model, () => {
                layer.msg('删除成功!');
                table.pageReload();
            }, (msg) => {
                layer.msg(msg);
            })
        })
    }
}
