import UserService from "../../../services/user";
import View from "../../../../../vendor/framework/view";
import Table from "../../layout/table";
import Excel from "../../../components/excel";

class UserListLayout extends View
{
    service() {
        return UserService;
    }

    componentDidMount() {
        /* global layer */
    }

    render() {
        return (
            <Table
               props={this.props}
               service={UserService}
               stateInit={{
                   orderBy:['id', 'desc']
               }}
               searchInit={{
                   keywords:{name:'关键字', enter: true, placeholder:'请输入编号/名称'},
                   company:{name:'所属公司', type:'component', component: this.buttonComponent.bind(this)},
                   status:{name:'账户状态', type:'select', data: this.server.status, default:''},
                   'information.status':{name:'审核状态', data: this.server.auditStatus, default:'', type:'select'},
                   dateMin:{name:'注册时间',  type:'date', position:0, format:'datetime', placeholder:'开始时间'},
                   dateMax:{type:'date', position:1, format:'datetime',placeholder:'结束时间'},
               }}
               fieldsInit={{
				   
                   id:{name:'ID', checkbox: true, style:{whiteSpace:'nowrap'}}, 
                   image:{name:'图片', type:'image'},
                   code:{
                       name:'商户编号',
                       value: model => <div style={{overflow:'hidden',textOverflow:'ellipsis'}} title={model.information.code}>{model.information.code}</div>
                   },
                   name:{
                       name:'名称',
                       title: model => model.information.name,
                       value: model => model.information.name,
                       style:{width:'200px',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}
                    },
                   phone:{name:'手机号码', value: model => <div style={{overflow:'hidden',textOverflow:'ellipsis'}} title={model.phone}>{model.phone}</div>},
                   companyName:{
                       name:'所属公司',
                       value: model => model.information.company ? model.information.company.name : '',
                       style:{width:'200px',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}
                   },
                   address:{
                       name:'地址',
                       title: model => model.information.address,
                       value: model => <div style={{width:'200px',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{model.information.address}</div>
                   },
                   type:{
                       name:'类型',
                       value: model => model.information.type ? model.information.type.name : '',
                       style:{width:'200px',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}
                   },
                   attr:{
                       name:'属性',
                       value: model => model.information.attr ? model.information.attr.name : '',
                       style:{width:'200px',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}
                   },
                   qrcode:{name:'二维码', type:'image'},
                   unSend:'待派装机',
                   sent:'已派装机',
                   installed:'已装机',
                   unrepeal:'已派撤机',
                   repealed:'已撤机',
                   statusDup:'账号状态',
                   auditStatusDup:'审核状态',
                   createdAt:{
                       name:'注册时间',
                       value:model => (<div style={{width:'160px'}}>{model.createdAt}</div>)
                   },
                   location:{name:'所在位置', type:'component', component:this.locationBtn.bind(this)}
               }}
               oprationInit={{
                   deviceList:{name:'终端列表', icon:['list'], click: this.deviceList.bind(this)},
                   orderList:{name:'工单记录', icon:['list'], click: this.orderList.bind(this)},
                   detail:{name:'详情', icon:['list'], click: this.detail.bind(this)},
                   edit:{name:'编辑', icon: ['edit'], click: this.edit.bind(this)},
                   audit:{name:'审核', icon:['', 'warm', 'iconfont icon-shenhe'], disabled: model => model.information ? model.information.status !== 0 : true, click: this.audit.bind(this)},
                   changeStatus:{
                       name: model => { return model.status === -1 ? '启用' : '禁用'},
                       icon:['', 'warm', 'iconfont icon-jinyong'],
                       click: this.changeStatus.bind(this),
                       disabled: model => {
                           return [-1, 2].indexOf(model.status) === -1;
                       }
                   }
               }}
               oprationStyle={{
                   whiteSpace:'nowrap'
               }}
               btnInit={{
                   add:{name:'添加', click:this.add.bind(this)},
                   excelLayout: {name: 'EXCEL模板下载', className: 'layui-btn-warm', click: this.excelLayout.bind(this)},
                   import:{name:'导入', className:'layui-btn-normal', click: this.import.bind(this)},
				   export:{name:'导出', click: this.export.bind(this)},
               }}
               headerStyle={{
                   whiteSpace:'nowrap'
               }}

            />
        )
    }

    buttonComponent(table) {
        return (
            <button className={"layui-btn"} onClick={e => this.chooseCompany(table)}>{ table.state.companyName || '所有公司'}</button>
        )
    }

    chooseCompany(table) {
        this.layOpen({
            title:'选择集团',
            content:'/admin/company/choose/list',
            form: true,
            area:['900px', '90%'],
            yes: (fields, index) => {
                fields = this.app.htmlArrayToJson(fields);
                let data = fields.data,
                    model = data && data.length ? JSON.parse(data[0]) : {id:'', name:''};
                table.setState({
                    companyName:model.name,
                })
                table.setSearch({
                    'information.company_id':model.id
                })
                table.loadData();
                layer.close(index);
            }
        })
    }

    locationBtn(model) {
        return (
            <button onClick={e => this.showMap(model)} className={"layui-btn layui-btn-normal layui-btn-xs"}>
                <i className={"layui-icon layui-icon-list"}></i>
                <span>查看地图</span>
            </button>
        )
    }

    showMap(model) {
        this.layOpen({
            title:model.information.name + ' 的所在位置',
            content:`/admin/map?longitude=${model.information.longitude}&latitude=${model.information.latitude}`,
            btn:['确定'],
            yes:(index) => {
                layer.close(index);
            }
        })
    }

    add(table) {
        this.layOpen({
            title:'添加商户',
            content:'/admin/user/add',
            form:true,
            area: ['90%', '98%'],
            yes: (field, index) => {
                field = this.app.htmlArrayToJson(field);
                this.server.save(field, () => {
                    layer.msg('添加成功!');
                    layer.close(index);
                    table.loadData();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    //用户详情
    edit(user, table) {
        this.layOpen({
            title:'修改商户 ' + user.information.name,
            content:'/admin/user/edit/'+user.id,
            form:true,
            area: ['90%', '98%'],
            yes: (field, index) => {
                field.id = user.id;
                field = this.app.htmlArrayToJson(field);
                this.server.save(field, () => {
                    layer.msg('修改成功!');
                    layer.close(index);
                    table.loadData();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    delete(model, table) {
        layer.confirm('您真的要删除 ' + ((model.nickname || model.accout) || model.email) + ' 这个用户吗?', () => {
            this.server.delete(model.id, () => {
                layer.msg('删除成功!');
                table.loadData();
            }, (msg) => {
                layer.msg(msg);
            })
        })
    }

    changeStatus(model, table) {
        let type = model.status === -1 ? '启用' : '禁用';
        layer.confirm('您真的要 ' + type + ' 这个商户吗?', () => {
            this.server.changeStatus(model.id, () => {
                layer.msg(type+'成功!');
                table.loadData();
            }, (msg) => {
                layer.msg(msg);
            })
        })
    }

    deviceList(model) {
        this.layOpen({
            title:'终端列表',
            content:'/admin/user/device/list?uid='+model.id,
            btn:['确定'],
            yes: (index) => {
                layer.close(index);
            }
        })
    }

    detail(model) {
        this.layOpen({
            title:'商户详情',
            content:'/admin/user/detail/'+model.id,
            btn:['确定'],
            yes: (index) => {
                layer.close(index);
            }
        })
    }

    orderList(model) {
        this.layOpen({
            title:'安装工单记录',
            content:'/admin/order/user/install/list?uid='+model.id,
            btn:['确定'],
            yes: (index) => {
                layer.close(index);
            }
        })
    }

    audit(model, table) {
        layer.confirm('审核结果', {icon:3, title:'审核', btn:['通过', '拒绝', '取消']}, () => {
            audit(1);
        }, () => {
            audit(2);
        })

        function audit(status) {
            table.server.audit({uid:model.id, status:status}, () => {
                layer.msg('保存成功!');
                table.pageReload();
            }, message => {
                layer.msg(message);
            })
        }
    }

    excelLayout() {
        let keys = Object.keys(this.server.excelFields),
            arr = [],
            data = [
                {
                    id:'1',
                    name:'广州裕富联',
                    code:'YFL100000',
                    phone:'13000000000',
                    company:'裕富联总部',
                    type:'奶茶店',
                    attr:'珍珠奶茶',
                    province:"广东省",
                    city:'广州市',
                    area:'天河区',
                    address:'车陂广场',
                    bankAddress:'Xzcc',
                    receiveBank:'中国人民银行',
                    bank:'中国人民银行',
                    lendRate:'1%',
                    loanRate:'1%',
                    outRate:'1%',
                    originRate:'1%',
                    specialCoast:'50',
                    level:'1',
                    expansion:'老张',
                    expansionCoast:'10',
                    platform:'1342',
                    bankAgent:'银行经办人',
                    expansionType:'拓展方式',
                    channel:'渠道',
                    transactionControl:'交易控制',
                    receiveDate:'2022-10-10',
                    postcode:'510000',
                    serialNumber:'123456',
                    risk:'0',
                    corporate:'张小明',
                    corporatePhone:'13000000000',
                    corporateTel:'',
                    corporateFax:'',
                    corporateIdcode:'',
                    corporateValidityStartDate:'',
                    corporateValidityEndDate:'',
                    contact:'张小明',
                    tel:'',
                    salesman:'张小明',
                    salesmanNum:'XX123',
                    externalChannels:'',
                    clientManagerPhone:'',
                    district:'',
                }
            ];
        arr[0] = this.server.getExcelTitles();
        data.forEach((item, index) => {
            let k = index + 1;
            arr[k] = [];
            for(let j in keys) {
                let field = keys[j];
                if (item.hasOwnProperty(field)) {
                    arr[k].push(item[field]);
                }
            }
        })

        let excel = new Excel();
        excel.export('商户导入模板', arr, (workbook, table) => {
            workbook.sheet(0).row(1).height(50);
            workbook.sheets().forEach((sheet) => {
                // 表头加粗及背景色
                sheet.range('A1:AS1').style({
                    wrapText: true,
                    fill: "00bdf0",
                    bold: true,
                    horizontalAlignment: "center",
                    verticalAlignment: "center",
                    fontSize: 12,
                    border: {
                        style: 'thin',
                        color: "000000",
                        direction: "both"
                    }
                });

                for (let k in table[0]) {
                    sheet.column(k).width(25);
                }
            })
        });
    }

    import(table) {
        this.layOpen({
            title: '导入',
            content: '/admin/user/import',
            area: ['90%', '98%'],
            form: true,
            yes: (data, index) => {
                data = this.app.htmlArrayToJson(data);
                table.server.import(data, () => {
                    layer.close(index);
                    table.loadData();
                    layer.msg('导入成功!');
                }, (msg) => {
                    layer.alert(msg);
                })
            }
        })
    }
	 
	export(table, type = 0) {
	    let ids = this.getCheckIds();
	    if (! ids.length) {
	        return layer.msg('请选择数据!');
	    }
	    this.server.export({ids:ids, type: type}, res => {
	        if (type) {
	            let url = (new File()).make(res.path);
	            window.open(url);
	            return;
	        }
	        let list = [];
	        res.forEach(item => {
	            if (ids.indexOf(item.id) > -1) {
	                list.push(item);
	            }
	        })
	        let titles = this.server.getExcelOutTitles();
	        let keys = Object.keys(this.server.excelOutFields),
	            data = [titles]; 
	        list.forEach(item => {
	            let arr = [],
	             information = item.information || {},   
				 type = item.information.type || {},   
				 attr = item.information.attr || {},  
							row = {
							id:item.id || 0,
							name:information.name || '',
							code:information.code || '',
							phone:item.phone || '',
							company:information.company.name || '',
							province:information.province || '',
							city:information.city || '',
							area:information.area || '',
							address:information.address || '',
							type:type.name || '',
							attr:attr.name || '',
							bankAddress:information.bankAddress || '',
							receiveBank:information.receiveBank || '',
							bank:information.bank || '',
							lendRate:information.lendRate || '',
							loanRate:information.loanRate || '',
							outRate:information.outRate || '',
							originRate:information.originRate || '',
							specialCoast:information.specialCoast || '',
							level:information.level || '',
							expansion:information.expansion || '',
							expansionCoast:information.expansionCoast || '',
							platform:information.platform || '',
							bankAgent:information.bankAgent || '',
							expansionType:information.expansionType || '',
							channel:information.channel || '',
							transactionControl:information.transactionControl || '',
							receiveDate:information.receiveDate || '',
							postcode:information.postcode || '',
							serialNumber:information.serialNumber || '',
							risk:information.risk || '',
							corporate:information.corporate || '',
							corporatePhone:information.corporatePhone || '',
							corporateTel:information.corporateTel || '',
							corporateFax:information.corporateFax || '',
							corporateIdcode:information.corporateIdcode || '',
							corporateValidityStartDate:information.corporateValidityStartDate || '',
							corporateValidityEndDate:information.corporateValidityEndDate || '',
							contact:information.contact || '',
							tel:information.tel || '',
							salesman:information.salesman || '',
							salesmanNum:information.salesmanNum || '',
							externalChannels:information.externalChannels || '',
							clientManagerPhone:information.clientManagerPhone || '',
							district:information.district || '',
							unSend:item.unSend || '0',
							sent:item.sent || '0',
							installed:item.installed || '0',
							unrepeal:item.unrepeal || '0',
							repealed:item.repealed || '0',
							statusDup:item.statusDup ||'0',
							auditStatusDup:item.auditStatusDup || '',
							createdAt:item.createdAt || '',
							  } 
	              	   
 				
				keys.forEach(field => {
				        arr.push(row[field] || '');
				    })
				data.push(arr);
				
				
	        }) 
	
	        let excel = new Excel();
	        excel.export('商户列表导出', data, (workbook, table) => {
	            workbook.sheet(0).row(1).height(50);
	            workbook.sheets().forEach((sheet) => {
	                // 表头加粗及背景色
	                sheet.range('A1:AZ1').style({
	                    wrapText: true,
	                    fill: "00bdf0",
	                    bold: true,
	                    horizontalAlignment: "center",
	                    verticalAlignment: "center",
	                    fontSize: 12,
	                    border: {
	                        style: 'thin',
	                        color: "000000",
	                        direction: "both"
	                    }
	                });
	
	                for (let k in table[0]) {
	                    sheet.column(k).width(25);
	                }
	            })
	        })
	    })
	} 
	
	
	
}
export default UserListLayout;
