import View from "../../../../../../vendor/framework/view";
import Table from "../../../layout/table";
import SimService from "../../../../services/device/sim";
import * as XLSX from 'xlsx';
import Excel from "../../../../components/excel";

export default class SimListLayout extends View
{
    boot() {
        let status = this.app.getUrlParams('status');
        this.state = {
            orderBy:['id', 'desc'],
            search:{
                status: status != undefined ? status : '',
                type:2
            }
        }
    }

    service() {
        return SimService;
    }

    render() {
        return (
            <Table
                props={this.props}
                service={SimService}
				canCheck={true}
				checkMany={true}
                stateInit={this.state}
                searchInit={{
                    keywords:{name:'关键字', enter: true},
                    company: {name: '所属公司', type: 'component', component: this.buttonComponent.bind(this)},
                    status:{name:'状态', data: this.server.status, default:'', type:'select'}
                }}
                fieldsInit={{
                    id:{name:'ID', checkbox: true},
                    company:{name:'所属公司', value(model) {
                        return model.company.name;
                    }},
                    model:'型号',
                    deviceNum:'设备号',
                    made:'厂商',
                    statusDup:'状态',
                    createdAt:'入库时间',
                    proddedAt:'出库时间'
                }}
				oprationInit={{
					logdetail: {name: '记录', icon: ['list', 'normal'], click: this.logdetail.bind(this)},
				}}
                btnInit={{
                    import:{name:'excel导入', click: this.import.bind(this)},
                    excelLayout:{name:"excel模板下载", click: this.downloadExcelLayout.bind(this)},
					export:{name:'导出', click: this.export.bind(this)}
                }}
            />
        );
    }

    componentDidMount() {
        /* global layer */
    }

    buttonComponent(table) {
        return (
            <button className={"layui-btn"}
                    onClick={e => this.chooseCompany(table)}>{table.state.companyName || '所有公司'}</button>
        )
    }

    chooseCompany(table) {
        this.layOpen({
            title: '选择公司',
            content: '/admin/company/choose/list',
            form: true,
            area: ['900px', '90%'],
            yes: (fields, index) => {
                fields = this.app.htmlArrayToJson(fields);
                let data = fields.data,
                    model = data && data.length ? JSON.parse(data[0]) : {id: '', name: ''};
                table.setState({
                    companyName: model.name,
                })
                table.setSearch({
                    'company_id': model.id
                })
                table.loadData();
                layer.close(index);
            }
        })
    }
	
	logdetail(model) {
	    this.layOpen({
	        title: '日志记录【' + model.deviceNum+'】',
	        content: '/admin/device/log/' + model.id,
	        area: ['90%', '90%'],
	        btn: ['确定'],
	        yes: (index) => {
	            layer.close(index);
	        }
	    })
	}

    import(table) {
        this.layOpen({
            title:'导入',
            content:'/admin/device/pos/import',
            form:true,
            yes:(data, index) => {
                data = this.app.htmlArrayToJson(data);
                table.server.add(data, () => {
                    layer.close(index);
                    table.loadData();
                    layer.msg('导入成功!');
                }, (msg) => {
                    layer.alert(msg);
                })
            }
        })
    }

    downloadExcelLayout() {
        let arr = [
            ['序号', '所属分部', '型号', '设备号', '厂商'],
            ['（例如）1', '广州裕富联分公司1', 'K320', 'k32021k0716', ' 广州裕富联信息科技有限公司']
        ]
        // 将数组转化为标签页
        let ws = XLSX.utils.aoa_to_sheet(arr);
        // 创建工作薄
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb,ws);
        XLSX.writeFile(wb,'SIM卡导入模板.xlsx');
    }
	
export(table, type = 0) {
	    let ids = this.getCheckIds();
	    if (! ids.length) {
	        return layer.msg('请选择数据!');
	    }
	    this.server.export({ids:ids, type: type}, res => {
	        if (type) {
	            let url = (new File()).make(res.path);
	            window.open(url);
	            return;
	        }
	        let list = [];
	        res.forEach(item => {
	            if (ids.indexOf(item.id) > -1) {
	                list.push(item);
	            }
	        })
	        let titles = this.server.getExcelTitles();
	        let keys = Object.keys(this.server.exportTitle),
	            data = [titles];
	        list.forEach(item => {
	            let arr = [],
				company = item.company  || {},
	                row = {
	                    id:item.id,
	                    company:company.name,
	                    model:item.model,
	                    deviceNum:item.deviceNum,
	                    made:item.made,
	                    statusDup:item.statusDup,
	                    createdAt:item.createdAt,
	                    proddedAt:item.proddedAt,
	                    logDup:item.logDup,
 	                }
	                keys.forEach(field => {
	                    arr.push(row[field] || '');
	                })
	            data.push(arr);
	        })
			
	        let excel = new Excel();
	        excel.export('SIM列表', data, (workbook, table) => {
	            workbook.sheet(0).row(1).height(50);
	            workbook.sheets().forEach((sheet) => {
	                // 表头加粗及背景色
	                sheet.range('A1:BM1').style({
	                    wrapText: true,
	                    fill: "00bdf0",
	                    bold: true,
	                    horizontalAlignment: "center",
	                    verticalAlignment: "center",
	                    fontSize: 12,
	                    border: {
	                        style: 'thin',
	                        color: "000000",
	                        direction: "both"
	                    }
	                });
	
	                for (let k in table[0]) {
	                    sheet.column(k).width(25);
	                }
	            })
	        })
	    })
	}
	
	
	
	
	
	
	
	
	
	
	
	
}
