import Table from "../../../../layout/table";
import View from "../../../../../../../vendor/framework/view";
import InstallApplyService from "../../../../../services/order/installApply";
import OrderInstallService from "../../../../../services/order/install";

export default class OrderInstallApplyListLayout extends View {
    render() {
        return (
            <Table
                props={this.props}
                service={InstallApplyService}
                searchInit={{
                    keywords: {name: '关键字', enter: true},
                    status:{name:'状态', type:'select', data:[{id:'', title:'--全部--'},{id:0,title:'待审核'}, {id:1,title:'审核通过'}, {id:2, title:'审核拒绝'}], option:['id', 'title']},
                    dateMin:{name:'申请时间', type:'date', position:0},
                    dateMax:{type:'date', position:1}
                }}
                props={this.props}
                fieldsInit={{
                    id: {name: 'ID'},
                    orderSn:'申请编号',
                    clientName: {name:'商户名称', value: model => model.client.name},
                    clientCode: {name:'商户编号', value: model => model.client.code},
                    username: '联系人',
                    phone: '联系手机',
                    statusDup:'状态',
                    createdAt: '申请时间'
                }}
                oprationInit={{
                    detail: {name: '详情', icon: ['list', 'normal'], click: this.detail.bind(this)},
                    audit:{name:'审核', icon:['', 'warm', 'iconfont icon-shenhe'], disabled: model => model.status !== 0, click: this.audit.bind(this)},
                    delete: {name: '删除', icon: ['delete', 'danger'], click: this.delete.bind(this)}
                }}
            />
        );
    }

    componentDidMount() {
        /* global layui,layer */
        layui.use(['form'], () => {

        })
    }

    detail(model) {
        this.layOpen({
            title: '详情',
            content: '/admin/order/install/apply/detail/' + model.id,
            area:['90%', '90%'],
            btn:['确定'],
            yes: (index) => {
                layer.close(index);
            }
        })
    }

    audit(model, table) {
        let _this = this;

        layer.confirm('审核结果', {icon:3, title:'审核', btn:['通过', '拒绝', '取消']}, () => {
            audit(1);
        }, () => {
            audit(2);
        })

        function audit(status) {
            table.server.audit({id:model.id, status:status}, () => {
                layer.msg('保存成功!');
                table.pageReload();
                if (status === 1) {
                    _this.layOpen({
                        title: '添加工单',
                        content: '/admin/order/install/add?uid='+model.uid,
                        form: true,
                        area: ['90%', '98%'],
                        yes: (field, index) => {
                            field.applyId = model.id;
                            table.server.getService(OrderInstallService).save(field, () => {
                                layer.msg('添加成功!');
                                layer.close(index);
                            }, (msg) => {
                                layer.msg(msg);
                            })
                        }
                    })
                }
            }, message => {
                layer.msg(message);
            })
        }
    }

    delete(model, table) {
        layer.confirm('您真的要删除吗?', () => {
            table.server.delete(model.id, () => {
                layer.msg('删除成功!');
                table.pageReload();
            }, (msg) => {
                layer.msg(msg);
            })
        })
    }
}
