import Base from "../../../base";
import SimListLayout from "../layout";

export default class SimList extends Base
{
    render() {
        return (
            <SimListLayout
                oprationShow={true}
            />
        );
    }
}
