import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";
import File from "../../../../vendor/framework/file";

export default class PaymentQrcodeService extends BaseService
{
    requestUrls = {
        url:'/running/payment-qrcode/list',
        save:'/running/payment-qrcode/save',
        detail:'/running/payment-qrcode/detail',
        delete:'/running/payment-qrcode/delete'
    }

    status = ['禁用', '启用'];

    appends = ['statusDup'];

    file = new File();

    getList(suc) {
        this.post(
            this.requestUrls.url,
            {},
            suc
        )
    }

    save(field, suc, err) {
        var validator = new Validator(),
            rules = [
                ['name', 'required', '请填写名称'],
                ['qrcode', 'required', '请上传二维码']
            ];

        if (! validator.validate(rules, field)) {
            return err(validator.getError());
        }
        field.qrcode = this.file.filter(field.qrcode);
        this.post(
            this.requestUrls.save,
            field,
            suc,
            err
        )
    }

    getDetail(id, suc) {
        this.post(
            this.requestUrls.detail,
            {id:id},
            suc
        )
    }

    delete(model, suc, err) {
        this.post(
            this.requestUrls.delete,
            {id:model.id},
            suc,
            err
        )
    }

    getStatusDupAttribute() {
        return this.status[this.attributes['status']];
    }

    getQrcodeAttribute() {
        return this.file.make(this.attributes['qrcode']);
    }
}

