import Base from "../../../base";
import Table from "../../../layout/table";
import * as XLSX from 'xlsx';
import style from './index.module.css'
import $ from 'jquery';
import PosService from "../../../../services/device/pos";
import { Fragment } from 'react';

export default class PosImport extends Base
{
    boot() {
        this.state = {
            list:[],
            fileName:'',
        }
    }

    service() {
        return PosService;
    }

    componentDidMount() {
        /* global layer,layui */
        layui.use(['form']);
    }

    render() {
        return (
            <div
                style={{background:'white',paddingBottom:'20px'}}
                onDragEnter={e => this.onDragEnter(e)}
                onDragOver={e => this.onDragOver(e)}
                onDragLeave={e => this.onDragLeave(e)}
                onDrop={e => this.onDrop(e)}
                ref={"contanier"}
            >
                <Table
                    autoload={false}
                    list={this.state.list}
                    layuiCardHeader={false}
                    fieldsInit={{
                        id:{name:'序号', value: this.inputComponent.bind(this)},
                        company:{name:'所属公司', value: this.inputComponent.bind(this)},
                        model:{name:'型号', value: this.inputComponent.bind(this)},
                        deviceNum:{name:'设备号', value: this.inputComponent.bind(this)},
                        made:{name:'厂商', value: this.inputComponent.bind(this)},
                    }}
                    paging={false}
                    oprationShow={false}
                    btnInit={{
                        file:{name:'请选择文件', click: this.chooseFile.bind(this)},
                        count:{type:'component', component: <Fragment><span>{this.state.fileName}</span><div>共有数据(<span style={{color:'red'}}>{this.state.list.length}</span>)条</div></Fragment>}
                    }}
                />
                {
                    ! this.state.list.length ? (
                        <div className={style.fileTip}>文件拖至此处进行预览</div>
                    ) : ''
                }
                <div style={{display:'none'}} className={"layui-form"}>
                    {
                        this.state.list.map((item, index) => {
                            return Object.keys(item).map((key, i) => {
                                if (this.server.excelFields.indexOf(key) > -1) {
                                    return (
                                        <input key={(index + 1) * (i + 1)} type="hidden" name={"data["+index+"]["+key+"]"} value={item[key]} />
                                    )
                                }
                            })
                        })
                    }
                    <input type="file" ref={"file"} onChange={e => this.fileChange(e)} />
                    <div className="layui-form-item layui-hide">
                        <input type="button" lay-submit="" lay-filter="LAY-user-front-submit" id="LAY-user-front-submit" value="确认" />
                    </div>
                </div>
            </div>
        );
    }

    inputComponent(model, field, i) {
        return (
            <td width={"6%"}>
                <input style={{display:model[field+'show'] ? 'block':'none'}} type="text" onBlur={e => this.showInput(e,false, field, i)} onChange={e => this.onChange(e, field, i)} className={"layui-input"} value={model[field]} />
                <div style={{display:model[field+'show'] ? 'none':'block', cursor:'pointer'}} title={"点击修改"} onClick={e => this.showInput(e,true, field, i)}>{model[field]}</div>
            </td>
        )
    }

    onChange(e, field, index) {
        let list = this.state.list,
            item = list[index];
        item[field] = e.target.value;
        this.setState({
            list:list
        })
    }

    showInput(e, show, field, index) {
        let list = this.state.list,
            card = field+'show';

        list.forEach((item, i) => {
            for(let k in item) {
                if (k.indexOf('show') > -1) {
                    item[k] = Number(index) === Number(i) && k === card && show;
                }
            }
        })

        this.setState({
            list:list
        }, () => {
            setTimeout(() => {
                if (show) {
                    $(e.target).prev().focus();
                }
            },100)
        })
    }

    onDragEnter(e) {
        this.refs.contanier.style.background = '#cbf7e3';
    }

    onDragOver(e) {
        e.preventDefault();
    }

    onDragLeave(e) {
        this.refs.contanier.style.background = 'white';
    }

    onDrop(e) {
        e.preventDefault();
        this.refs.contanier.style.background = 'white';
        let file = e.dataTransfer.files[0];
        this.refs.file.files = e.dataTransfer.files;
        if (file) {
            this.handleFile(file.name, file);
        }
    }

    chooseFile() {
        let file = this.refs.file;
        file.value = '';
        file.click();
    }

    handleFile(name, file) {
        let fileReader = new FileReader(),
            self = this,
            fileName = name.replace(/\\/g, '/').split('/');
        fileName = fileName[fileName.length - 1];

        var loadingIndex = layer.load(3)

        var ext = fileName.split('.')[1];

        if (! ext || ['xlsx'].indexOf(ext) < 0) {
            return layer.msg('文件格式不允许!');
        }

        fileReader.onload = event => {
            const {result} = event.target;
            // 以二进制流方式读取得到整份excel表格对象
            const workbook = XLSX.read(result, {type: 'binary'});

            let excelData = {};
            // 遍历每张工作表进行读取（这里默认只读取第一张表）
            for (const sheet in workbook.Sheets) {
                if (workbook.Sheets.hasOwnProperty(sheet)) {
                    var rows = workbook.Sheets[sheet];
                    var maxC = 1;
                    for(var k in rows) {
                        var num = Number(k.replace(/[^0-9]/ig,""));
                        if (num > maxC) {
                            maxC = num;
                        }
                    }
                    var letters = [];
                    for(var k in rows) {
                        var letter = k.replace(/[^a-zA-Z]/g, '');
                        var num = Number(k.replace(/[^0-9]/ig,""));
                        if (letters.indexOf(letter) < 0 && num) {
                            letters.push(letter);
                        }
                    }
                    for(var k in letters) {
                        for(var i = 1; i <= maxC; i ++) {
                            var key = letters[k] + i;
                            if (! rows.hasOwnProperty(key)) {
                                rows[key] = {w:''};
                            }
                        }
                    }

                    var keys = [];
                    for(var k in rows) {
                        var num = Number(k.replace(/[^0-9]/ig,""));
                        if (num) {
                            keys.push(k);
                        }
                    }

                    keys = keys.sort();

                    for(var k in keys) {
                        var v = keys[k];
                        var columns = rows[v];
                        if (columns.hasOwnProperty('w')) {
                            var letter = v.replace(/[^a-zA-Z]/g, '');
                            if (! excelData[letter]) {
                                excelData[letter] = [];
                            }
                            excelData[letter].push(columns.w);
                        }
                    }
                    break;
                }
            }

            let nums = [];
            for(var k in excelData) {
                nums.push(excelData[k].length);
            }
            let max = Math.max.apply(null, nums);
            for(var k in excelData) {
                for(var i = 0; i < max; i ++) {
                    if (excelData[k][i] === undefined) {
                        excelData[k][i] = '';
                    }
                }
            }

            let title = [],newData = [],list = [];
            for(var k in excelData) {
                var vals = excelData[k];
                title.push(vals[0]);
                for(var j in vals) {
                    if (Number(j)) {
                        var index = Number(j) - 1;
                        if (! newData[index]) {
                            newData[index] = [];
                        }
                        newData[index].push(vals[j]);
                    }
                }
            }

            for(var k in newData) {
                var vals = newData[k],
                    fields = {};
                for(var j in vals) {
                    fields[this.server.excelFields[j]] = vals[j];
                }
                list[k] = fields;
            }

            list.forEach(item => {
                for(let k in item) {
                    item[k +'show'] = false;
                }
            })

            self.setState(state => {
                return {
                    fileName:name,
                    //title:title,
                    list:list
                }
            }, () => {
                layer.close(loadingIndex);
            })
        }
        fileReader.readAsBinaryString(file);
    }

    fileChange(e) {
        this.handleFile(e.target.value, e.target.files[0]);
    }
}
