export default [
    {
        id:'1',
        orderSn:'I2022010211582210',
        type:'申请耗材',
        device:'POS机',
        company:'广州裕富联分公司',
        clientName:'白云学院',
        clientCode:'123456',
        username:'小陈',
        phone:'13000000000',
        address:'天河',
        terminalCode:'21112112121',
        checkUser:'陈陈',
        result:'服务完成',
        servedAt:'2022-01-10 10:10:00'
    },
    {
        id:'2',
        orderSn:'I2022010211582211',
        type:'申请耗材',
        device:'键盘',
        company:'广州裕富联分公司',
        clientName:'白云学院',
        clientCode:'123456',
        username:'小陈',
        phone:'13000000000',
        address:'天河',
        terminalCode:'21112112121',
        checkUser:'陈陈',
        result:'服务完成',
        servedAt:'2022-01-10 10:10:00'
    },
    {
        id:'3',
        orderSn:'I2022010211582212',
        type:'申请耗材',
        device:'SIM卡',
        company:'广州裕富联分公司',
        clientName:'白云学院',
        clientCode:'123456',
        username:'小陈',
        phone:'13000000000',
        address:'天河',
        terminalCode:'21112112121',
        checkUser:'陈陈',
        result:'服务完成',
        servedAt:'2022-01-10 10:10:00'
    },
    {
        id:'4',
        orderSn:'I2022010211582213',
        type:'故障',
        device:'',
        company:'广州裕富联分公司',
        clientName:'白云学院',
        clientCode:'123456',
        username:'小陈',
        phone:'13000000000',
        address:'天河',
        terminalCode:'21112112121',
        checkUser:'陈陈',
        result:'服务完成',
        servedAt:'2022-01-10 10:10:00'
    },
    {
        id:'5',
        orderSn:'I2022010211582214',
        type:'更换',
        device:'',
        company:'广州裕富联分公司',
        clientName:'白云学院',
        clientCode:'123456',
        username:'小陈',
        phone:'13000000000',
        address:'天河',
        terminalCode:'21112112121',
        checkUser:'陈陈',
        result:'服务完成',
        servedAt:'2022-01-10 10:10:00'
    }
];
