import Base from "../../../base";
import OrderInstallListLayout from "../layout";

export default class OrderInstallList extends Base
{
    render() {
        return (
            <OrderInstallListLayout

            />
        );
    }
}
