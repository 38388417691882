import Base from "../../../base";
import Detail from "../../../layout/detail/detail";
import OrderInstallService from "../../../../services/order/install";
import File from "../../../../../../vendor/framework/file";
import Table from "../../../layout/table";
import PosService from "../../../../services/device/pos";
import PosListLayout from "../../../device/pos/layout";
import KeybordListLayout from "../../../device/keybord/layout";
import SimListLayout from "../../../device/sim/layout";
import UserDeviceListLayout from "../../../user/device/layout";

export default class OrderInstallDetail extends Base
{
    file = new File();

    boot() {
        this.state = {
            model:{
            },
            pos:[],
            keyboard:[],
            sim:[],
            terminal:[]
        }
    }

    service() {
        return OrderInstallService;
    }

    componentDidMount() {
        /* global layer */
        this.getDetail();
    }

    async getDetail() {
        await this.server.getDetail(this.getPropsParams('id'), data => {

            if (! data.device) {
                data.device = {};
            }

            data.terminalCode =  data.device.terminalCode;
            if (data.device.id) {
                this.setState({
                    terminal:[data.device]
                })
            }
            this.setModel(data);
        })
    }

    render() {
        return (
            <Detail
                state={this.state}
                fields={[
                    {
                        title:'工单信息',
                        fields:{
                            orderSn:'工单号',
                            typeDup:'类型',
                            username:'联系人',
                            phone:'联系电话',
                            address:'地址',
                            remark:{name:'备注(问题描述)', block:true},
                            visitedAt:'上门时间',
                            servedAt:'完成时间',
                            createdAt:'添加时间',
                            notes:{name:'员工备注',block: true},
                            statusDup:'状态',
                            auditReason:{name:'审核理由', show: model => model.auditReason }
                        }
                    },
                    {
                        title:'商户信息',
                        fields:{
                            'client.company.name':'所属公司',
                            'client.name':'商户名称',
                             detail:
                                 {name:'详情',
                                     type :'itemComponent',
                                     component: () => {
                                         return (
                                             <button className={"layui-btn layui-btn-sm layui-btn-normal"} onClick={e => this.showClient()}>
                                                <i className="layui-icon layui-icon-list"></i>
                                                查看
                                             </button>
                                         )
                                }
                            },
                        }
                    },
                    {
                        title:'安装/巡检',
                        fields:{
                            'installUser.realname':'安装人',
                             installBtn:{
                                name:'安装人信息',
                                 show: model => {
                                    return model.installUid;
                                 },
                                 type:'itemComponent',
                                 component: () => {
                                    return (
                                        <button className={"layui-btn layui-btn-sm layui-btn-normal"} onClick={e => this.showInstallUser()}>
                                            <i className="layui-icon layui-icon-list"></i>
                                            查看
                                        </button>
                                    )
                                 }
                            },
                            hr:{name:'', block: true},
                        }
                    },
                    {
                        title:'安装信息',
                        show:model => {
                            return model.status !== 0;
                        },
                        fields:{
                            facadeImg:{name:'门面照', type:'slide', slide: 'slide'},
                            innerImg:{name:'室内照', type:'slide', slide: 'slide'},
                            deskImg:{name:'前台照', type:'slide', slide: 'slide'},
                            physicalImg:{name:'机身照', type:'slide', slide: 'slide'},
                            //staticImg:{name:'静态照', type:'slide', slide: 'slide'},
                            salesImg:{name:'签购单', type:'slide', slide: 'slide'},
							signImg:{name:'签名照', type:'slide', slide: 'slide'},
                            result:{name:'结果', value: model => { return this.server.result[model.result] }, block: true},
                            reason:{name:'备注原因', block: true},
							posDeviceNum:{name:'机身号', block: true}
                        }
                    },
                    {
                        title:'终端信息',
                        fields:{
                            terminal: {
                                name: '终端',
                                type: 'itemComponent',
                                component: this.terminal.bind(this),
                                block: true,
                            },
                            // 'pos':{
                            //     name:'POS机',
                            //     type:'itemComponent',
                            //     block: true,
                            //     component: () => {
                            //         return (
                            //             <PosListLayout
                            //                 autoload={false}
                            //                 list={this.state.pos}
                            //                 show={this.state.pos.length}
                            //                 paging={false}
                            //                 btnShow={false}
                            //                 oprationShow={false}
                            //                 layuiFluid={false}
                            //                 layuiCardBody={false}
                            //                 searchShow={false}
                            //                 hidden={['proddedAt']}
                            //                 inputName={"posId"}
                            //             />
                            //         )
                            //     }
                            // },
                            // keyborad:{
                            //     name:'键盘',
                            //     type:'itemComponent',
                            //     block: true,
                            //     component: () => {
                            //         return (
                            //             <KeybordListLayout
                            //                 autoload={false}
                            //                 list={this.state.keyboard}
                            //                 show={this.state.keyboard.length}
                            //                 paging={false}
                            //                 btnShow={false}
                            //                 oprationShow={false}
                            //                 layuiFluid={false}
                            //                 layuiCardBody={false}
                            //                 searchShow={false}
                            //                 hidden={['proddedAt']}
                            //                 inputName={"keyboardId"}
                            //             />
                            //         )
                            //     }
                            // },
                            // sim:{
                            //     name:'SIM卡',
                            //     type:'itemComponent',
                            //     block: true,
                            //     component: () => {
                            //         return (
                            //             <SimListLayout
                            //                 autoload={false}
                            //                 list={this.state.sim}
                            //                 show={this.state.sim.length}
                            //                 paging={false}
                            //                 btnShow={false}
                            //                 oprationShow={false}
                            //                 layuiFluid={false}
                            //                 layuiCardBody={false}
                            //                 searchShow={false}
                            //                 hidden={['proddedAt']}
                            //                 inputName={"simId"}
                            //             />
                            //         )
                            //     }
                            // },
                            // 'device.terminalCode':'终端号',
                            // 'device.receiveBankModel.name':'收单银行',
                            // 'device.bankModel.name':'开户银行',
                            // 'device.username':'联系人',
                            // 'device.phone':'联系电话',
                            // 'device.address':{name:'安装地址', block: true},
                            // 'device.lendRate':'费率(借)',
                            // 'device.loanRate':'费率(贷)',
                            // 'device.outRate':'费率(外)',
                            // 'device.originRate':'费率(本)',
                            // 'device.specialCoast':'特殊计费',
                            // 'device.level':'商户等级',
                            // 'device.expansion':'拓展人',
                            // 'device.expansionCoast':'拓展费用',
                            // 'device.platform':'所属平台',
                            // 'device.bankAgent':'银行经办人',
                            // 'device.expansionType':'拓展方式',
                            // 'device.channel':'渠道',
                            // 'device.transactionControl':'交易控制',
                            // 'device.receiveDate':{name:'收单日期', type:'date'},
                            // 'device.postcode':'邮政编码',
                            // 'device.serialNumber':'申请序号',
                            // 'device.risk':'风险等级',
                            // 'device.corporate':'法人代表',
                            // 'device.corporateIdcode':{name:'法人证件号'},
                            // 'device.corporateValidityStartDate':{name:'有效期开始'},
                            // 'device.corporateValidityEndDate':{name:'有效期结束'},
                            // 'device.corporatePhone':'法人手机',
                            // 'device.corporateTel':'法人固话',
                            // 'device.corporateFax':'法人传真',
                            // 'device.authorized':'被授权人',
                            // 'device.authorizedIdcode':{name:'被授权人身份证'},
                            // 'device.authorizedValidityStartDate':{name:'有效期开始'},
                            // 'device.authorizedValidityEndDate':{name:'有效期结束'},
                            // 'device.registerDate':{name:'登记日期'},
                            // 'device.startDate':{name:'启用日期'},
                            // 'device.aliCoastRate':{name:'支付宝费率'},
                            // 'device.wechatCoastRate':{name:'微信费率'},
                            // 'device.salesman':{name:'业务员'},
                            // 'device.salesmanNum':{name:'业务员工号'},
                            // 'device.externalChannels':{name:'外部渠道'},
                            // 'device.district':{name:'区域'},
                            // 'device.contractor':{name:'承包人'},
                        }
                    }
                ]}
            />
        );
    }

    terminal() {
        return (
            <UserDeviceListLayout
                autoload={false}
                show={this.state.terminal.length}
                list={this.state.terminal}
                paging={false}
                btnShow={false}
                searchShow={false}
                oprationFields={['detail']}
                layuiFluid={false}
                layuiCardBody={false}
                inputName={'terminalId'}
                visibles={['id', 'clientImage', 'client.code', 'client.name', 'terminalCode', 'client.bankAddress','deviceRemark']}
            />
        )
    }

    showClient() {
        this.layOpen({
            title:'查看商户 ' + this.state.model.client.name,
            content:'/admin/user/detail/'+this.state.model.uid,
            area:['900px', '96%'],
            btn:['确定'],
            yes: (index) => {
                layer.close(index);
            }
        })
    }

    deviceList(model) {
        return (
            <Table
                autoload={false}
                show={model.device.length}
                list={model.device}
                listTitleHeaderComponent={
                    <tr>
                        <th colSpan={4} style={{textAlign: 'center'}}>POS机</th>
                        <th colSpan={3} style={{textAlign: 'center'}}>键盘</th>
                        <th colSpan={3} style={{textAlign: 'center'}}>SIM卡</th>
                        <th style={{textAlign: 'center'}}>费用</th>
                    </tr>
                }
                fieldsInit={{
                    posModel: 'POS机型号',
                    posDeviceNum: 'POS机编号',
                    posMade: 'POS厂商',
                    deposit: '押金',
                    keyboardModel: '键盘型号',
                    keyboardDeviceNum: '键盘编号',
                    keyboardMade: '键盘厂商',
                    simModel: 'SIM类型',
                    simDeviceNum: 'SIM卡号',
                    simMade: '运营商',
                    money: ''
                }}
                oprationShow={false}
                layuiFluid={false}
                layuiCardBody={false}
                paging={false}
                btnShow={false}
                searchShow={false}

            />
        )
    }

    showInstallUser() {
        let installUser = this.state.model.installUser;
        this.layOpen({
            title:'查看安装人' + installUser.realname,
            content:'/admin/company/user/detail/'+ installUser.id,
            area:['800px', '90%'],
            btn:['确定'],
            yes: index => {
                layer.close(index);
            }
        })
    }

    showCheckUser() {
        let checkUser = this.state.model.checkUser;
        this.layOpen({
            title:'查看巡检人' + checkUser.realname,
            content:'/admin/company/user/detail/'+ checkUser.id,
            area:['800px', '90%'],
            btn:['确定'],
            yes: index => {
                layer.close(index);
            }
        })
    }
}
