import Config from './config';

class Validator
{
    config = new Config();

    regexEnum = {
        intege : "^-?[1-9]\\d*$", // 整数
        intege1 : "^[1-9]\\d*$", // 正整数
        intege2 : "^-[1-9]\\d*$", // 负整数
        num : "^([+-]?)\\d*\\.?\\d+$", // 数字
        num1 : "^([+]?)\\d*$", // 正数（正整数 + 0）
        num2 : "^-[1-9]\\d*|0$", // 负数（负整数 + 0）
        num3 : "^([+]?)\\d*\\.?\\d+$", // 正数
        decmal : "^([+-]?)\\d*\\.\\d+$", // 浮点数
        decmal1 : "^[1-9]\\d*.\\d*|0.\\d*[1-9]\\d*$", // 正浮点数
        decmal2 : "^-([1-9]\\d*.\\d*|0.\\d*[1-9]\\d*)$", // 负浮点数
        decmal3 : "^-?([1-9]\\d*.\\d*|0.\\d*[1-9]\\d*|0?.0+|0)$", // 浮点数
        decmal4 : "^[1-9]\\d*.\\d*|0.\\d*[1-9]\\d*|0?.0+|0$", // 非负浮点数（正浮点数 + 0）
        decmal5 : "^(-([1-9]\\d*.\\d*|0.\\d*[1-9]\\d*))|0?.0+|0$", // 非正浮点数（负浮点数 +// 0）
        email : "^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z0-9]+$", // 邮件
        color : "^[a-fA-F0-9]{6}$", // 颜色
        url : "^http[s]?:\\/\\/([\\w-]+\\.)+[\\w-]+([\\w-./?%&=]*)?$", // url
        chinese : "^[\\u4E00-\\u9FA5\\uF900-\\uFA2D]+$", // 仅中文
        ascii : "^[\\x00-\\xFF]+$", // 仅ACSII字符
        zipcode : "^\\d{6}$", // 邮编
        mobile : "^(13|14|15|17|18|19)[0-9]{9}$", // 手机
        ip4 : "^(25[0-5]|2[0-4]\\d|[0-1]\\d{2}|[1-9]?\\d)\\.(25[0-5]|2[0-4]\\d|[0-1]\\d{2}|[1-9]?\\d)\\.(25[0-5]|2[0-4]\\d|[0-1]\\d{2}|[1-9]?\\d)\\.(25[0-5]|2[0-4]\\d|[0-1]\\d{2}|[1-9]?\\d)$", // ip地址
        required : "^\\S+$", // 非空
        picture : "(.*)\\.(jpg|bmp|gif|ico|pcx|jpeg|tif|png|raw|tga)$", // 图片
        jpg : "(.*)\\.(jpg|gif)$", // 图片
        rar : "(.*)\\.(rar|zip|7zip|tgz)$", // 压缩文件
        // eslint-disable-next-line
        date : "^\\d{4}(\\-|\\/|\.)\\d{1,2}\\1\\d{1,2}$", // 日期
        qq : "^[1-9]*[1-9][0-9]*$", // QQ号码
        tel : "^(([0\\+]\\d{2,3}-)?(0\\d{2,3})-)?(\\d{7,8})(-(\\d{3,}))?$", // 电话号码的函数(包括验证国内区号,国际区号,分机号)
        username : "^\\w+$", // 用来用户注册。匹配由数字、26个英文字母或者下划线组成的字符串
        letter : "^[A-Za-z]+$", // 字母
        letter_u : "^[A-Z]+$", // 大写字母
        letter_l : "^[a-z]+$", // 小写字母
        letter_num : "^\\w+$", // 匹配由数字、26个英文字母或者下划线组成的字符串
        idcard : "/(^/d{15}$)|(^/d{17}([0-9]|X)$)/", // 身份证
        htmlcode : "^[^\\\\'\"<>@#$&]+$", // 禁止输入html代码（特殊字符）
        uploadFile : "(.*)\\.(jpg|bmp|gif|png|jpeg|tif|pdf|doc|docx|xls|xlsx|ppt|pptx)$", // 图片
    };

    ruleMsg = {
        required:'不能为空',
        mobile:'手机号码格式不正确'
    }

    errorMsgs = [];

    rules = [];

    data;

    extentions = {};

    init() {
        this.errorMsgs = [];
        this.rules = [];
        this.data = undefined;
    }

    //不能为空
    isRequired(value) {
        return (value !== null) &&
            (value !== undefined) &&
            (value.toString().replace(/\s+/g, "") !== "");
    }

    //如果参数为参数值不能为空
    isRequiredIf(value, params, field, data) {
        params = params.split(',');
        for(var k in params) {
            if (Number(k)) {
                // eslint-disable-next-line
                if (data[params[0]] == params[k]) {
                    return this.isRequired(value);
                }
            }
        }
        return true;
    }

    //如果参数不为空就不能为空
    isRequiredWith(value, params, field, data) {
        params = params.split(',');
        for(var k in params) {
            if (this.isRequired(data[params[k]]) && ! this.isRequired(value)) {
                return false;
            }
        }
        return true;
    }

    isRequiredWithout(value, params, field, data) {
        params = params.split(',');
        for(var k in params) {
            if (! this.isRequired(data[params[k]]) && ! this.isRequired(value)) {
                return false;
            }
        }
        return true;
    }

    //当参数为真时不能为空
    isRequiredWhen(value, params) {
        if (params === 'true') {
            return this.isRequired(value);
        }
        return true;
    }

    //是否是身份证号码
    isIdCard(value) {

    }

    //是否是手机号码
    isMobile(value, params, field) {
        if (value) {
            var reg = this.regexEnum.mobile;
            return value.match(reg) != null;
        }
        return true;
    }

    isEmail(value, params, field) {
        if (value) {
            var reg = this.regexEnum.email;
            return value.match(reg) != null;
        }
        return true;
    }

    //是否是参数长度
    isLength(value, params) {
        return value.length === Number(params);
    }

    //是否是最小位数
    isMin(value, params) {
        if (value) {
            return value.length >= Number(params);
        }
        return true;
    }

    //是否是最大位数
    isMax(value, params) {
        if (value) {
            return value.length <= Number(params);
        }
       return true;
    }

    //小于
    isLt(value, params) {
        if (value) {
            return Number(value) < Number(params);
        }
        return true;
    }

    //大于
    isGt(value, params) {
        if (value || typeof value === 'number') {
            return Number(value) > Number(params);
        }
       return true;
    }

    //是否是数字
    isNumeric(value) {
        if (value) {
            return typeof Number(value)  === 'number' && !isNaN(value);
        }
        return true;
    }

    //是否是日期时间
    isDateTime(value) {
        if (! this.isRequired(value)) {
            return true;
        }
        var reg = /^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2}) (\d{1,2}):(\d{1,2}):(\d{1,2})$/;
        var r = value.match(reg);
        // eslint-disable-next-line
        if (r == null)
            return false;
        var d = new Date(r[1], r[3] - 1, r[4], r[5], r[6], r[7]);
        // eslint-disable-next-line
        return (d.getFullYear() == r[1] && (d.getMonth() + 1) == r[3]
            // eslint-disable-next-line
            && d.getDate() == r[4] && d.getHours() == r[5]
            // eslint-disable-next-line
            && d.getMinutes() == r[6] && d.getSeconds() == r[7]);
    }

    isDate(value) {
        if (! this.isRequired(value)) {
            return true;
        }
        return isNaN(value) &&!isNaN(Date.parse(value));
    }

    isTime(value) {
        if (! this.isRequired(value)) {
            return true;
        }
        let data = value.split(':');
            if (! data[1]) {
                return false;
            }
            if (! data[2]) {
                data[2] = 0;
                value += ':00';
            }

        let match = value.match(/^(\d{1,2})(:)?(\d{1,2})\2(\d{1,2})$/);
        if (! match) {
            return false;
        }

        return !(match[1]>24 || match[3]>60 || match[4]>60);
    }

    //是否是数组
    isArray(value) {
        if (! this.isRequired(value)) {
            return true;
        }
        return ['object', 'array'].indexOf(typeof value) >= 0 || value instanceof Array;
    }

    //重复是否正确
    isConfirmed(value, params, field, data) {
        if (! this.isRequired(value)) {
            return true;
        }
        return data[field + 'Confirmation'] === value;
    }

    //是否包含在内
    isIn(value, params) {
        if (! this.isRequired(value)) {
            return true;
        }
        params = params.split(',');
        for(var k in params) {
            // eslint-disable-next-line
            if (params[k] == value) {
                return true;
            }
        }
        return false;
    }

    isEgt(value, params) {
        if (! this.isRequired(value)) {
            return true;
        }

        if (value) {
            return Number(value) >= Number(params);
        }
        return true;
    }

    isEq(value, params) {
        if (! this.isRequired(value)) {
            return true;
        }
        if (value) {
            // eslint-disable-next-line
            return value == params;
        }
        return true;
    }

    validate(rules, data) {
        var self = this;
        self.init(); //初始化
        this.rules = rules;
        this.data = data;

        for(var k in rules) {
            var rule = rules[k],
                field = rule[0],
                fieldArr = field.split('.'); //要验证的字段

            if (rule instanceof Array) {
                if (rule[1] && typeof rule[1] === 'string') {
                    var rarr = rule[1].split('|'); //分切规则
                    var errs = rule[2] && typeof rule[2] === 'string' ? rule[2].split('|') : []; //分切错误信息

                    //处理规则
                    for(var index in rarr) {
                        var nameAndParams = rarr[index].split(':'); //得到规则 名称
                        var name = nameAndParams[0];
                        var params = nameAndParams[1]; //得到规则参数
                        var func = 'is' + (name.charAt(0).toUpperCase() + name.slice(1)); //获取验证方法名
                        var value = self.getValue(fieldArr, data);
                        if (typeof  self[func] !== 'function') {
                            if (self.extentions.hasOwnProperty(name)) {
                                self.handleExtention(name, value, field, params, fieldArr, errs[index]);
                            } else {
                                self.errorMsgs.push(field + ' ' + func + ' is not exists!');
                                break;
                            }
                        } else if (name) {
                            self.handleFunc(func, value, params, fieldArr, errs[index], name, rule);
                        }
                    }
                } else if (typeof rule[1] === 'function') {
                    var result = rule[1](field, data);
                    if (! Boolean(result)) {
                       return result;
                    } else if (typeof result === 'string') {
                        self.errorMsgs.push(result);
                    }
                }
            }
        }
        return !Boolean(self.errorMsgs.length);
    }

    getValue(fieldArr, data) {
        var val = undefined,
            field = fieldArr[0],
            second = fieldArr[1],
            third = fieldArr[2],
            value = data[field],
            finals = [];

        try {
            if (third) {
                if (second === '*') {
                    // eslint-disable-next-line
                    for(var k in value) {
                        val = value[k] || [];
                        finals.push(val[third]);
                    }
                } else {
                    val = value[second] || [];
                    finals.push(val[third]);
                }
            } else if (second) {
                if (second === '*') {
                    // eslint-disable-next-line
                   for(var k in value) {
                       finals.push(value[k]);
                   }
                } else if (value) {
                    finals.push(value[second]);
                }
            } else {
                finals.push(value);
            }
            return finals.length > 1 ? finals : finals[0];
        }catch (e) {
            console.error(e)
        }
    }

    handleValue(func, value, params, field, err, name, rule) {
       //验证不通过(值, 参数, 字段名, 总参数, 总规则)
        if (! this[func](value, params, field, this.data, this.rules)) {
            //获得对应错误信息
            var er = err || this.getDefaultErrorMsg(field, name);//默认错误信息(字段名称, 规则名称)
            this.errorMsgs.push(er); //装载错误信息
            var callback = typeof rule[2] === 'function' ? rule[2] : rule[3]; //错误回调
            var passIn = [value, params, field, er, this.data, this.rules];
            if (this.config.debug) {
                console.log(passIn);
            }
            if (typeof callback === 'function') {
                callback(passIn);
            }
        }
    }

    handleFunc(func, value, params, field, errs, name, index , rule) {
        if (value instanceof Array) {
            for(var k in value) {
                this.handleValue(func, value[k], params, field, errs, name, index , rule);
            }
        } else {
            this.handleValue(func, value, params, field, errs, name, index , rule);
        }
    }

    handleExtention(attribute, value, field, params, fieldArr, err) {
        if (! this.extentions[attribute](attribute, value, field, params, this.data, fieldArr, err)) {
            this.errorMsgs.push(err);
        }
    }

    getDefaultErrorMsg(name, rule) {
        return name + ' ' + this.ruleMsg[rule];
    }

    extend(name, func) {
        this.extentions[name] = func;
        return this;
    }

    getError() {
        return this.errorMsgs[0];
    }

    getAllErrors() {
        return this.errorMsgs;
    }
}

export default Validator;
