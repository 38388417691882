import UserListLayout from "../layout/list";
import Base from "../../base";

export default class UserChooseList extends Base
{
    render() {
        return (
            <UserListLayout
                search={{
                    'information.company_id':this.app.getUrlParams('companyId'),
                    status:2
                }}
                searchFields={['keywords', 'company', 'dateMin']}
                searchShow={true}
                paging={true}
                layForm={true}
                canCheck={true}
                btnShow={false}
                oprationFields={['detail']}
            />
        );
    }
}


 

