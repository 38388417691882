import View from "../../../../vendor/framework/view";

class Error extends View
{
    boot() {
        this.state = {

        }
    }

    render() {
        return (
            <div className="layui-fluid">
                <div className="layadmin-tips">
                    <i className="layui-icon" style={{fontSize:'280px'}} face>&#xe664;</i>
                    <div className="" style={{fontSize: '20px',margin: '30px auto',paddingTop: '20px', borderTop: '5px solid #009688'}}>
                        您好像没有权限访问
                    </div>
                </div>
            </div>
        );
    }
}
export default Error;
