import Base from "../../../base";
import Table from "../../../layout/table";
import VersionPackageService from "../../../../services/running/versionPackage";

export default class VersionPackageList extends Base
{
    componentDidMount() {
        /* global layer */

    }

    render() {
        return (
            <Table
                service={VersionPackageService}
                fieldsInit={{
                    id:'ID',
                    version:'版本号',
                    fileName:'文件名',
                    size:'大小',
                    desc:'描述',
                    statusDup:'状态',
                    createdAt:'添加时间'
                }}
                oprationInit={{
                    edit:{name:'编辑', icon: ['edit'], click: this.edit.bind(this)},
                    delete:{name: '删除', icon:['delete', 'danger'], click:this.delete.bind(this)}
                }}
                btnInit={{
                    add:{name:'添加', click: this.add.bind(this)}
                }}
            />
        );
    }

    add(table) {
        this.layOpen({
            title:'添加',
            content:'/admin/running/version-package/add',
            form:true,
            area:['1000px', '90%'],
            yes: (field, index) => {
                table.server.save(field, () => {
                    layer.msg('添加成功!');
                    layer.close(index);
                    table.loadData();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    edit(model, table) {
        this.layOpen({
            title:'修改',
            content:'/admin/running/version-package/edit/'+model.id,
            form:true,
            area:['1000px', '90%'],
            yes: (field, index) => {
                field.id = model.id;
                table.server.save(field, () => {
                    layer.msg('修改成功!');
                    layer.close(index);
                    table.loadData();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    delete(model, table) {
        layer.confirm(`您真的要删除吗?`, () => {
            table.server.delete(model, () => {
                layer.msg('删除成功!');
                table.pageReload();
            }, (msg) => {
                layer.msg(msg);
            })
        })
    }
}
