import UserListLayout from "../layout/list";
import Base from "../../base";

class UserList extends Base
{
	boot() {
		let searchHidden = [],
			companyId = this.app.getUrlParams('companyId');
		if (companyId) {
			searchHidden.push('company');
		}
		this.state = {
			companyId:companyId,
			searchHidden:searchHidden
		}

	}

	render() {
		return (
			<UserListLayout
				search={{
					'information.company_id': this.state.companyId
				}}
				searchShow={true}
				searchHidden={this.state.searchHidden}
				paging={true}
				layForm={true}
				canCheck={true}
				checkMany={true}
				hidden={this.state.companyId ? ['companyName'] : []}
				 />
		);
	}
}

export default UserList;
