import Table from "../../layout/table";
import CompanyService from "../../../services/company";
import View from "../../../../../vendor/framework/view";

export default class CompanyListLayout extends View
{
    render() {
        return (
            <Table
                props={this.props}
                service={CompanyService}
                searchInit={{
                    name:{name:'公司名称', enter:true},
                }}
                props={this.props}
                fieldsInit={{
                    id:{name:'ID', checkbox: true},
                    name:'公司名称',
                    phone:'手机号码',
                    createdAt:'添加时间'
                }}
                btnInit={{
                    add:{name:'添加', click:this.add.bind(this)},
                }}
                oprationInit={{
                    clientList:{name:'商户列表', icon:['list', 'normal'], click: this.clientList.bind(this)},
                    edit:{name:'修改', icon:['edit', 'normal'], click:this.edit.bind(this)},
                    delete:{name:'删除', icon:['delete', 'danger'], click:this.delete.bind(this)}
                }}
            />
        );
    }

    componentDidMount() {
        /* global layui,layer */
        layui.use(['form'], () => {

        })
    }

    add(table) {
        this.layOpen({
            title:'添加集团',
            content:'/admin/company/add',
            form:true,
            area:['500px', '600px'],
            yes: (field, index) => {
                table.server.save(field, () => {
                    layer.msg('添加成功!');
                    layer.close(index);
                    table.loadData();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    edit(val, table) {
        this.layOpen({
            title:'修改集团 '+val.name,
            content:'/admin/company/edit/'+val.id,
            form:true,
            area:['500px', '600px'],
            yes: (field, index) => {
                field.id = val.id;
                table.server.save(field, () => {
                    layer.msg('修改成功!');
                    layer.close(index);
                    table.pageReload();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    delete(model, table) {
        layer.confirm('您真的要删除 ' + model.name + ' 吗?', () => {
            table.server.delete(model.id, () => {
                layer.msg('删除成功!');
                table.pageReload();
            }, (msg) => {
                layer.msg(msg);
            })
        })
    }

    clientList(model) {
        this.layOpen({
            title:'商户列表',
            content:'/admin/user/list?companyId='+model.id,
            btn:['确定'],
            yes: (index) => {
                layer.close(index);
            }
        })
    }
}
