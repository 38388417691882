import BaseService from "../base";

export default class ConsoleService extends BaseService
{
    requestUrls = {
        console:'/console',
        device:'/console/device'
    }

    getData(suc, respose) {
        this.post(
            this.requestUrls.console,
            {},
            suc,
            null,
            respose
        )
    }

    getDevice(suc, response) {
        this.post(
            this.requestUrls.device,
            {},
            suc,
            null,
            response
        )
    }
}
