import Auth from "../services/auth";
import { Redirect } from 'react-router'
import Middleware from "../../../vendor/framework/middleware";

class AuthMiddleware extends Middleware
{
    auth = new Auth();

    handle($next) {
         if (! this.auth.check()) {
             return (
                 <Redirect to={"/admin/login"} />
             )
         }
        return $next;
    }
}

export default AuthMiddleware;
