import Table from "../../../layout/table";
import OrderPatrolService from "../../../../services/order/patrol";
import View from "../../../../../../vendor/framework/view";
import Excel from "../../../../components/excel";
import $ from "jquery";
import File from "../../../../../../vendor/framework/file";

export default class OrderPatrolListLayout extends View
{
    boot() {
        this.state = {
            installId: this.app.getUrlParams('installId') || ''
        }
    }

    service() {
        return OrderPatrolService;
    }

    render() {
        return (
            <Table
                service={OrderPatrolService}
                stateInit={{
                    orderBy:['id', 'desc']
                }}
                search={{
                    install_id: this.state.installId
                }}
                searchInit={{
                    keywords: {name: '关键字', placeholder:'商户编号/工单编号/商户名称/终端号/巡检人', enter: true},
                    status:{name:'状态', type:'select', data: this.server.status, default:''},
                    dateMin: {name: '添加时间', type: 'date', position: 0, format: 'datetime', placeholder: '开始时间'},
                    dateMax: {type: 'date', position: 1, format: 'datetime', placeholder: '结束时间'},
					dateMin1:{name: '完成时间', type: 'date', position: 0, format: 'datetime', placeholder: '开始时间',field:'served_at',key:"dateMin"},
					dateMax1:{type: 'date', position: 1, format: 'datetime',  placeholder: '结束时间',field:'served_at',key:"dateMax"},					


                }}
                fieldsInit={{
                    id:{name:'ID', sort:{asc:'asc', desc:'desc'}, checkbox: true},
                    orderSn:'工单编号',
                    company:{name:'所属公司', value(model) { return model.client && model.client.company ? model.client.company.name : ''}},
                    clientName:{name:'商户名称', value(model) { return model.client ? model.client.name : ''; }},
                    clientCode:{name:'商户编号', value: model => model.client ? model.client.code : ''},
                    terminalCode:{name:'终端号'},
                    principalUser:{name:'巡检人', value(model) { return model.checkUser ? model.checkUser.realname : ''; }},
                    //visitedAt:'上门时间',
                    servedAt:'完成时间',
                    statusDup:'状态',
                    createdAt:'添加时间'
                }}
                oprationInit={{
                   detail:{name:'详情', icon:['list', 'normal'], click: this.detail.bind(this)},
                   edit: {name: '修改', icon: ['edit', 'normal'], click: this.edit.bind(this)},
                   audit:{name:'审核', icon:['', 'warm', 'iconfont icon-shenhe'], disabled: model => { return model.status !== 1; }, click:this.audit.bind(this)},
                   delete:{name:'删除', icon:['delete', 'danger'], click:this.delete.bind(this)},
                }}
                btnInit={{
                    add: {name: '添加', click: this.add.bind(this)},
                    appoint: {name: '指派', click: this.appoint.bind(this)},
                    manyAppointLayout:{name:'批量指派模板下载', click: this.manyAppointLayout.bind(this)},
                    manyAppointImport:{name:'批量指派', click: this.manyAppointImport.bind(this)},
                    excelLayout: {name: 'EXCEL模板下载', className: 'layui-btn-warm', click: this.excelLayout.bind(this)},
                    import:{name:'导入', className:'layui-btn-normal', click: this.import.bind(this)},
                    export:{name:'导出', click: this.export.bind(this)},
                    download:{name:'下载', click: this.download.bind(this)},
                }}
                canCheck={true}
                checkMany={true}
            />
        );
    }

    componentDidMount() {
        /* global layui,layer */
        layui.use(['form'], () => {

        })
    }

    add(table) {
        this.layOpen({
            title: '添加巡检工单',
            content: '/admin/order/patrol/add',
            form: true,
            area: ['90%', '98%'],
            yes: (field, index) => {
                table.server.save(field, () => {
                    layer.msg('添加成功!');
                    layer.close(index);
                    table.loadData();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    edit(val, table) {
        this.layOpen({
            title: '修改巡检工单 ' + val.orderSn,
            content: '/admin/order/patrol/edit/' + val.id,
            form: true,
            area: ['90%', '98%'],
            yes: (field, index) => {
                field.id = val.id;
                table.server.save(field, () => {
                    layer.msg('修改成功!');
                    layer.close(index);
                    table.pageReload();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    audit(model, table) {
        layer.confirm('审核结果', {icon:3, title:'审核', btn:['通过', '拒绝', '取消']}, () => {
            audit(3);
        }, () => {
            layer.prompt({
                formType:2,
                title:'原因',
            }, (val, index) => {
                audit(2, val);
                layer.close(index)
            })
        })

        function audit(status, reason) {
            table.server.audit({id:model.id, status:status, reason:reason}, () => {
                layer.msg('保存成功!');
                table.pageReload();
            }, message => {
                layer.msg(message);
            })
        }
    }

    detail(model) {
        this.layOpen({
            title:' 巡检详情',
            content:'/admin/order/patrol/detail/'+model.id,
            area:['90%', '90%'],
            btn:['确定'],
            yes: (index) => {
                layer.close(index);
            }
        })
    }

    delete(model, table) {
        layer.confirm('您真的要删除工单号为 ' + model.orderSn + ' 吗?', () => {
            table.server.delete(model.id, () => {
                layer.msg('删除成功!');
                table.pageReload();
            }, (msg) => {
                layer.msg(msg);
            })
        })
    }

    excelLayout() {
        let keys = Object.keys(this.server.excelFields),
            arr = [],
            data = [
                {
                    id:'1',
                    orderSn:'I2022010211582210',
                    company:'广州裕富联分公司',
                    clientName:'白云学院',
                    clientCode:'123456',
                    username:'小陈',
                    phone:'13000000000',
                    address:'天河',
                    terminalCode:'21112112121',
                    checkUser:'陈陈',
                    result:'服务完成',
                    servedAt:'2022-01-10 10:10:00'
                }
            ];
        arr[0] = this.server.getExcelTitles();
        data.forEach((item, index) => {
            let k = index + 1;
            arr[k] = [];
            for(let j in keys) {
                let field = keys[j];
                if (item.hasOwnProperty(field)) {
                    arr[k].push(item[field]);
                }
            }
        })

        let excel = new Excel();
        excel.export('巡检导入模板', arr, (workbook, table) => {
            workbook.sheet(0).row(1).height(50);
            workbook.sheets().forEach((sheet) => {
                // 表头加粗及背景色
                sheet.range('A1:L1').style({
                    wrapText: true,
                    fill: "00bdf0",
                    bold: true,
                    horizontalAlignment: "center",
                    verticalAlignment: "center",
                    fontSize: 12,
                    border: {
                        style: 'thin',
                        color: "000000",
                        direction: "both"
                    }
                });

                for (let k in table[0]) {
                    sheet.column(k).width(25);
                }
            })
        });
    }

    import(table) {
        this.layOpen({
            title: '导入',
            content: '/admin/order/patrol/import',
            area: ['90%', '98%'],
            form: true,
            yes: (data, index) => {
                data = this.app.htmlArrayToJson(data);
                table.server.import(data, () => {
                    layer.close(index);
                    table.loadData();
                    layer.msg('导入成功!');
                }, (msg) => {
                    layer.alert(msg);
                })
            }
        })
    }

    appoint(table) {
        let ids = [];

        $('input[type=checkbox]').each(function () {
            if ($(this).prop('checked')) {
                let value = $(this).val();
                if (value.indexOf('{') > -1 && value.indexOf('}') > -1) {
                    let val = JSON.parse(value);
                    ids.push(val.id);
                }
            }
        })
        if (!ids.length) {
            return layer.msg('请选择要指派的工单!');
        }

        this.layOpen({
            title: '选择巡检员工',
            content: '/admin/company/user/choose/list',
            form: true,
            yes: (form, index) => {
                form = this.app.htmlArrayToJson(form);
                let data = form.data;
                if (!data || !data.length) {
                    return layer.msg('请选择员工!');
                }
                let user = JSON.parse(data[0]);
                let model = {
                    ids: ids,
                    uid: user.id
                }
                table.server.setAppointUser(model, () => {
                    layer.msg('已指派!');
                    layer.close(index);
                    table.pageReload();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    manyAppointLayout() {
        let keys = ['id', 'orderSn', 'checkUser'],
            arr = [],
            data = [
                {
                    'id':'1',
                    'orderSn':' 2022010211582210 ',
                    'checkUser':'志星',
                }
            ];
        arr[0] = ['序号', '巡检工单编号', '巡检员工'];
        data.forEach((item, index) => {
            let k = index + 1;
            arr[k] = [];
            for(let j in keys) {
                let field = keys[j];
                if (item.hasOwnProperty(field)) {
                    arr[k].push(item[field]);
                }
            }
        })

        let excel = new Excel();
        excel.export('批量指派模板', arr, (workbook, table) => {
            workbook.sheet(0).row(1).height(50);
            workbook.sheets().forEach((sheet) => {
                // 表头加粗及背景色
                sheet.range('A1:C1').style({
                    wrapText: true,
                    fill: "00bdf0",
                    bold: true,
                    horizontalAlignment: "center",
                    verticalAlignment: "center",
                    fontSize: 12,
                    border: {
                        style: 'thin',
                        color: "000000",
                        direction: "both"
                    }
                });

                for (let k in table[0]) {
                    sheet.column(k).width(25);
                }
            })
        });
    }

    manyAppointImport(table) {
        this.layOpen({
            title: '导入',
            content: '/admin/order/patrol/import/appoint',
            area: ['90%', '98%'],
            form: true,
            yes: (data, index) => {
                data = this.app.htmlArrayToJson(data);
                table.server.manyAppoint(data, () => {
                    layer.close(index);
                    table.loadData();
                    layer.msg('导入成功!');
                }, (msg) => {
                    layer.alert(msg);
                })
            }
        })
    }

    export(table, type = 0) {
        let ids = this.getCheckIds();
        if (! ids.length) {
            return layer.msg('请选择数据!');
        }
        this.server.export({ids:ids, type: type}, res => {
            if (type) {
                let url = (new File()).make(res.path);
                window.open(url);
                return;
            }
            let list = [];
            res.forEach(item => {
                if (ids.indexOf(item.id) > -1) {
                    list.push(item);
                }
            })
            let titles = this.server.getExcelTitles();
            titles.push("结果");
            titles.push("理由");
			titles.push("机身号");
            let keys = Object.keys(this.server.excelFields),
                data = [titles];
            keys.push("result1");
            keys.push("reason");
			keys.push("posDeviceNum");
            list.forEach(item => {
                let arr = [],
                    client = item.client || {},
                    company = client.company  || {},
                    checkUser = item.checkUser || {},
                    row = {
                        id:item.id,
                        orderSn:item.orderSn,
                        company:company.name || '',
                        clientName:client.name || '',
                        clientCode:client.code || '',
                        username:item.username || '',
                        phone:item.phone || '',
                        address:item.address || '',
                        terminalCode:item.terminalCode,
                        checkUser:checkUser.realname || '',
                        result:item.statusDup,
                        servedAt:item.servedAt,
                        result1:item.resultDup,
                        reason:item.reason,
						reason:item.posDeviceNum,
						
                    }
                keys.forEach(field => {
                    arr.push(row[field] || '');
                })
                data.push(arr);
            })

            let excel = new Excel();
            excel.export('巡检工单列表', data, (workbook, table) => {
                workbook.sheet(0).row(1).height(50);
                workbook.sheets().forEach((sheet) => {
                    // 表头加粗及背景色
                    sheet.range('A1:O1').style({
                        wrapText: true,
                        fill: "00bdf0",
                        bold: true,
                        horizontalAlignment: "center",
                        verticalAlignment: "center",
                        fontSize: 12,
                        border: {
                            style: 'thin',
                            color: "000000",
                            direction: "both"
                        }
                    });

                    for (let k in table[0]) {
                        sheet.column(k).width(25);
                    }
                })
            })
        })
    }

    download() {
        this.export(null, 1);
    }
}
