import Base from "../../../base";
import VersionPackageService from "../../../../services/running/versionPackage";
import Form from "../../../layout/form";

export default class VersionPackageAdd extends Base
{
    boot() {
        this.state = {
            model:{
                type:0,
                link:'',
                version:'',
                fileName:'',
                size:'',
                desc:'',
                status:1
            }
        }
    }

    service() {
        return VersionPackageService;
    }

    render() {
        return (
            <Form
                model={this.state.model}
                fields={{
                    type:{name:'端口', type:'select', data: this.server.type},
                    link:{name:'文件', type:'file', tip:'上传APP安装包', exts:['apk'], choosed:(form, file) => {
                        form.setModel({
                            fileName: file[1][0] + '.' + file[2][0],
                            size:(file[3][0] / 1024 ).toFixed(2) + 'KB'
                        })
                     }},
                    version:{name:'版本号', type:'text', tip:'如:1.0.0'},
                    fileName:'文件名',
                    size:'文件大小',
                    desc:{name:'描述', type:'textarea'},
                    status:{name:'状态', type:'radio', data: this.server.status}
                }}
            />
        );
    }
}
