import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";

class RoleService extends BaseService
{
    requestUrls = {
        url:'/system/role/list',
        addOrEdit:'/system/role/save',
        detail:'/system/role/detail',
        delete: '/system/role/delete'
    }

    states = ['停用', '正常'];

    appends = ['stateDup', 'descDup'];

    save(model, suc, err) {
        let validator = new Validator(),
            rules = [
                ['name', 'required', '请输入角色名称'],
                ['ruleIds', 'required', '请选择权限']
            ];

        model = this.app.htmlArrayToJson(model);
        if (! validator.validate(rules, model)) {
            return err(validator.getError());
        }
        this.post(this.requestUrls.addOrEdit,
                model,
                suc,
                err
            )
    }

    getDetail(id, suc) {
        this.post(
            this.requestUrls.detail,
            {id:id},
            suc
        );
    }

    delete(id, suc, err) {
        this.post(
            this.requestUrls.delete,
            {id:id},
            suc,
            err
        )
    }

    getStateDupAttribute() {
        return this.states[Number(this.attributes['status'])];
    }

    getDescDupAttribute(val, model) {
        return model.desc;
    }
}
export default RoleService;
