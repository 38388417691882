import Base from "../../../base";
import RoleService from "../../../../services/admin/role";
import RuleService from "../../../../services/admin/rule";
import style from "../../rule/list/index.module.css";
import {Fragment} from "react";
import $ from "jquery";

class RoleEdit extends Base
{
    boot() {
        this.state = {
            model:{
                name:'',
                ruleIds:[],
                status:1
            },
            data:[]
        }
    }

    service() {
        return RoleService;
    }

    componentDidMount() {
        /* global layui */
        layui.use(['form'], () => {
            let form = layui.form;
            this.server.getDetail(this.getPropsParams('id'), (model) => {
                this.server.getService(RuleService).getAll((data) => {
                    this.setState({
                        data:data,
                        model:model
                    }, () => {
                        form.render();
                    })
                })
            })
        })
    }

    render() {
        return (
            <div className="layui-form" style={{padding: '20px 30px 0 0'}}>
                <div className="layui-form-item">
                    <label className="layui-form-label">角色</label>
                    <div className="layui-input-inline">
                        <input type="text" name="name" placeholder="角色名称" onChange={e => this.onChangeModel(e)} value={this.state.model.name} className="layui-input" />
                    </div>
                </div>
                <div className="layui-form-item">
                    <label className="layui-form-label">权限范围</label>
                    <div className="layui-input-block">
                        <ul>
                            {
                                this.state.data.map((rule, index) => {
                                    return (
                                        <li key={index} style={{width: '100%',height: 'auto', display: 'block', marginBottom: '20px'}}>
                                            <input type="checkbox" checked={this.state.model.ruleIds.indexOf(rule['id']) > -1} name="ruleIds[]" title={rule['name']}
                                                   value={rule['id']} lay-filter="rules" />
                                            {
                                                rule['children'] && rule['children'].length ?
                                                    (
                                                        <div style={{marginLeft: '20px'}}>
                                                            {
                                                                rule['children'].map((son, ind) => {
                                                                    return (
                                                                        <div style={{marginLeft:'28px'}}>
                                                                            <input type="checkbox" checked={this.state.model.ruleIds.indexOf(son['id']) > -1} name="ruleIds[]" title={son['name']}
                                                                                   className="son" value={son['id']} lay-filter="rules" />
                                                                            {
                                                                                son['children'] ?
                                                                                    (
                                                                                        <div style={{display: 'inline'}}>
                                                                                            {
                                                                                                son['children'].map((v, i) => {
                                                                                                    return (
                                                                                                        <Fragment>
                                                                                                            <input type="checkbox" name="ruleIds[]"
                                                                                                                   checked={this.state.model.ruleIds.indexOf(v['id']) > -1}
                                                                                                                   title={v['name']} className="son"
                                                                                                                   value={v['id']} />
                                                                                                        </Fragment>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                    :''
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                    :''
                                            }
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className="layui-form-item">
                    <label className="layui-form-label">状态</label>
                    <div className="layui-input-inline">
                        <select name="status" value={Number(this.state.model.status)}>
                            {
                                this.server.states.map((val, index) => {
                                    return (
                                        <option key={index} value={index}>{val}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="layui-form-item">
                    <label className="layui-form-label">具体描述</label>
                    <div className="layui-input-block">
                        <textarea type="text" name="desc" autoComplete="off" onChange={e => this.onChangeModel(e)} className="layui-textarea" value={this.state.model.desc}></textarea>
                    </div>
                </div>
                <div className="layui-form-item layui-hide">
                    <button className="layui-btn" lay-submit={""} lay-filter="LAY-user-front-submit"
                            id="LAY-user-front-submit">提交
                    </button>
                </div>
            </div>
        );
    }

    mouseOver(e) {
        $(e.target).find('.'+style.tip).show();
    }

    mouseLeave(e) {
        $(e.target).find('.'+style.tip).hide();
    }
}

export default RoleEdit;
