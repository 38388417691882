import Base from "../../../base";
import UserDeviceListLayout from "../layout";

export default class UserDeviceChooseList extends Base
{
    boot() {
        this.state = {
            uid:this.app.getUrlParams('uid'),
            status:this.app.getUrlParams('status')
        }
    }

    render() {
        return (
            <UserDeviceListLayout
                canCheck={true}
                btnShow={false}
                oprationFields={['detail']}
                search={{
                    uid:this.state.uid,
                    status:this.state.status
                }}
            />
        );
    }
}
