import Base from "../../../base";
import UserTypeListLayout from "../layout";

export default class UserTypeChooseList extends Base
{
    render() {
        return (
            <UserTypeListLayout
                canCheck={true}
                oprationShow={false}
                visibles={['id', 'name', 'createdAt']}
                btnShow={false}
                searchFields={['name']}
                search={{
                    status:1
                }}
            />
        );
    }
}
