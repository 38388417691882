import ListLayout from "./list";
import {Fragment} from "react";
import $ from 'jquery';
import TableSort from "../../components/tablesort";
import style from './list.module.css'
import File from "../../../../vendor/framework/file";

export default class Table extends ListLayout {

    checkboxFilter = 'ids';

    file = new File();

    stateInit() {
        let state = this.props.stateInit || {},
            search = this.props.searchInit || {};
        state.search = state.search || {};
        for (var k in search) {
            state.search[k] = search[k].value;
        }
        return state;
    }

    fieldsInit() {
        let fields = this.props.fieldsInit || {},
            fieldContainer = {};

        for (let k in fields) {
            let item = fields[k],
                type = item.type || 'text',
                func = this['field' + type.charAt(0).toUpperCase() + type.slice(1)].bind(this),
                itemType = typeof item,
                name = itemType === 'object' ? (item.key || k) : k;
            fieldContainer[k] = itemType === 'function' ? item : (model = {}, modelKey, modelOrigin) => {
                return itemType === 'string' ? func(model, k, {name:item}, k, modelKey, modelOrigin) : func(model, name, item, k, modelKey, modelOrigin);
            }
        }
        return fieldContainer;
    }

    getFieldsTitle(name, item) {
        let title = item.hasOwnProperty('sort') ? (<TableSort name={item.name} show={this.sortShow.can} asc={item.sort.asc} desc={item.sort.desc} sort={(sort) => {
            item.sort.click ? item.sort.click(sort, this) : this.setOrderBy(name, item, sort);
        }} />) : item.name;
        return <th style={item.headerStyle || (this.props.headerStyle || {})}>{title}</th>;
    }

    setOrderBy(name, item, sort) {
        this.setState({
            orderBy:[item.sort.key || name, sort]
        }, () => {
            this.loadData();
        })
    }

    fieldText(model, name, item, i, modelKey, modelOrigin) {
        let title = this.getFieldsTitle(name, item);
        if (item.checkbox) {
            var inputName = this.list.inputName;
            return {
                title: this.list.checkMany ? (<span><input type="checkbox" lay-skin="primary"
                                                           lay-filter="checkAll"/>{name}</span>) : name,
                value: <td key={i} style={item.style || (this.props.bodyStyle || {})} title={typeof item.title === 'function' ? item.title(model) : (item.title || '')}>{this.list.canCheck ?
                    <input type="checkbox" name={inputName} lay-skin="primary" lay-filter={item.filter || this.checkboxFilter}
                           className={item.filter || this.checkboxFilter}
                           value={JSON.stringify(model)}/> :
                    <input type="hidden" name={inputName} value={model[name]}/>}{model[name]}</td>
            }
        }
        let titleTip = typeof item.title === 'function' && JSON.stringify(model) !== '{}' ? item.title(model) : (item.title || ''),
            value;

        if (name.indexOf('.') > -1) {
            let keys = name.split('.');
                value = model[keys[0]];
            for(let k in keys) {
                if (Number(k) > 0) {
                    if (value && typeof value === 'object') {
                        value = value[keys[k]];
                    }
                }
            }
        } else {
            value = model[name];
        }

        return {
            title: title,
            value: item.value ? (<td style={item.style || (this.props.bodyStyle || {})} title={titleTip}>{typeof item.value === 'function' ? (model && JSON.stringify(model) !== '{}' ? item.value(model, i, modelKey, modelOrigin) : '') : item.value}</td>) : <td style={item.style || (this.props.bodyStyle || {})} title={titleTip}>{value}</td>
        }
    }

    fieldInput(model, name, item, i) {
        return {
            title: this.getFieldsTitle(name, item),
            value: (
                <td key={i} style={{width: '100px'}}>
                    <input type="number" value={model[name]}
                           onBlur={e => this.changeInput(name, model, item)}
                           onChange={e => this.onChangeInput(e, name, model)}
                           className="layui-input"/>
                </td>
            )
        }
    }

    fieldSwitch(model, name, item, i) {
        return {
            title: this.getFieldsTitle(name, item),
            value: (
                <td key={i} width="6%" style={item.style || {}}>
                    <input type="checkbox" value={model[name]} readOnly={true} checked={item.checked === model[name]}
                           data-id={model.id} lay-filter={'field' + name} lay-skin="switch" lay-text={item.text}/>
                </td>
            )
        }
    }

    fieldComponent(model, name, item, i) {
        return {
            title:this.getFieldsTitle(name, item),
            value:(
                <td key={i}>{typeof item.component === 'function' ? item.component(model) : item.component}</td>
            )
        }
    }

    fieldImage(model, name, item, i) {
        let value = item.value !== undefined ? (typeof item.value === 'function' ? item.value(model) : item.value) : model[name];
        return {
            title:this.getFieldsTitle(name, item),
            value:(
                <td key={i}>{value ? <div className={"tsimgbox"}><img  alt={""} className={"psimg"} src={this.file.make(value)} /></div> : item.default || ''}</td>
            )
        }
    }

    searchInit() {
        let search = this.props.searchInit || {},
            searchContainer = {};
        for (let k in search) {
            let item = search[k];
            if (typeof item === 'string') {
                searchContainer[k] = () => {
                    return this.searchText(item.key || k, item);
                }
            } else {
                let type = search[k].type || 'text',
                    func = this['search' + type.charAt(0).toUpperCase() + type.slice(1)].bind(this);
                searchContainer[k] = () => {
                    return func(item.key || k, search[k], search);
                }
            }
        }
        return searchContainer;
    }

    searchText(name, item) {
        let title = typeof item === 'string' ? item : item.name;
        return (
            <Fragment>
                <div className="layui-inline" style={{width: 400 + 'px'}} >
                    <label className={"layui-form-label " +(item.layTip ? 'layTip' : '')} title={title}>{title}</label>
                    <div className="layui-input-block">
                        <input type="text"onKeyDown={e => this.onEnter(e, () => {
                            this.loadData()
                        })} name={name} onChange={e => this.onChangeSearch(e)} placeholder={item.placeholder || ("输入" + title)}
                               autoComplete="off" value={this.state.search[name]} className="layui-input"/>
                    </div>
                </div>
                {
                    item.enter ?
                        (
                            <div className="layui-inline">
                                <button className="layui-btn layuiadmin-btn-useradmin" onClick={e => this.loadData()}>
                                    <i className="layui-icon layui-icon-search layuiadmin-button-btn"></i>
                                </button>
                            </div>
                        )
                        : ''
                }
            </Fragment>
        )
    }

    searchSelect(name, item) {
        let def = item.default,
            value = this.state.search[name],
            option = '';
        name = item.key || name;
        if (item.hasOwnProperty('default')) {
            if (typeof def !== 'object') {
                value = def;
                option = (
                    <option value={def}>--全部--</option>
                )
            } else {
                if (item.option) {
                    for(let k of item.data) {
                        if (k[option[0]] === def.value) {
                            option = (
                                <option value={def.value}>{def.name}</option>
                            )
                        }
                    }
                }
            }
        }

        return (
            <div className="layui-inline">
                <label className={"layui-form-label "+style['layui-form-label'] + ' '+(item.layTip ? 'layTip' : '')} title={item.name}>{item.name}</label>
                <div className="layui-input-inline">
                    <select name={name} lay-filter={"search" + name} defaultValue={value} value={this.state.search[name]}>
                        {
                            option
                        }
                        {
                            item.data instanceof Array ? this.searchSelectFromArray(item) : this.searchSelectFromObject(item)
                        }
                    </select>
                </div>
            </div>
        )
    }

    searchSelectFromArray(item) {
        return item.data.map((val, index) => {
            return (
                <option key={index}
                        value={item.option ? val[item.option[0]] : index}>{item.option ? val[item.option[1]] : val}</option>
            )
        })
    }

    searchSelectFromObject(item) {
        return Object.keys(item.data || {}).map((key, index) => {
            return (
                <option key={index} value={key}>{item.data[key]}</option>
            )
        })
    }

    searchDate(name, item, search) {
        let num = item.position,
            end,
            field = item.field || 'created_at',
            key = item.key || name;

        for(let k in search) {
            let prev = search[k];
            if (typeof prev === 'object' && prev.type === 'date' && (prev.position === num - 1)) {
                return;
            }
            if (typeof prev === 'object' && prev.type === 'date' && (prev.position === num + 1)) {
                end = this.searchEndDate(k, prev, field);
            }
        }

        if (! this.state.search[key]) {
            this.state.search[key] = {
                [field]:''
            }
        } else if (! this.state.search[key][field]) {
            this.state.search[key][field] = '';
        }

        let index = Object.keys(this.state.search[key]).indexOf(field);
        return (
            <div className="layui-inline">
                <label className={"layui-form-label "+(item.layTip ? 'layTip' : '')} title={item.name}>{item.name}</label>
                <div className="layui-input-inline">
                    <input type="text" name={key + (index > 0 ? index : '')} placeholder={item.placeholder || '开始日期'}
                           id={key + (index > 0 ? index : '')} autoComplete="off" className="layui-input"
                           value={this.state.search[key][field]}/>
                </div>
                {end}
            </div>
        )
    }

    searchEndDate(name, item, field) {
        let key = item.key || name;
        if (! this.state.search[key]) {
            this.state.search[key] = {
                [field]:''
            }
        } else if (! this.state.search[key][field]) {
            this.state.search[key][field] = '';
        }
        let index = Object.keys(this.state.search[key]).indexOf(field);
        return (
            <Fragment>
                <div className="layui-form-mid layui-word-aux">-</div>
                <div className="layui-input-inline">
                    <input type="text" name={key + (index > 0 ? index : '')} placeholder={item.placeholder || "结束日期"} id={key + (index > 0 ? index : '')}
                           autoComplete="off" className="layui-input" value={this.state.search[key][field]}/>
                </div>
            </Fragment>
        )
    }

    searchExtentions() {
        let searchGroups = this.props.searchGroups;
        if (searchGroups) {
            let searchContainer = {};
            for(let k in searchGroups) {
                let search = searchGroups[k];
                searchContainer[k] = () => {
                    return (
                        <div className={"layui-form-item"}>
                            {
                                Object.keys(search).map((key, i) => {
                                    let item = search[key];
                                    let type = item.type || 'text',
                                        func = this['search' + type.charAt(0).toUpperCase() + type.slice(1)].bind(this);
                                    return func(key, item, search);
                                })
                            }
                        </div>
                    );
                }
            }
            return searchContainer;
        }
    }

    searchComponent(name, item) {
        let title = typeof item === 'string' ? item : item.name;
        return (
            <div className="layui-inline">
                <label className={"layui-form-label " +(item.layTip ? 'layTip' : '')} title={title}>{title}</label>
                <div className="layui-input-block">
                    {typeof item.component === 'function' ? item.component(this) : item.component}
                </div>
            </div>
        )
    }

    oprationInit() {
		
        let opration = this.props.oprationInit || {},
            oprationContainer = {
                title: '操作',
                values: {}
            };
	 
        for (let k in opration) {
            let item = opration[k],
                icon = item.icon || [],
                show = item.show !== undefined ? item.show : true;
            if ((typeof show === 'function' && ! show(this.state)) || (! show)) {
                continue;
            }

            oprationContainer.values[k] = (model = {}) => {
                let disabled = typeof item.disabled === 'function' ? item.disabled(model) : (typeof item.disabled === "string" ? model[item.disabled] : false),
                    name = typeof item.name === 'function' ? item.name(model) : item.name,
                    icon0 = icon ? (typeof icon[0] === 'function' ? icon[0](model) : icon[0]) : false,
                    icon1 = icon ? (typeof icon[1] === 'function' ? icon[1](model) : icon[1]) : false,
                    icon2 = icon ? (typeof icon[2] === 'function' ? icon[2](model) : icon[2]) : false;
                return (
                    <button onClick={e => !disabled && item.click && item.click(model, this)}
                            className={"layui-btn layui-btn-" + (icon1 || 'normal') + " layui-btn-xs" + (disabled ? ' layui-btn-disabled' : '')}>
                        <i className={(icon0 ? ("layui-icon layui-icon-" + (icon0)) : '') + ' '+(icon2 || '')}></i><span>{name}</span>
                    </button>
                )
            }
        }
		
        return oprationContainer;
    }

    btnExtentions() {
		 
        let btns = this.props.btnInit || {},
            btnContainer = {};
        for (let k in btns) {
	
            let item = btns[k],
                show = item.show !== undefined ? item.show : true;
            if (show) {
                btnContainer[k] = () => {
					
                    let type = item.type || 'btn',
                        func = this['btn' + type.charAt(0).toUpperCase() + type.slice(1)].bind(this);
						
                    return func(item);
                }
            }
        }
        return btnContainer;
    }

    btnBtn(item) {
        return (
            <button className={"layui-btn " + item.className || ""} type='button'
                    onClick={e => item.click && item.click(this)}>{item.name}</button>
        )
    }

    btnComponent(item) {
        return item.component;
    }

    service() {
        return this.props.service;
    }

    getList(conf) {
        if (typeof this.props.getList === 'function') {
            this.props.getList(conf);
        } else {
            super.getList(conf);
        }
    }

    componentDidMount() {
        /* global layui,layer */
        let modules = ['form'],
            types = ['select', 'switch'],
            fields = this.props.fieldsInit || {},
            search = this.props.searchInit || {},
            searchGroups = this.props.searchGroups || {},
            _this = this;
        for(let k in searchGroups) {
            let searchEx = searchGroups[k];
            for(let j in searchEx) {
                search[j] = searchEx[j];
            }
        }
        for(var k in search) {
            var item = search[k];
            if (typeof item === 'object' && item.type === 'date') {
                modules.push('laydate');
            }
        }

        layui.use(modules, () => {
            let form = layui.form,
                laydate;

            if (modules.indexOf('laydate') > -1) {
                laydate = layui.laydate;
            }

            for (let k in fields) {
                let item = fields[k];
                if (item && typeof item === 'object') {
                    if (typeof item.change === 'function' && types.indexOf(item.type) > -1) {
                        let name = item.key || k;
                        form.on(item.type + '(field' + name + ')', (data) => {
                            var id = $(data.elem).attr('data-id');
                            !_this.autoload ? item.change({id: id, [name]: data.value}, _this) : item.change({id: id, [name]: data.value}, () => {
                                layer.msg('修改成功');
                                this.pageReload();
                            }, (msg) => {
                                layer.msg(msg);
                                this.pageReload();
                            })
                        })
                    }
                    if (item.checkbox) {
                        let filter = item.filter || this.checkboxFilter;
                        form.on('checkbox('+(filter)+')', (data) => {
                            let checked = data.elem.checked;
                            $(data.elem).prop('checked', checked);
                            if (! this.list.checkMany && checked) {
                                $(data.elem).closest('tr').siblings().find('.'+filter).each(function() {
                                    $(this).prop('checked', !checked);
                                })
                            }
                            form.render('checkbox');
                        })

                        form.on('checkbox(checkAll)', function(data) {
                            $('.'+filter).each(function() {
                                $(this).prop("checked", data.elem.checked);
                            })
                            form.render('checkbox')
                        });
                    }
                }
            }

            let dates = [];
            for (let k in search) {
                let item = search[k];
                if (item && typeof item === 'object' && item.type) {
                    if (types.indexOf(item.type) > -1) {
                        let name = item.key || k;
                        form.on(item.type+'(search'+name+')', (data) => {
                            this.state.search[name] = data.value;
                            (item.loadData === undefined || item.loadData) && _this.loadData();
                        })
                    }
                    if (item.type === 'date') {
                        dates.push({name:k,item:item});
                    }
                }
            }

            if (dates.length) {
                setTimeout(() => {
                     for(let k of dates) {
						  let key = k.item.key || k.name;
					
						if(_this.state.search[key]!==undefined){
						 
 						   // let key = k.item.key || k.name,
						    let field = k.item.field || 'created_at',
						    index = Object.keys(_this.state.search[key]).indexOf(field);
							laydate.render({
								elem: '#'+(key + (index > 0 ? index : ''))
								,type: k.item.format || 'date',
								trigger:'click'
								,done: value => {
									_this.state.search[key][field] = value;
									(k.item.loadData === undefined || k.item.loadData) && _this.loadData();
								}
							})	
						}
						 


                    }
                }, 1000)
            }

            $('.layTip').mouseover(function() {
                var obj = $(this);
                layer.tips(obj.attr('title'), obj.next(), {
                    tips:[4, '#78BA32']
                });
            })
        })
    }

    onChangeInput(e, name, model) {
        let origin = 'originchangeinput' + name;
        if (model[origin] === undefined) {
            model[origin] = model[name];
        }
        model[name] = e.target.value;
        this.listInit();
    }

    changeInput(name, model, item) {
        let origin = 'originchangeinput' + name;
        if (String(model[origin]) && model[origin] !== undefined && String(model[name]) !== String(model[origin]) && typeof item.change === 'function') {
            ! this.autoload ? item.change(model, this) : item.change(model, () => {
                layer.msg('修改成功');
                model[origin] = model[name];
                this.listInit();
            }, (msg) => {
                layer.msg(msg);
                this.pageReload();
            })
        }
    }
}

