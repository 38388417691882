import Base from "../../../base";
import AgreementService from "../../../../services/running/agreement";
import Form from "../../../layout/form";

class AgreementEdit extends Base
{
    boot() {
        this.state = {
            model: {}
        }
    }

    service() {
        return AgreementService;
    }

    componentDidMount() {
        this.server.getDetail(this.getPropsParams('id'), (data) => {
            this.setModel(data);
        })
    }

    render() {
        return (
            <Form
                model={this.state.model}
                fields={{
                    type:{name:'类型', type:'select', data:this.server.types},
                    client:{name:'所属端口', type:'select', data:this.server.clients},
                    content:{name:'内容', type:'editor',width:'100%',height:'500px'}
                }}
            />
        );
    }
}
export default AgreementEdit;
