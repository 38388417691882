import Base from "../../../base";
import Form from "../../../layout/form";
import AnnouncementService from "../../../../services/running/announcement";

export default class AnnouncementEdit extends Base
{
    boot() {
        this.state = {
            model:{
                title:'',
                type:0,
                content:'',
                startAt:'',
                endAt:'',
                status:1
            }
        }
    }

    service() {
        return AnnouncementService;
    }

    componentDidMount() {
        this.server.getDetail(this.getPropsParams('id'), data => {
            this.setModel(data);
        })
    }

    render() {
        return (
            <Form
                model={this.state.model}
                fields={{
                    type:{name:'所属端口', type:'select', data:this.server.type},
                    title:'标题',
                    content:{name:'内容', type:'editor'},
                    startAt:{name:'开始时间', type:'date', format:'datetime'},
                    endAt:{name:'结束时间', type:'date', format:'datetime'},
                    status:{name:'状态', type:'select', data: this.server.status}
                }}
            />
        );
    }
}
