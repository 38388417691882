import Base from "../../../base";
import BannerService from "../../../../services/running/banner";
import Form from "../../../layout/form";

class BannerAdd extends Base
{
    boot() {
        this.state = {
            model:{
                type:0,
                name:'',
                image:'',
                sort:0,
                status:1
            }
        }
    }

    service() {
        return BannerService;
    }

    render() {
        return (
            <Form
                model={this.state.model}
                fields={{
                    name: '名称',
                    image:{name:'图片', type:'image'},
                    sort:{name:'排序', type:'number'},
                    type:{'name':'类型', type:'radio', data: this.server.type},
                    status:{name:'状态', type: 'radio', data: this.server.status}
                }}
            />
        );
    }
}
export default BannerAdd;
