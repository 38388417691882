import Base from "../../../base";
import OrderRepealListLayout from "../layout";

export default class OrderRepealList extends Base
{
    render() {
        return (
            <OrderRepealListLayout

            />
        );
    }
}
