import Base from "../../../base";
import Table from "../../../layout/table";
import OrderPaymentQrcodeService from "../../../../services/order/paymentQrcode";

export default class OrderPaymentQrcodeList extends Base
{
    service() {
        return OrderPaymentQrcodeService;
    }

    render() {
        return (
            <Table
                service={OrderPaymentQrcodeService}
                stateInit={{
                    orderBy:['id', 'desc']
                }}
                searchInit={{
                    orderSn:{key:'order_sn', name:'工单编号', enter: true},
                    status:{name:'状态', type:'select', data: this.server.status, default:''}
                }}
                fieldsInit={{
                    id:{name:'ID', sort:{asc:'asc', desc:'desc'}},
                    orderSn:'工单编号',
                    type:{name:'类型', value: model => { return model.qrcode.name; }},
                    clientName:{name:'商户名称', value(model) { return model.client ? model.client.name : ''; }},
                    receipt:{name:'凭证',  type:'image'},
                    statusDup:'状态',
                    createdAt:'添加时间'
                }}
                oprationInit={{
                    detail:{name:'详情', icon:['list', 'normal'], click: this.detail.bind(this)},
                    audit:{name:'处理', icon:['', 'warm', 'iconfont icon-shenhe'], disabled: model => { return model.status !== 0; }, click:this.change.bind(this)},
                    delete:{name:'删除', icon:['delete', 'danger'], click:this.delete.bind(this)}
                }}
            />
        )
    }

    componentDidMount() {
        /* global layui,layer */
        layui.use(['form'], () => {

        })
    }

    change(model, table) {
        layer.confirm('已处理?',() => {
            audit(1);
        },() => {
        })
        function audit(status) {
            table.server.change({id:model.id,status:status}, () => {
                table.pageReload();
                layer.msg('已处理!');
            }, (msg) => {
                layer.msg(msg);
            })
        }
    }

    detail(model) {
        this.layOpen({
            title:' 巡检详情',
            content:'/admin/order/payment-qrcode/detail/'+model.id,
            area:['900px', '90%'],
            btn:['确定'],
            yes: (index) => {
                layer.close(index);
            }
        })
    }

    delete(model, table) {
        layer.confirm('您真的要删除工单号为 ' + model.orderSn + ' 吗?', () => {
            table.server.delete(model.id, () => {
                layer.msg('删除成功!');
                table.pageReload();
            }, (msg) => {
                layer.msg(msg);
            })
        })
    }
}
