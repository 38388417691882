import Base from "../../base";
import Form from "../../layout/form";
import CompanyService from "../../../services/company";

export default class CompanyEdit extends Base
{
    boot() {
        this.state = {
            model:{
                name:'',
                password:'',
                status:1,
            },
        }
    }

    service() {
        return CompanyService;
    }

    componentDidMount() {
        /* global layer */
        this.server.getDetail(this.getPropsParams('id'), data => {
            this.setModel(data);
        })
    }

    render() {
        return (
            <Form
                model={this.state.model}
                fields={{
                    name:'分部名称',
                    phone:'手机号码',
                    password:{name:'密码', type: 'password'},
                }}
            />
        );
    }
}

