import BaseService from '../base';

export default class ConfigService extends BaseService {
    requestUrls = {
        url: '/running/config/list',
        save: '/running/config/save',
    }

    getList(suc) {
        this.post(
            this.requestUrls.url,
            {},
            suc
        )
    }

    save(model, suc, err) {
        this.post(
            this.requestUrls.save,
            model,
            suc,
            err
        )
    }
}