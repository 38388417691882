import Base from "../../../base";
import Table from "../../../layout/table";
import style from "../../../device/keybord/import/index.module.css";
import $ from "jquery";
import Excel from "../../../../components/excel";
import OrderPatrolService from "../../../../services/order/patrol";
import { Fragment } from 'react';

export default class OrderPatrolImportAppoint extends Base
{
    boot() {
        this.state = {
            list:[],
            fileName:'',
        }
    }

    componentDidMount() {
        /* global layer,layui */
        layui.use(['form']);
    }

    service() {
        return OrderPatrolService;
    }

    render() {
        let fields = {};
        for(let k in this.server.appointFields) {
            fields[k] = {name:this.server.appointFields[k], value: this.inputComponent.bind(this), headerStyle:{width:'100px'}};
        }
        return (
            <div
                style={{background:'white',paddingBottom:'20px'}}
                onDragEnter={e => this.onDragEnter(e)}
                onDragOver={e => this.onDragOver(e)}
                onDragLeave={e => this.onDragLeave(e)}
                onDrop={e => this.onDrop(e)}
                ref={"contanier"}
            >
                <Table
                    autoload={false}
                    list={this.state.list}
                    layuiCardHeader={false}
                    fieldsInit={fields}
                    paging={false}
                    oprationShow={false}
                    btnInit={{
                        file:{name:'请选择文件', click: this.chooseFile.bind(this)},
                        count:{type:'component', component: <Fragment><span>{this.state.fileName}</span><div>共有数据(<span style={{color:'red'}}>{this.state.list.length}</span>)条</div></Fragment>}
                    }}
                    tableStyle={{
                        tableLayout:'fixed',
                    }}
                />
                {
                    ! this.state.list.length ? (
                        <div className={style.fileTip}>文件拖至此处进行预览</div>
                    ) : ''
                }
                <div style={{display:'none'}} className={"layui-form"}>
                    {
                        this.state.list.map((item, index) => {
                            return Object.keys(item).map((key, i) => {
                                if (Object.keys(this.server.appointFields).indexOf(key) > -1) {
                                    return (
                                        <input key={(index + 1) * (i + 1)} type="hidden" name={"data["+index+"]["+key+"]"} value={item[key]} />
                                    )
                                }
                            })
                        })
                    }
                    <input type="file" ref={"file"} onChange={e => this.fileChange(e)} />
                    <div className="layui-form-item layui-hide">
                        <input type="button" lay-submit="" lay-filter="LAY-user-front-submit" id="LAY-user-front-submit" value="确认" />
                    </div>
                </div>
            </div>
        );
    }

    inputComponent(model, field, i) {
        return (
            <div>
                <input style={{display:model[field+'show'] ? 'block':'none'}} type="text" onBlur={e => this.showInput(e,false, field, i)} onChange={e => this.onChange(e, field, i)} className={"layui-input"} value={model[field]} />
                <div style={{display:model[field+'show'] ? 'none':'block', cursor:'pointer', minHeight:'30px',overflow:'hidden', textOverflow:'ellipsis'}} title={"点击修改"} onClick={e => this.showInput(e,true, field, i)}>{model[field]}</div>
            </div>
        )
    }

    onChange(e, field, index) {
        let list = this.state.list,
            item = list[index];
        item[field] = e.target.value;
        this.setState({
            list:list
        })
    }

    showInput(e, show, field, index) {
        let list = this.state.list,
            card = field+'show';

        list.forEach((item, i) => {
            for(let k in item) {
                if (k.indexOf('show') > -1) {
                    item[k] = Number(index) === Number(i) && k === card && show;
                }
            }
        })

        this.setState({
            list:list
        }, () => {
            setTimeout(() => {
                if (show) {
                    $(e.target).prev().focus();
                }
            },100)
        })
    }

    onDragEnter(e) {
        this.refs.contanier.style.background = '#cbf7e3';
    }

    onDragOver(e) {
        e.preventDefault();
    }

    onDragLeave(e) {
        this.refs.contanier.style.background = 'white';
    }

    onDrop(e) {
        e.preventDefault();
        this.refs.contanier.style.background = 'white';
        let file = e.dataTransfer.files[0];
        this.refs.file.files = e.dataTransfer.files;
        if (file) {
            this.handleFile(file.name, file);
        }
    }

    handleFile(name, file) {
        let excel = new Excel();
        excel.import(
            name,
            file,
            Object.keys(this.server.appointFields),
            list => {
                list.forEach(item => {
                    for(let k in item) {
                        item[k +'show'] = false;
                    }
                })
                this.setState({
                    fileName:name,
                    list:list
                })
            }
        )
    }

    chooseFile() {
        let file = this.refs.file;
        file.value = '';
        file.click();
    }

    fileChange(e) {
        this.handleFile(e.target.value, e.target.files[0]);
    }
}