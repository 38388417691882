import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";

class RuleService extends BaseService
{
    requestUrls = {
        url:'/system/rule/list',
        save:'/system/rule/save',
        detail:'/system/rule/detail',
        delete:'/system/rule/delete',
        sort:'/system/rule/sort',
        userRules: '/system/rule/user'
    }

    getList(suc, err) {
        this.post(
            this.requestUrls.url,
            {},
            suc,
            err
        )
    }

    getAll(suc) {
        this.getList((data) => {
                let first = this.siblingsSort(this.getFirst(data));
            for(var k of first) {
                k.children = this.getChildren(k.id, data);
            }
            suc(first);
        })
    }

    getCanForParent(suc) {
        this.getAll((data) => {
            let list = [];
            for(var k of data) {
                list.push(k);
                for(var j of k.children) {
                    list.push(j);
                }
            }
            suc(list);
        })
    }

    getRules(func, reset = false, err) {
        let rules = this.app.getItem(this.auth.ruleKey);
        if (!reset && rules) {
            return func && func(JSON.parse(rules));
        }

        this.request({
            url:this.requestUrls.userRules,
            success: data => {
                this.auth.setRules(data);
                func && func(data);
            },
            error:err,
            notLogin: () => {

            }
        })
    }

    getMenu(suc, err) {
        this.getRules((data) => {
            let menu = this.siblingsSort(this.getFirst(data, true));
            for(const k of data) {
                k.children = this.getChildren(k.id, data, true);
            }
            suc(menu);
        }, true, err)
    }

    getFirst(data, isMenu = false) {
        let menu = [];
        data.forEach(item => {
            if ((! isMenu && !item.pid) || (isMenu && !item.pid && item.isMenu)) {
                menu.push(item);
            }
        })
        return menu;
    }

    getChildren(id, data, isMenu = false) {
        let children = [],
            rest = [];
        data = this.siblingsSort(data);
        for(var k of data) {
            if ((! isMenu && k.pid === id) || (isMenu && k.pid === id && k.isMenu)) {
                children.push(k);
            } else {
                rest.push(k);
            }
        }
        if (rest.length) {
            for(const k of children) {
                k.children = [];
                for(var j of rest) {
                    if ((! isMenu && j.pid === k.id) || (isMenu && j.pid === k.id && j.isMenu)) {
                        k.children.push(j);
                    }
                }
                k.children = this.siblingsSort(k.children);
            }
        }
        return children;
    }

    getSiblings(pid, data) {
        let list = [];
        data.forEach(item => {
            if (item.pid === pid) {
                list.push(item);
            }
            if (item.children) {
                item.children.forEach(val => {
                    if (val.pid === pid) {
                        list.push(val);
                    }
                    if (val.children) {
                        val.children.forEach(v => {
                            if (v.pid === pid) {
                                list.push(v);
                            }
                        })
                    }
                })
            }
        })
        return list;
    }

    siblingsSort(data) {
        let sorts = [],
            list = [],
            ids = [];
        for(let k of data) {
            sorts.push(k.sort);
        }
        sorts = sorts.sort();
        for(let k in sorts) {
            for(var j of data) {
                if (j.sort === sorts[k] && ids.indexOf(j.id) < 0) {
                    list.push(j);
                    ids.push(j.id);
                }
            }
        }
        return list;
    }

    save(model, suc, err) {
        let validator = new Validator(),
            rules = [
                ['name', 'required', '请输入名称']
            ];

        model = this.app.htmlArrayToJson(model);
        model.isMenu = model.isMenu || 0;
        if (! validator.validate(rules, model)) {
            return err(validator.getError());
        }

        this.post(
            this.requestUrls.save,
            model,
            (res) => {
                if (model.sorts) {
                    let lenth = Object.keys(model.sorts).length,
                        func,
                        i = 0;

                    for(var k in model.sorts) {
                        i ++;
                        if (i === lenth) {
                            func = () => {
                                suc(res);
                            }
                        }
                        this.sort(
                            {id:k.replace('id', ''), sort:model.sorts[k]},
                            func
                            )
                    }
                } else {
                    suc(res);
                }
            },
            err
        )
    }

    sort(model, suc, err) {
        this.post(
            this.requestUrls.sort,
            model,
            suc,
            err
        )
    }

    getDetail(id, suc) {
        this.post(
            this.requestUrls.detail,
            {id:id},
            suc
        )
    }

    delete(id, suc, err) {
        this.post(
            this.requestUrls.delete,
            {id:id},
            suc,
            err
        )
    }

    getIconAttribute(val) {
        if (val) {
            return JSON.parse(val);
        }
        return {};
    }
}
export default RuleService;
