import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";
import File from "../../../../vendor/framework/file";

export default class VersionPackageService extends BaseService
{
    requestUrls = {
        url:'/running/version-package/list',
        save:'/running/version-package/save',
        detail:'/running/version-package/detail',
        delete:'/running/version-package/delete'
    }

    status = ['禁用', '启用'];

    type = ['安卓'];

    appends = ['statusDup', 'typeDup'];

    file = new File();

    save(model, suc, err) {
        let validator = new Validator(),
            rules = [
                //['image', 'required', '请上传图片'],
            ];
        if (! validator.validate(rules, model)) {
            return err(validator.getError());
        }
        model.link = this.file.filter(model.link);
        this.post(
            this.requestUrls.save,
            model,
            suc,
            err
        )
    }

    getDetail(id, suc) {
        this.post(
            this.requestUrls.detail,
            {id:id},
            suc
        )
    }

    delete(model, suc, err) {
        this.post(
            this.requestUrls.delete,
            {id:model.id},
            suc,
            err
        )
    }


    getStatusDupAttribute() {
        return this.status[this.attributes['status']];
    }

    getTypeDupAttribute() {
        return this.type[this.attributes['type']];
    }
}
