import Middleware from "../../../vendor/framework/middleware";
import AdminService from "../services/admin";
import RuleService from "../services/admin/rule";

class PermissionMiddleware extends Middleware
{
    admin = new AdminService();

    rule = new RuleService();

    handle(props) {
        let path = props.match.path;

        if (this.admin.leaders.indexOf(this.rule.auth.getUser('username')) === -1) {
            this.rule.getRules((data) => {
                let paths = [];
                for(var k of data) {
                    if (k.viewPath) {
                        paths.push(k.viewPath);
                    }
                }
                if (paths.indexOf(path) < 0) {
                    this.rule.app.redirect('/admin/error');
                    throw SyntaxError("您没有权限");
                }
            })
        }
    }
}
export default PermissionMiddleware;
