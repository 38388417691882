import Base from "../../../base";
import OrderMaintainingListLayout from "../layout";

export default class OrderMaintainingList extends Base
{
    render() {
        return (
            <OrderMaintainingListLayout />
        );
    }
}
