import Base from "../../base";
import CompanyListLayout from "../layout";

export default class CompanyList extends Base
{
    render() {
        return (
            <CompanyListLayout

            />
        );
    }
}
