import Base from "../../../base";
import PosListLayout from "../layout";

export default class PosList extends Base
{
    render() {
        return (
            <PosListLayout
                oprationShow={true}
            />
        );
    }
}
