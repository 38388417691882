import Base from "../../../../base";
import PaymentQrcodeTypeService from "../../../../../services/running/paymentQrcodeType";
import Form from "../../../../layout/form";

export default class ReceiptCodeTypeEdit extends Base
{
    boot() {
        this.state = {
            model: {
                name:'',
                status:1
            }
        }
    }

    service() {
        return PaymentQrcodeTypeService;
    }

    componentDidMount() {
        this.server.getDetail(this.getPropsParams('id'), (data) => {
            this.setModel(data);
        })
    }

    render() {
        return (
            <Form
                model={this.state.model}
                fields={{
                    name:'类型名称',
                    status:{name:'状态', type:'radio', data: this.server.status}
                }}
            />
        );
    }
}

