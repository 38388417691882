import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";

export default class LogService extends BaseService
{
    requestUrls = {
        url:'/device/logDetail',
    }
 
    status = ['入库', '出库'];
    appends = ['statusDup'];

    getStatusDupAttribute() {
        return this.status[this.attributes['status']];
    }
	 
	
	getList(id, suc) {
		console.log(55555555555555555555);
	    this.post(
	        this.requestUrls.url,
	        {id:id},
	        suc
	    )
	}
	
	
 
	
 
}
