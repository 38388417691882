import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";

export default class PaymentQrcodeTypeService extends BaseService
{
    requestUrls = {
        url:'/running/payment-qrcode/type/list',
        save:'/running/payment-qrcode/type/save',
        detail:'/running/payment-qrcode/type/detail',
        delete:'/running/payment-qrcode/type/delete'
    }

    status = ['禁用', '启用'];

    appends = ['statusDup'];

    getList(status, suc) {
        this.post(
            this.requestUrls.url,
            {
                search:{
                    status:status
                }
            },
            suc
        )
    }

    save(model, suc, err) {
        let validator = new Validator(),
            rules = [
                ['name', 'required', '请填写类型名称'],
            ];
        if (! validator.validate(rules, model)) {
            return err(validator.getError());
        }

        this.post(
            this.requestUrls.save,
            model,
            suc,
            err
        )
    }

    getDetail(id, suc) {
        this.post(
            this.requestUrls.detail,
            {id:id},
            suc
        )
    }

    delete(model, suc, err) {
        this.post(
            this.requestUrls.delete,
            {id:model.id},
            suc,
            err
        )
    }

    getStatusDupAttribute() {
        return this.status[this.attributes['status']];
    }
}
