import View from "../../../../../../../vendor/framework/view";
import PaymentQrcodeTypeService from "../../../../../services/running/paymentQrcodeType";
import Table from "../../../../layout/table";

export default class ReceiptCodeTypeListLayout extends View
{
    boot() {
        this.state = {
            list:[]
        }
    }

    service() {
        return PaymentQrcodeTypeService;
    }

    componentDidMount() {
        /* global layer */
        this.getList();
    }

    getList() {
        this.server.getList(this.app.getUrlParams('status'),
                data => {
            this.setState({
                list:data
            })
        })
    }

    render() {
        return (
            <Table
                autoload={false}
                list={this.state.list}
                fieldsInit={{
                    id:'ID',
                    name:'类型名称',
                    statusDup:'状态',
                    createdAt:'添加时间'
                }}
                oprationInit={{
                    edit:{name:'编辑', icon: ['edit'], click: this.edit.bind(this)},
                    delete:{name: '删除', icon:['delete', 'danger'], click:this.delete.bind(this)}
                }}
                btnInit={{
                    add:{name:'添加', click: this.add.bind(this)}
                }}
            />
        )
    }

    add() {
        this.layOpen({
            title:'添加',
            content:'/admin/running/receipt-code/type/add',
            form:true,
            area:['600px', '300px'],
            yes: (field, index) => {
                this.server.save(field, () => {
                    layer.msg('添加成功!');
                    layer.close(index);
                    this.getList();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    edit(model) {
        this.layOpen({
            title:'修改',
            content:'/admin/running/receipt-code/type/edit/'+model.id,
            form:true,
            area:['600px', '300px'],
            yes: (field, index) => {
                field.id = model.id;
                this.server.save(field, () => {
                    layer.msg('修改成功!');
                    layer.close(index);
                    this.getList();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    delete(model) {
        layer.confirm('您真的要删除这条数据吗?', () => {
            this.server.delete(model, () => {
                layer.msg('删除成功!');
                this.getList();
            }, (msg) => {
                layer.msg(msg);
            })
        })
    }
}
