import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";

export default class AnnouncementService extends BaseService
{
    requestUrls = {
        url:'/running/announcement/list',
        save:'/running/announcement/save',
        detail:'/running/announcement/detail',
        delete:'/running/announcement/delete'
    }

    status = ['禁用', '启用'];

    type = ['员工端', '客户端']

    appends = ['statusDup', 'typeDup', 'contentDup'];

    save(model, suc, err) {
        let validator = new Validator(),
            rules = [
                ['type', 'required', '请选择端口'],
                ['title', 'required', '请填写标题'],
                ['content', 'required', '请填写内容'],
                ['startAt', 'required', '请选择开始时间'],
                ['endAt', 'required', '请填写结束时间'],
            ];
        if (! validator.validate(rules, model)) {
            return err(validator.getError());
        }

        this.post(
            this.requestUrls.save,
            model,
            suc,
            err
        )
    }

    getDetail(id, suc) {
        this.post(
            this.requestUrls.detail,
            {id:id},
            suc
        )
    }

    delete(model, suc, err) {
        this.post(
            this.requestUrls.delete,
            {id:model.id},
            suc,
            err
        )
    }

    getStatusDupAttribute() {
        return this.status[this.attributes['status']];
    }

    getTypeDupAttribute() {
        return this.type[this.attributes['type']];
    }

    getContentDupAttribute() {
        var content = this.app.delHtmlTag(this.attributes['content']);
        var length = content.length;
        if (! content && this.attributes['content']) {
            if (this.attributes['content'].match(/<img/)) {
                return '--图片内容--';
            }
        }
        return length > 20 ? (content.substr(0, 17) + '...') : content;
    }
}
