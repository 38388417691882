import View from "../../../../../../vendor/framework/view";
import Table from "../../../layout/table";
import ActivityService from "../../../../services/running/activity";

export default class ActivityListLayout extends View
{
    service() {
        return ActivityService;
    }

    componentDidMount() {
        /* global layer */
    }

    render() {
        return (
            <Table
                service={ActivityService}
                stateInit={{
                    orderBy:['id', 'desc']
                }}
                searchInit={{
                    keywords:{name:'关键字', enter: true},
                    status:{name:'状态', value:'',type: 'select', data: [{key:'',name:'--全部--'},{key:1,name:'开启'}, {key:0, name:'禁用'}], option:['key', 'name']},
                    dateMin:{name:'添加时间',  type:'date', position:0},
                    dateMax:{type:'date', position:1},
                }}
                fieldsInit={{
                    id:{name:'ID', sort:{asc:'asc', desc:'desc'}},
                    name:'名称',
                    image:{name:'封面', type:'image'},
                    contentDup:'内容',
                    sort:{name:'排序', type: 'input', change:this.server.changeSort.bind(this.server), sort:{asc:'asc', desc:'desc'}},
                    status:{name:'状态', type:'switch', checked:1, text:'开启|禁用', change:this.server.changeState.bind(this.server)},
                    createdAt:'添加时间'
                }}
                oprationInit={{
                    edit:{name:'编辑', icon: ['edit'], click: this.edit.bind(this)},
                    delete:{name: '删除', icon:['delete', 'danger'], click:this.delete.bind(this)}
                }}
                btnInit={{
                    add:{name:'添加', click: this.add.bind(this)}
                }}
            />
        );
    }

    add(table) {
        this.layOpen({
            title:'添加',
            content:'/admin/running/activity/add',
            form:true,
            area:['1000px', '90%'],
            yes: (field, index) => {
                table.server.save(field, () => {
                    layer.msg('添加成功!');
                    layer.close(index);
                    table.loadData();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    edit(model, table) {
        this.layOpen({
            title:'修改 '+model.name,
            content:'/admin/running/activity/edit/'+model.id,
            form:true,
            area:['1000px', '90%'],
            yes: (field, index) => {
                field.id = model.id;
                table.server.save(field, () => {
                    layer.msg('修改成功!');
                    layer.close(index);
                    table.loadData();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    delete(model, table) {
        layer.confirm(`您真的要删除 ${model.name} 吗?`, () => {
            table.server.delete(model, () => {
                layer.msg('删除成功!');
                table.pageReload();
            }, (msg) => {
                layer.msg(msg);
            })
        })
    }
}
