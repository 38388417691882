import Base from "../../../base";
import BankListLayout from "../layout";

export default class BankChooseList extends Base
{
    boot() {
        this.state = {
            type: this.app.getUrlParams('type') || ''
        }
    }

    render() {
        return (
            <BankListLayout
                search={{
                    type:this.state.type
                }}
                canCheck={true}
                oprationShow={false}
                searchFields={['keywords', 'type']}
                btnShow={false}
            />
        );
    }
}
