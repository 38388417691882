import Base from "../../../base";
import AgreementService from "../../../../services/running/agreement";
import Form from "../../../layout/form";

export default class AgreementAdd extends Base
{
    service() {
        return AgreementService;
    }

    render() {
        return (
            <Form
                fields={{
                    type:{name:'类型', type:'select', data:this.server.types},
                    client:{name:'所属端口', type:'select', data:this.server.clients},
                    content:{name:'内容', type:'editor',width:'100%',height:'500px'}
                }}
            />
        );
    }
}
