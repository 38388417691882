import ConsoleService from "../../services/console";
import $ from 'jquery';
import Base from "../base";
import Device from "./device";

export default class Console extends Base
{
	boot() {
		this.state = {
			model:{
				terminals:{
					all:0,
					month:0
				},
				client:{
					all:0,
					month:0
				},
				employee:{
					all:0,
					month:0
				},
				company:{
					all:0,
					month:0
				},
				install:{
					all:0,
					month:0
				},
				maintaining:{
					all:0,
					month:0
				},
				patrol:{
					all:0,
					month:0
				},
				repeal:{
					all:0,
					month:0
				},
				money:{}
			},
			device:[]
		}
	}

	componentWillMount() {
		if (window.top === window.self) {
			this.app.redirect('/admin/index');
		}
		super.componentWillMount();
	}

	service() {
		return ConsoleService;
	}

	init(){
		let _this = this;
		function getData() {
			_this.server.getData((data) => {
				_this.setModel(data);
			}, () => {
				// setTimeout(() => {
				// 	getData();
				// }, 10000)
			})
		}

		function getDevice() {
			_this.server.getDevice(data => {
				_this.setState({
					device:data
				})
			}, () => {
				// setTimeout(() => {
				// 	getDevice();
				// }, 20000)
			})
		}

		getData();
		getDevice();
	}


	componentDidMount() {
		/* global layui,layer */
		layui.use(['form'], () => {
			this.init();
		})
	}

	render() {
		let dayObj = new Date(),
			year = dayObj.getFullYear(),
			month = dayObj.getMonth() + 1,
			date = dayObj.getDate();
		month = month < 10 ? '0' + month : month;
		date = date < 10 ? '0' + date : date;
		let today = year + '-' +  month + '-' + date;
		return (
			<div className="layui-fluid">
				<div className="layui-row layui-col-space15">
					<div className="layui-col-md12">
						<div className="layui-row layui-col-space15">
							<div className="layui-col-md4">
								<div className="layui-card">
									<div className="layui-card-header">终端数量</div>
									<div className="layui-card-body layadmin-takerates">
										<ul className="layui-row layui-col-space10">
											<li className="layui-col-xs6">
												<div className="lineTextCenter">
													<a href="#!" className="layadmin-backlog-body" onClick={e => this.goPage(0)}>
														<h3>总数量</h3>
														<p><cite>{this.state.model.terminals.all}</cite></p>
													</a>
												</div>
											</li>
											<li className="layui-col-xs6">
												<a href="#!" className="layadmin-backlog-body">
													<div className="lineTextCenter">
														<h3>本月新增</h3>
														<p><cite>{this.state.model.terminals.month}</cite></p>
													</div>
												</a>
											</li>
										</ul>
									</div>
								</div>
								<div className="layui-card">
									<div className="layui-card-header">商户数</div>
									<div className="layui-card-body layadmin-takerates">
										<ul className="layui-row layui-col-space10">
											<li className="layui-col-xs6">
												<div className="lineTextCenter">
													<a href="#!" className="layadmin-backlog-body" onClick={e => this.goPage(1)}>
														<h3>总数量</h3>
														<p><cite>{this.state.model.client.all}</cite></p>
													</a>
												</div>
											</li>
											<li className="layui-col-xs6">
												<div className="lineTextCenter">
													<a href="#!" className="layadmin-backlog-body">
														<h3>本月新增</h3>
														<p><cite>{this.state.model.client.month}</cite></p>
													</a>
												</div>
											</li>
										</ul>
									</div>
								</div>
								<div className="layui-card">
									<div className="layui-card-header">分部数</div>
									<div className="layui-card-body layadmin-takerates">
										<ul className="layui-row layui-col-space10">
											<li className="layui-col-xs6">
												<div className="lineTextCenter">
													<a href="#!" className="layadmin-backlog-body" onClick={e => this.goPage(2)}>
														<h3>总数</h3>
														<p><cite>{this.state.model.company.all}</cite></p>
													</a>
												</div>
											</li>
											<li className="layui-col-xs6">
												<div className="lineTextCenter">
													<a href="#!" className="layadmin-backlog-body">
														<h3>本月新增</h3>
														<p><cite>{this.state.model.company.month}</cite></p>
													</a>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="layui-col-md4">
								<div className="layui-card">
									<div className="layui-card-header">装机工单数</div>
									<div className="layui-card-body layadmin-takerates">
										<ul className="layui-row layui-col-space10">
											<li className="layui-col-xs6">
												<div className="lineTextCenter">
													<a href="#!" className="layadmin-backlog-body" onClick={e => this.goPage(5)}>
														<h3>总数</h3>
														<p><cite>{this.state.model.install.all}</cite></p>
													</a>
												</div>
											</li>
											<li className="layui-col-xs6">
												<div className="lineTextCenter">
													<a href="#!" className="layadmin-backlog-body" onClick={e => this.goPage(5)}>
														<h3>本月新增</h3>
														<p><cite>{this.state.model.install.month}</cite></p>
													</a>
												</div>
											</li>
										</ul>
									</div>
								</div>
								<div className="layui-card">
									<div className="layui-card-header">维护工单数</div>
									<div className="layui-card-body layadmin-takerates">
										<ul className="layui-row layui-col-space10">
											<li className="layui-col-xs6">
												<div className="lineTextCenter">
													<a href="#!" className="layadmin-backlog-body" onClick={e => this.goPage(6)}>
														<h3>总数</h3>
														<p><cite>{this.state.model.maintaining.all}</cite></p>
													</a>
												</div>
											</li>
											<li className="layui-col-xs6">
												<div className="lineTextCenter">
													<a href="#!" className="layadmin-backlog-body" onClick={e => this.goPage(6, 'startDate='+today)}>
														<h3>本月新增</h3>
														<p><cite>{this.state.model.maintaining.month}</cite></p>
													</a>
												</div>
											</li>
										</ul>
									</div>
								</div>
								<div className="layui-card">
									<div className="layui-card-header">巡检工单数</div>
									<div className="layui-card-body layadmin-takerates">
										<ul className="layui-row layui-col-space10">
											<li className="layui-col-xs6">
												<div className="lineTextCenter">
													<a href="#!" className="layadmin-backlog-body" onClick={e => this.goPage(7)}>
														<h3>总数</h3>
														<p><cite>{this.state.model.patrol.all}</cite></p>
													</a>
												</div>
											</li>
											<li className="layui-col-xs6">
												<div className="lineTextCenter">
													<a href="#!" className="layadmin-backlog-body" onClick={e => this.goPage(7, 'startDate='+today)}>
														<h3>本月新增</h3>
														<p><cite>{this.state.model.patrol.month}</cite></p>
													</a>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="layui-col-md4">
								<div className="layui-card">
									<div className="layui-card-header">员工数</div>
									<div className="layui-card-body layadmin-takerates">
										<ul className="layui-row layui-col-space10">
											<li className="layui-col-xs6">
												<div className="lineTextCenter">
													<a href="#!" className="layadmin-backlog-body" onClick={e => this.goPage(8)}>
														<h3>总数量</h3>
														<p><cite>{this.state.model.employee.all}</cite></p>
													</a>
												</div>
											</li>
											<li className="layui-col-xs6">
												<a href="#!" className="layadmin-backlog-body">
													<div className="lineTextCenter">
														<h3>本月新增</h3>
														<p><cite>{this.state.model.employee.month}</cite></p>
													</div>
												</a>
											</li>
										</ul>
									</div>
								</div>
								<div className="layui-card">
									<div className="layui-card-header">撤机工单数</div>
									<div className="layui-card-body layadmin-takerates">
										<ul className="layui-row layui-col-space10">
											<li className="layui-col-xs6">
												<div className="lineTextCenter">
													<a href="#!" className="layadmin-backlog-body" onClick={e => this.goPage(7)}>
														<h3>总数</h3>
														<p><cite>{this.state.model.repeal.all}</cite></p>
													</a>
												</div>
											</li>
											<li className="layui-col-xs6">
												<div className="lineTextCenter">
													<a href="#!" className="layadmin-backlog-body" onClick={e => this.goPage(7, 'startDate='+today)}>
														<h3>本月新增</h3>
														<p><cite>{this.state.model.repeal.month}</cite></p>
													</a>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/*<div className="layui-col-md12">*/}
					{/*	<div className="layui-card">*/}
					{/*		<div className="layui-card-header">设备分布图</div>*/}
					{/*		<div className="layui-card-body">*/}
					{/*			<div style={{display:'flex',justifyContent:'center'}}>*/}
					{/*				/!*<Device list={this.state.device} />*!/*/}
					{/*			</div>*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*</div>*/}
				</div>
			</div>
		)
	}

	goPage(page, params) {
		let urlData = [
			['终端列表', '/admin/user/device/list'],
			['商户列表', '/admin/user/list'],
			['分部列表', '/admin/company/list'],
		][page];

		var layIndex = layer.open({
			title:urlData[0],
			content:urlData[1],
			type:2,
			area:['100%', '100%'],
			btn:['确定'],
			maxmin:true,
			min() {
				$('#layui-layer-shade'+layIndex).hide();
			},
			max() {
				$('#layui-layer-shade'+layIndex).show();
			},
			restore() {
				$('#layui-layer-shade'+layIndex).show();
			},
			yes(index) {
				layer.close(index);
			}
		})
	}
}

