import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";
import File from "../../../../vendor/framework/file";

export default class ActivityService extends BaseService
{
    file = new File();

    requestUrls = {
        url:'/running/activity/list',
        save:'/running/activity/save',
        detail:'/running/activity/detail',
        sort:'/running/activity/sort',
        change:'/running/activity/change',
        delete:'/running/activity/delete'
    }

    status = ['禁用', '启用'];

    appends = ['stateDup'];

    listAppends = ['contentDup'];

    save(model, suc, err) {
        let validator = new Validator(),
            rules = [
                ['name', 'required', '请填写活动名称'],
                ['image', 'required', '请上传封面'],
                ['content', 'required', '请填写内容']
            ];
        if (! validator.validate(rules, model)) {
            return err(validator.getError());
        }

        this.post(
            this.requestUrls.save,
            model,
            suc,
            err
        )
    }

    getDetail(id, suc) {
        this.post(
            this.requestUrls.detail,
            {id:id},
            suc
        )
    }

    delete(model, suc, err) {
        this.post(
            this.requestUrls.delete,
            {id:model.id},
            suc,
            err
        )
    }

    changeState(data, suc, err) {
        let model = {
            id:data.id,
            status:Number(data.status) === 0 ? 1 : 0
        }
        this.post(
            this.requestUrls.change,
            model,
            suc,
            err
        )
    }

    changeSort(data, suc, err) {
        let model = {
            id:data.id,
            sort:data.sort
        }
        this.post(
            this.requestUrls.sort,
            model,
            suc,
            err
        )
    }

    getStateDupAttribute() {
        return this.status[this.attributes['status']];
    }

    getContentDupAttribute() {
        var content = this.app.delHtmlTag(this.attributes['content']);
        var length = content.length;
        if (! content && this.attributes['content']) {
            if (this.attributes['content'].match(/<img/)) {
                return '--图片内容--';
            }
        }
        return length > 20 ? (content.substr(0, 17) + '...') : content;
    }

    getImageAttribute() {
        return this.file.make(this.attributes['image']);
    }
}
