import Base from "../../../base";
import BankListLayout from "../layout";

export default class BankList extends Base
{
    render() {
        return (
            <BankListLayout />
        );
    }
}
