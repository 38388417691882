import Base from "../../../../base";
import Detail from "../../../../layout/detail/detail";
import InstallApplyService from "../../../../../services/order/installApply";

export default class OrderInstallApplyDetail extends Base {
    boot() {
        this.state = {
            model: {},
        }
    }

    service() {
        return InstallApplyService;
    }

    componentDidMount() {
        /* global layer */
        this.server.getDetail(this.getPropsParams('id'), data => {
            this.setModel(data);
        });
    }

    render() {
        return (
            <Detail
                model={this.state.model}
                fields={[
                    {
                        title:'基本信息',
                        fields:{
                            orderSn:'申请编号',
                            username:'联系人',
                            phone:'联系电话',
                            createdAt:'申请时间',
                            statusDup:'状态'
                        }
                    },
                    {
                        title:'商户信息',
                        fields:{
                            'client.code':'商户编号',
                            'client.name':'商户名称',
                            btn:{name:'查看详情', type:'itemComponent', component: () => {
                                    return (
                                        <button className={"layui-btn layui-btn-sm layui-btn-normal"} onClick={e => this.showClient(e)}>
                                            <i className="layui-icon layui-icon-list"></i>
                                            查看
                                        </button>
                                    )
                                }}
                        }
                    }
                ]}
            />
        );
    }

    showClient() {
        this.layOpen({
            title:'查看商户 ' + this.state.model.client.name,
            content:'/admin/user/detail/'+this.state.model.uid,
            area:['90%', '96%'],
            btn:['确定'],
            yes: (index) => {
                layer.close(index);
            }
        })
    }
}

