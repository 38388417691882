import * as XLSX from "xlsx";
import xlsxPopulate from "xlsx-populate";

export default class Excel {

    letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

    constructor() {
        /* global layer */
    }

    /**
     * 导入
     * @param name 文件名
     * @param file 文件
     * @param fields 字段
     * @param suc 回调
     * @returns {*}
     */
    import(name, file, fields, suc) {
        let fileReader = new FileReader(),
            fileName = name.replace(/\\/g, '/').split('/');
        fileName = fileName[fileName.length - 1];

        var ext = fileName.split('.')[1];

        if (! ext || ['xlsx'].indexOf(ext) < 0) {
            return layer.msg('文件格式不允许!');
        }

        fileReader.onload = event => {
            const {result} = event.target;
            // 以二进制流方式读取得到整份excel表格对象
            const workbook = XLSX.read(result, {type: 'binary'});

            let excelData = {};
            // 遍历每张工作表进行读取（这里默认只读取第一张表）
            for (const sheet in workbook.Sheets) {
                if (workbook.Sheets.hasOwnProperty(sheet)) {
                    var rows = workbook.Sheets[sheet];
                    var maxC = 1;
                    for (var k in rows) {
                        var num = Number(k.replace(/[^0-9]/ig, ""));
                        if (num > maxC) {
                            maxC = num;
                        }
                    }
                    var letters = [];
                    for (var k in rows) {
                        var letter = k.replace(/[^a-zA-Z]/g, '');
                        var num = Number(k.replace(/[^0-9]/ig, ""));
                        if (letters.indexOf(letter) < 0 && num) {
                            letters.push(letter);
                        }
                    }
                    for (var k in letters) {
                        for (var i = 1; i <= maxC; i++) {
                            var key = letters[k] + i;
                            if (!rows.hasOwnProperty(key)) {
                                rows[key] = {w: ''};
                            }
                        }
                    }

                    var keys = [];
                    for (var k in rows) {
                        var num = Number(k.replace(/[^0-9]/ig, ""));
                        if (num) {
                            keys.push(k);
                        }
                    }

                    keys = keys.sort();

                    for (var k in keys) {
                        var v = keys[k];
                        var columns = rows[v];
                        if (columns.hasOwnProperty('w')) {
                            var letter = v.replace(/[^a-zA-Z]/g, '');
                            if (!excelData[letter]) {
                                excelData[letter] = [];
                            }
                            excelData[letter].push(columns.w);
                        }
                    }
                    break;
                }
            }

            let letterArr = Object.keys(excelData);
            letterArr.sort((a, b) => {
                return a.length - b.length;
            })

            let fieldsArr = {},
                dataArr = {};
            fields.forEach((item, i) => {
                fieldsArr[item] = letterArr[i];
            })

            Object.keys(excelData).forEach(key => {
                Object.keys(fieldsArr).forEach(l => {
                    if (fieldsArr[l] === key) {
                        dataArr[l] = excelData[key];
                    }
                })
            })

            let max = 0;
            Object.keys(dataArr).forEach(field => {
                if (! max || max < dataArr[field].length) {
                    max = dataArr[field].length;
                }
            })

            let title = {},
                list = [];
            for(let k = 0; k < max; k ++) {
                let row = {};
                Object.keys(dataArr).forEach(field => {
                    dataArr[field].forEach((val, j) => {
                        if (k === j) {
                            row[field] = val;
                        }
                    })
                })
                if (! k) {
                    title = row;
                } else {
                    list.push(row);
                }
            }
            suc && suc(list, title);
        }
        fileReader.readAsBinaryString(file);
    }

    /**
     * 导出
     * @param fileName 文件名
     * @param data 数组
     * @param func 回调
     */
    export(fileName, data, func) {
        let letters = this.getLetters(data[0].length),
            table = [];
        data.forEach(item => {
            let row = {};
            item.forEach((val, index) => {
                row[letters[index]] = val;
            })
            table.push(row);
        })
        const wb = XLSX.utils.book_new();

        const sheet = XLSX.utils.json_to_sheet(table, {
            skipHeader: true
        });

        XLSX.utils.book_append_sheet(wb, sheet, fileName);

        const wopts = {
            // 要生成的文件类型
            bookType: "xlsx",
            // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
            bookSST: false,
            type: "binary"
        };
        const wbout = XLSX.write(wb, wopts);

        // 将字符串转ArrayBuffer
        function s2ab(s) {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
            return buf;
        }

        const workbookBlob= new Blob([s2ab(wbout)], {
            type: "application/octet-stream"
        });

        xlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
            func && func(workbook, table);
            return workbook.outputAsync().then(
                (workbookBlob) => {
                    return URL.createObjectURL(workbookBlob)
                }
            );
        }).then(url => {
            const downloadAnchorNode = document.createElement("a");
            downloadAnchorNode.setAttribute("href", url);
            downloadAnchorNode.setAttribute(
                "download",
                fileName+".xlsx"
            );
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        })
    }

    getLetters(length) {
        let letters = [...this.letters],
            column = [];

        if (length <= 26) {
            letters.forEach((item, k) => {
                if (k <= length - 1) {
                    column.push(item);
                }
            })
        } else {
            let rows = Math.ceil(length / 26),
                end = length - ((rows - 1) * 26);
            for (let k = 0; k < rows; k ++) {
                if (! k) {
                    letters.forEach(item => {
                        column.push(item);
                    })
                } else {
                    let j = k - 1,
                        letter = letters[j];
                    for(let l in letters) {
                        if (k === rows - 1 && Number(l) === end) {
                            return column;
                        }
                        column.push(letter + letters[l]);
                    }
                }
            }
        }
        return column;
    }
}
