import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";

export default class CompanyService extends BaseService
{
    requestUrls = {
        url:'/company/list',
        save:'/company/save',
        detail:'/company/detail',
        delete:'/company/delete',
    }

    save(model, suc, err) {
        var validator = new Validator(),
            rules = [
                ['name', 'required', '请填写分部名称'],
                ['phone', 'required|mobile', '请填写手机号码|手机号码格式不正确'],
                ['password', (!model.id ? 'required|' : '') + 'min:6|max:20', (!model.id ? '请填写密码|' : '') + '密码至少6位|密码最多20位'],
            ];

        if (! validator.validate(rules, model)) {
            return err(validator.getError());
        }

        this.post(
            this.requestUrls.save,
            model,
            suc,
            err
        )
    }

    getDetail(id, suc) {
        this.post(
            this.requestUrls.detail,
            {id:id},
            suc
        )
    }

    delete(id, suc, err) {
        this.request({
            url:this.requestUrls.delete,
            data:{id:id},
            success:suc,
            error:err
        })
    }
}
