import Base from "../../../base";
import CompanyUserListLayout from "../layout";

export default class CompanyUserList extends Base
{
    render() {
        return (
            <CompanyUserListLayout
            />
        );
    }
}
