import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";

export default class UserTypeService extends BaseService
{
    requestUrls = {
        url:'/running/userType/list',
        save:'/running/userType/save',
        detail:'/running/userType/detail',
        sort:'/running/userType/sort',
        change:'/running/userType/change',
        delete:'/running/userType/delete'
    }

    status = ['禁用', '启用'];

    appends = ['stateDup'];

    getList(suc) {
        this.post(
            this.requestUrls.url,
            {perPage:1000},
            suc
        )
    }

    save(model, suc, err) {
        let validator = new Validator(),
            rules = [
                ['name', 'required', '请填写商户类型'],
            ];
        if (! validator.validate(rules, model)) {
            return err(validator.getError());
        }

        this.post(
            this.requestUrls.save,
            model,
            suc,
            err
        )
    }

    getDetail(id, suc) {
        this.post(
            this.requestUrls.detail,
            {id:id},
            suc
        )
    }

    delete(model, suc, err) {
        this.post(
            this.requestUrls.delete,
            {id:model.id},
            suc,
            err
        )
    }

    changeState(data, suc, err) {
        let model = {
            id:data.id,
            status:Number(data.status) === 0 ? 1 : 0
        }
        this.post(
            this.requestUrls.change,
            model,
            suc,
            err
        )
    }

    changeSort(data, suc, err) {
        let model = {
            id:data.id,
            sort:data.sort
        }
        this.post(
            this.requestUrls.sort,
            model,
            suc,
            err
        )
    }

    getStateDupAttribute() {
        return this.status[this.attributes['status']];
    }
}
