import Base from "../../../base";
import UserTypeListLayout from "../layout";

export default class UserTypeList extends Base
{
    render() {
        return (
            <UserTypeListLayout />
        );
    }
}
